/**
 * Common
 */
body.page-cart,
body.page-checkout {
  #main-content #block-system-main {
    @extend %container;
  }

  h1 {
    margin: 35px 0 17px;

    span {
      @include sm {
        display: none;
      }
    }
  }

  .checkout-buttons {
    text-align: right;

    .fieldset-wrapper {
      margin: 0;
      border: none;
    }

    input[type='submit'] {
      @extend %buttonInput;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      padding: 11px 37px;
    }

    .button-operator {
      margin: 0 .5em;
    }
  }

  .form-item-commerce-customer-address-und-0-postal-code {
    width: auto;
    max-width: 100px;
  }
}

/**
 * MiniCart Popup
 */
.view-id-commerce_cart_summary_bordas.view-display-id-minicart {
  .views-row {
    display: flex;
    margin-bottom: 1em;

    .left {
      flex-shrink: 0;
    }

    .right {
      margin-left: 1em;

      .price {
        color: $colorBetterave;
        font-weight: bold;
      }

      a::before {
        display: none !important;
      }
    }
  }
}

/**
 * Progress Checkout
 */
#block-commerce-checkout-progress-indication {
  border-top: 2px solid $colorGrisClair;
  border-bottom: 2px solid $colorGrisClair;
  margin-bottom: 28px;

  > .content {
    @extend %container;
  }

  ol.commerce-checkout-progress {
    padding: 13px 0 0;

    li {
      position: relative;
      width: auto;
      padding: 7px 5px 10px 22px;
      margin-right: 15px;
      color: $colorGrayDark;
      font-size: 1.2rem;
      font-weight: 500;
      opacity: 0.5;

      @include xs {
        display: none;
        margin-right: 10px;
      }

      @for $i from 1 through 6 {
        &:nth-child(#{$i})::before {
          @include xs {
            content: '#{$i}.';
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -4px;
        left: 0;
        display: block;
        width: 100%;
        height: 3px;
      }

      &::before {
        padding: 2px 4px;
        background-color: transparent;
        color: $colorBetterave;
        font-weight: 500;
      }

      &.active {
        opacity: 1;

        @include xs {
          display: list-item;
        }

        &::after {
          background-color: $colorSilver;
        }

        + .next {
          @include xs {
            display: list-item;
          }

          + li {
            @include xs {
              display: list-item;
            }
          }
        }
      }
    }
  }
}

/**
 * Progress Back Button
 */
#block-bordas-block-bordas-refonte-tunnel-back {
  padding: 6px 0 34px;

  > .content {
    @extend %container;
  }

  a.tunnel-back-link {
    display: inline-flex;
    align-items: center;
    color: $colorGrayDark;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8;
    text-decoration: none;

    &::before {
      @include backgroundImage('chevron_back.svg', 7px 12px, center center);
      content: '';
      display: inline-block;
      height: 22px;
      width: 12px;
      margin-right: 12px;
    }
  }
}

/**
 * Panier - étape 1/5
 */
body.page-cart .cart-empty-page {
  margin: 2em 0;
}

.view-commerce-cart-form-bordas {
  form {
    font-size: 0.9em;

    table {
      @extend %cart-summary-table;

      &.views-table {
        @include sm {
          display: block;
        }

        tbody {
          @include sm {
            display: block;
          }

          tr {
            @include sm {
              display: -ms-grid;
              display: grid;
              -ms-grid-columns: minmax(min-content, 136px) 1fr;
              grid-template-columns: minmax(min-content, 136px) 1fr;
              -ms-grid-rows: auto auto auto auto auto;
              grid-template-rows: repeat(5, auto);
              padding: 20px 21px;
              margin-bottom: 10px;
            }

            @include xs {
              -ms-grid-columns: minmax(min-content, 60px) 1fr;
              grid-template-columns: minmax(min-content, 60px) 1fr;
              padding: 20px 11px;
            }
            td {
              @include sm-up {
                vertical-align: top;

                &.views-field-line-item-title,
                &.views-field-field-ean13,
                &.views-field-edit-price-type
                {
                  padding-top: 38px;
                }
              }
            }
          }

          // Price selector for enseignant.
          td.views-field-edit-price-type {
            max-width: 350px;

            @include sm {
              width: 100%;
              max-width: none;
              justify-content: flex-end;
            }
            .form-type-radios {
              display: flex;
              flex-direction: column;

              @include sm {
                max-width: 260px;
              }

            }
            .form-item {
              white-space: normal;
            }
            label {
              font-size: 1.2rem;
              margin-bottom: 12px;
              font-weight: normal;
              text-transform: none;
            }
            .description {
              margin: 0.2em 0 0 2em;
              width: 100%;
            }
            .form-radios {
              .form-type-radio {
                margin-bottom: 8px;
                display:flex;
                flex-wrap:wrap;
                align-items:flex-start;

                > label {
                  max-width:calc(100% - 21px);
                  display: block;
                  margin-bottom: 0;
                }

                > input {
                  margin-right: 8px;
                }

              }
            }
          }
        }
      }
    }

    #bordas-commerce-express-add {
      @extend %commerce-pane;
      width: 100%;
      max-width: 570px;
      margin: 26px 0 22px auto;
      float: left;
      padding: 24px 20px;

      .container-inline {
        display: flex;
        align-items: flex-start;
        p {
          color: #27282B;
          font-size: 1.3rem;
          line-height: 1.7;
          margin-right: 10px;
        }
      }
      h2 {
        color: $colorDoveGray;
        font-size: 1.7rem;
        font-weight: 500;
        margin-top : 0;
      }
      input[type='text'] {
        height: 40px;
        &::-webkit-input-placeholder,
        &::-ms-input-placeholder {
          font-weight: bold;
          font-size: 1.2rem;
        }
        &::placeholder {
          font-weight: bold;
          font-size: 1.2rem;
        }
      }
      input[type='submit'] {
        @extend %buttonInput;
        padding: 11px;
        margin-left: 1em;
        font-weight: 500;
        height: 40px;
        height: max-content;

        @include sm {
          padding: 11px 21px;
        }

        @include max(470px) {
          margin: 0 0 0.5em;
        }
      }

      #submit_express {
        flex-shrink: 0;
      }
    }

    #edit-actions {
      @extend %commerce-pane;
      width: 100%;
      max-width: 570px;
      margin: 26px 0 22px auto;
      overflow: hidden;

      @include sm {
        max-width: 100%;
        margin: 0 0 17px;
      }

      @include max(470px) {
        text-align: center;
      }

      input[type='submit'],
      .input-button {
        @extend %buttonInput;
        padding: 11px;
        margin-left: 1em;
        font-weight: 500;

        @include sm {
          padding: 11px 21px;
        }

        @include max(470px) {
          margin: 0 0 0.5em;
        }
      }

      #edit-checkout,
      #edit-devis {
        float: right;
        clear: both;
        min-width: 18rem;
      }

      #edit-devis {
        margin-top: 15px;
      }

      #edit-checkout {
        @include sm {
          margin-top: 15px;
        }
      }

      .line-item-summary {
        text-align: left;

        table {
          width: 100%;
          border: none;

          tr {
            border: none;
            background-color: transparent;

            @include sm {
              display: flex;
              justify-content: space-between;
            }
          }

          td {
            padding: 0.25em;
          }

          td.line-item-total-label {
            color: $colorShark;
            font-size: 1.7rem;
            font-weight: 500;
            text-align: left;
            letter-spacing: letterSpacing(35);
          }

          td.line-item-total-raw {
            text-align: right;
            font-size: 1.7rem;
            font-weight: 700;
            color: $colorBetterave;

            @include sm {
              display: flex;
              justify-content: flex-end;
            }
          }
        }

        img {
          max-width: 185px;
          transform: translateY(170%);

          @include sm {
            max-width: 155px;
          }

          @include max(555px) {
            transform: none;
          }
        }
      }
    }


    .spinners {
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      left: -21px;
      width: 20px;

      @include sm {
        left: 0;
      }

      > div {
        display: flex;
        width: 100%;
      }
    }

    .commerce-quantity-plusminus-link {
      a.button {
        padding: 4px 7px;
        margin-right: 0;
        font-weight: 500;
        text-align: center;

        &:hover {
          text-decoration: none;
          color: $colorMineShaft;
        }
      }

      &.commerce-quantity-plusminus-link-decrease,
      &.commerce-quantity-plusminus-link-increase {
        a {
          padding: 0 5px;
          border-radius: 10px;
          background-color: transparent;
          color: $colorDustyGray;
          font-size: 0.8rem;
          text-decoration: none;
        }
      }

      &.commerce-quantity-plusminus-link-disabled {
        a.button {
          color: $colorDustyGray;
        }
      }
    }

    div[class*='form-item-edit-quantity'] > span {
      display: inline-block;
      padding: 13px 34px 13px 14px;
      border: 1px solid $colorSilver2;
      border-radius: 0;
      background-color: $colorWhite;
      color: $colorBlack;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.1;

      + .spinners {
        display: none;
      }
    }

    select {
      background-image: none;
    }
  }

  .view-footer {
    clear: both;
    max-width: 570px;
    padding-bottom: 40px;
    padding-left: 10px;
    margin-left: auto;
    color: $colorShark;
    font-size: 1.2rem;
    line-height: 1.3;

    @include sm {
      max-width: 100%;
      padding-left: 0;
      text-align: center;
    }

    @include xs {
      padding: 0 15px 27px;
    }
  }
}

/**
 * Authentification - étape 2/5
 */
form#commerce-checkout-form-authentify {
  > div {
    display: flex;

    @include sm {
      flex-wrap: wrap;
    }

    > div {
      &:first-of-type {
        margin-right: 15px;

        @include sm {
          margin-right: 0;
        }
      }

      &:nth-of-type(2) {
        margin-left: 15px;

        @include sm {
          margin-left: 0;
        }
      }
    }
  }

  #edit-bordas-login,
  #edit-bordas-register {
    @extend %commerce-pane;
    max-width: 50%;
    margin-bottom: 80px;
    background-color: transparent;

    @include sm {
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }

    h3 {
      display: block;
      margin: 0;
      color: $colorGray1;
      font-size: 1.7rem;
      font-weight: 500;
      text-align: center;
    }

    label,
    .intro-text {
      margin-bottom: 0.5em;
      font-size: 1.3rem;
      font-weight: normal;
      text-transform: none;
    }
  }

  #edit-bordas-login {
    .form-item {
      margin: 1.5em 0;
    }

    .forgot-password {
      text-align: center;

      a {
        color: $colorShark;
        font-size: 1.2rem;
      }
    }
  }

  #edit-bordas-register {
    .intro-text {
      margin-bottom: 36px;
      margin-top: 41px;
    }

    .form-type-radio {
      label.option {
        font-weight: 500;
      }
    }

    input[type='submit'] {
      margin-top: 3em;
    }
  }

  input[type='submit'] {
    @extend %buttonInput;
    display: block;
    margin: 1em auto;
    font-weight: 500;
    text-align: center;
    text-transform: none;
  }
}

/**
 * Livraison - étape 3/5
 */
form#commerce-checkout-form-checkout {
  > div {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: minmax(min-content, 570px) 30px minmax(min-content, 570px);
    grid-template-columns: minmax(min-content, 570px) 30px minmax(min-content, 570px);
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: repeat(4, auto);
    align-items: start;

    @include sm {
      display: flex;
      flex-wrap: wrap;
    }

    > .messages {
      -ms-grid-row: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      grid-column: 1 / 4;

      + #edit-bordas-expedition {
        -ms-grid-row: 2;
        grid-row: 2;
      }
    }

    > div:first-of-type:not(.messages) {
      -ms-grid-row: 2;
      grid-row: 2;
    }

    > div:nth-of-type(2):not(.messages) {
      -ms-grid-row: 3;
      grid-row: 3;
    }

    > .bordas_expedition {
      + #edit-bordas-gp-shipping {
        -ms-grid-row: 2;
        grid-row: 2;
      }
    }
  }

  #edit-bordas-etablissement {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    grid-row: 1 / 5;

    #edit-bordas-etablissement-organisme,
    #edit-bordas-etablissement-etablissement {
      padding: 3rem;
    }
  }

  #edit-bordas-ens-shipping {
    grid-row: 1;
    -ms-grid-row: 1;
  }

  #edit-bordas-ens-shipping,
  #edit-bordas-expedition {
    -ms-grid-column: 3;
    grid-column: 3;
  }

  #edit-bordas-expedition {
    max-width: 570px;
    align-self: start;
    grid-row: 2;
    -ms-grid-row: 2;



    body.account-enseignant & {
      // -ms-grid-row: 3;
      // grid-row: 3;
    }
  }

  #edit-bordas-gp-shipping +#edit-bordas-expedition {
    -ms-grid-row: 2;
    grid-row: 2;
  }

  #edit-bordas-etablissement,
  #edit-bordas-gp-shipping,
  #edit-bordas-ens-shipping,
  #edit-bordas-expedition {
    @extend %commerce-pane;
    margin: 0 0 1em 0;

    @include sm {
      width: 100%;
    }

    .fieldset-wrapper {
      padding: 0;
      margin: 0;
      border: none;
    }

    .form-item {
      margin-bottom: 17px;
    }

    h3 {
      display: block;
      margin: 0 0 30px;
      color: $colorGray1;
      font-size: 1.7rem;
      font-weight: 500;
      text-align: center;
    }

    label {
      margin-top: 17px;
    }

    label,
    p {
      margin-bottom: 0.5em;
      font-size: 1.3rem;
      font-weight: 500;
      text-transform: none;

      &.option {
        font-weight: 500;
      }
    }

    .form-type-radio label {
      font-weight: normal;
    }

    #addressfield-wrapper .form-wrapper {
      .description {
        margin: 0.5em 0;
      }
    }

    .street-block {
      margin: 0 0 17px;
    }

    div.addressfield-container-inline > div.form-item {
      margin-right: 0;
    }
  }

  #edit-bordas-billing {
    label {
      margin: 1.5em 0 0.25em;
    }
  }

  #edit-buttons {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1 / 4;
    -ms-grid-row: 5;
    grid-row: 5;
    text-align: center;

    @include sm {
      margin: 0 auto 23px;
    }
  }
}

/**
 * Vérification - étape 4/5
 */
form#commerce-checkout-form-review {
  // The summary view
  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include sm {
      align-items: stretch;
    }
  }

  .cart_contents {
    width: 100%;
  }

  .view-commerce-cart-summary-bordas {
    margin-bottom: 1em;

    .view-content {
      font-size: 0.9em;

      table {
        @extend %cart-summary-table;

        &.views-table {
          @include sm {
            display: block;
          }

          tbody {
            @include sm {
              display: block;
            }

            tr {
              @include sm {
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: minmax(min-content, 136px) 1fr;
                grid-template-columns: minmax(min-content, 136px) 1fr;
                -ms-grid-rows: auto auto auto auto auto;
                grid-template-rows: repeat(5, auto);
                padding: 20px 21px;
                margin-bottom: 10px;
              }

              @include xs {
                -ms-grid-columns: minmax(min-content, 60px) 1fr;
                grid-template-columns: minmax(min-content, 60px) 1fr;
                padding: 20px 11px;
              }
            }
          }
        }
      }
    }
  }

  // All the stuff below
  #edit-edu-coupon,
  #edit-bordas-devis-pdf,
  #edit-bordas-order-total {
    @extend %commerce-pane;
    width: 100%;
    margin: 0 0 1em 0;

    .fieldset-wrapper {
      padding: 0;
      margin: 0;
      border: none;

      &:nth-child(2) {
        padding-right: 15px;

        input {
          margin-left: auto;
        }
      }
    }

    input[type='submit'] {
      @extend %buttonInput;
      display: block;
      font-weight: 500;

      &[disabled]:hover {
        opacity: 0.5;
        text-decoration: none;
        box-shadow: none;
      }
    }
  }

  #edit-edu-coupon,
  #edit-bordas-devis-pdf {
    flex-shrink: 1;
    width: 100%;
    max-width: 270px;
    padding: 24px 15px;
    margin-right: 30px;

    @include md {
      max-width: 250px;
      margin-right: 10px;
    }

    @include sm {
      width: 100%;
      max-width: calc(50% - 10px);
      margin-right: 0;
    }

    @include xs {
      max-width: 100%;
    }

    &#edit-edu-coupon {
      @include sm {
        margin-right: 10px;
      }

      @include xs {
        margin: 0 0 20px;
      }
    }

    &#edit-bordas-devis-pdf {
      @include sm {
        margin-left: 10px;
      }

      @include xs {
        margin: 0 0 20px;
      }
    }

    h2 {
      margin: 0 0 20px;
      color: $colorGray1;
      font-size: 1.7rem;
      font-weight: 500;
    }

    p {
      margin: 0 0 10px;
      color: $colorShark;
      font-size: 1.3rem;
      line-height: 1.5;
    }

    .devis-link {
      display: flex;
      align-items: center;
      color: $colorShark;
      font-size: 1.3rem;
      color: #FFFFFF;
      font-size: 14px;
      text-transform: UPPERCASE;
      padding: 10px;
      text-decoration: none;
      font-weight: 500;
      background-color: #435D7B;

      &::before {
        @include backgroundImage('icon_pdf_white.svg', 21px 29px, center center);
        content: '';
        display: block;
        flex-shrink: 0;
        width: 21px;
        height: 29px;
        margin-right: 9px;
      }

      &:hover {
        text-decoration:underline;
      }
    }

    input {
      color: $colorShark;
      font-size: 1.3rem;
      font-weight: 500;

      @include placeholder {
        color: $colorShark;
      }
    }

    .btn-validate {
      margin-top: 12px;
      text-align: center;
    }

    button {
      text-transform: none;
      font-weight: 500;
    }
  }

  #edit-bordas-order-total {
    flex-shrink: 0;
    width: 100%;
    max-width: calc(100% - 600px);
    padding: 24px 0;
    background-color: $colorGrisClair;

    @include md {
      max-width: calc(100% - 520px);
    }

    @include sm {
      width: 100%;
      max-width: 100%;
      margin-bottom: 25px;
    }

    img.payment-logos {
      padding-left: 15px;
      max-width: 185px;
      transform: translateY(60%);

      @include sm {
        max-width: 155px;
      }

      @include max(555px) {
        transform: none;
      }
    }

    #edit-cgv {
      padding: 42px 15px 15px;
      color: $colorShark;
      font-size: 1.3rem;

      label {
        font-size: 1.3rem;
      }

      .form-item {
        display: inline-flex;

        input {
          margin-right: 10px;
        }
      }

      .cgv-incitation {
        display: inline;
        margin-left: 3px;

        @include max(460px) {
          display: inline-block;
          margin-left: 0;
        }
      }
    }

    input[type='submit'] {
      margin-top: -40px;

      @include sm {
        margin-top: -30px;
      }

      @include max(555px) {
        margin-top: 0;
      }
    }

    table {
      width: 100%;
      border: none;
      font-size: 1.5rem;

      tbody {
        border: none;
      }

      tr {
        padding: 0;
        border-bottom: 1px solid $colorSilver;
        background-color: transparent;

        &:first-child {
          td {
            padding-top: 0;
          }
        }
      }

      td {
        padding: 14px 0;

        &:first-child {
          padding-left: 15px;
        }

        &:last-child {
          padding-right: 15px;
        }
      }

      .component-title {
        color: $colorShark;
        font-weight: 500;
        text-align: left;
      }

      .component-total {
        text-align: right;
        color: $colorBetterave;
        padding-left: 0.5em;
        font-weight: 700;
      }

      .component-type-commerce-price-formatted-amount {
        border-bottom: 0;
        font-size: 1.7rem;
      }
    }

    img {
      margin-bottom: 1em;
    }

  }

  #edit-buttons {
    display: none;
  }
}

/**
 * Paiement - étape 5/5
 */
form#commerce-checkout-form-paiement {
  #edit-bordas-payment {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    #edit-bordas-payment-bordas-commerce-payment-chequecommerce-payment-bordas-commerce-payment-cheque {
      .details {
        color: $colorShark;
        font-size: 1.3rem;
        line-height: 1.6;
      }

      .adresse {
        max-width: calc(100% - 40px);
        padding: 15px;
        border: 1px solid $colorSilver;
        margin: 20px auto 0;
        background-color: $colorConcrete;
        font-family: $fontRubik;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.7;
        text-align: center;
      }
    }

    .payment-method {
      flex: 1 0 45%;
      @extend %commerce-pane;
      width: 100%;
      max-width: calc(50% - 1%);
      margin: 2em 1%;
      padding: 0;
      background-color: transparent;

      @include sm {
        max-width: 100%;
        margin: 0 0 20px;
      }

      .details {
        padding: 15px;

        > .form-item,
        .commerce-credit-card-expiration {
          margin-bottom: 11px;

          .form-radios {
            margin-bottom: 8px;
          }
        }

        > .form-item {
          @include xs {
            flex-wrap: wrap;
          }

          label {
            @include xs {
              width: 100%;
            }
          }
        }

        .form-type-radios,
        .form-radios,
        .form-type-radio {
          display: flex;
          align-items: center;
        }
      }

      .form-type-radio {
        label {
          width: 100%;

          &::after {
            @include backgroundImage('', 100% auto, center center);
            content: '';
            display: block;
            margin-left: 12px;
          }
        }

        label[for*='visa'] {
          &::after {
            @include backgroundImage('visa_icon.svg');
            width: 50px;
            height: 16px;
          }
        }

        label[for*='mastercard'] {
          &::after {
            @include backgroundImage('masterccard_icon.svg');
            width: 120px;
            height: 28px;
          }
        }

        img {
          display: none;
        }
      }

      h2 {
        padding: 24px 5px 23px;
        border-bottom: 1px solid $colorSilver;
        margin: 0 0 1em;
        color: $colorGray1;
        font-size: 1.7rem;
        font-weight: 500;
        text-align: center;
      }

      label {
        display: inline-block;
        width: 34%;
        padding: 0;
        margin: 0 1em 0 0;
        font-weight: 400;
        text-transform: none;

        @include xs {
          width: 100%;
        }
      }

      input[type='submit'] {
        @extend %buttonInput;
        display: block;
        margin: 1.5em auto;
        font-weight: 500;
      }

      .form-radios,
      input[type='text'] {
        width: 60%;
        margin: 0;
        padding: 0;

        @include xs {
          width: 100%;
        }
      }

      input[type='text'],
      input[type='radio'] {
        display: inline-block;
      }
    }

    .messages {
      order: -3;
      flex: 0 1 100%;
    }
  }
}

/**
 * Complete - étape 6
 */
#edit-bordas-complete {
  @extend %commerce-pane;
  width: 100%;
  margin-bottom: 70px;

  pre {
    max-width: calc(100% - 40px);
    padding: 15px;
    border: 1px solid $colorSilver;
    margin: 20px auto 0;
    background-color: $colorConcrete;
    font-family: $fontRubik;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7;
    text-align: center;
    margin-bottom: 20px;
  }

  .button {
    @extend %buttonInput;
    margin-bottom: 1em;
  }
}

.commerce-price-formatted-components {
  .sub {
    font-weight: normal;
    font-size: 85%;
  }
}

/**
 * User - mes commandes
 */
.view-commerce-user-orders {
  @extend %container;
  margin-bottom: 70px;

  table.views-table {
    @extend %cart-summary-table;
  }
}

/**
 * Messages - Add to cart
 */
.commerce-add-to-cart {
  position: relative;
}

.message-add-cart {
  @extend %boxShadowDefault;
  position: absolute;
  bottom: 65px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px 17px 15px;
  border-radius: 3px;
  background-color: $colorWhite;
  background-image: none!important;
  font-weight: 500;
  text-align: center;
  z-index: 300;

  &::before {
    content: '';
    position: absolute;
    bottom: -7px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background-color: $colorWhite;
    transform: rotate(45deg);
  }

  .page-recherche & {
    bottom: 90px;
    left: -50%;
    right: -50%;
  }

  &.success {
    .icon-add-to-cart {
      &::after {
        @include backgroundImage('icon-success.svg');
      }
    }
  }

  &.error {
    .icon-add-to-cart {
      &::after {
        @include backgroundImage('icon-error.svg');
      }
    }
  }

  .icon-add-to-cart {
    @include backgroundImage('cart_black.svg', 100% auto, center);
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-bottom: 20px;

    &::after {
      @include backgroundImage('', contain, center);
      content: '';
      position: absolute;
      top: -8px;
      display: inline-block;
      width: 25px;
      height: 25px;
    }
  }
}

#messages {
  .messages {

    &.express,
    &.submit_checkout{
      display: none;
    }
  }
}
