.node-type-auteur {
  #content > .section {
    flex-wrap: wrap;
    padding-bottom: 0;

    > * {
      flex: 0 0 100%;
    }

    .node-auteur {
      width: 100%;
    }

    .content {
      > .group-container-global {
        @extend %container;
        display: flex;
        flex-wrap: wrap;
      }

      .group-content-wrapper {
        width: calc(75% - 40px);
        margin-right: 40px;
        overflow: hidden;
        padding: 60px 0;
        display: grid;
        display: -ms-grid;
        grid-template-columns: auto 1fr;
        -ms-grid-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        -ms-grid-template-rows: auto 1fr;
        position: relative;

        @include sm {
          padding: 30px 0;
          width: 100%;
          margin: 0;
          grid-template-columns: 1fr 2fr;
          -ms-grid-columns: 1fr 2fr;
          justify-items: start;
        }

        .image-auteur {
          width: 100%;
          padding-right: 20px;
          object-fit: cover;
          grid-column: 1;
          -ms-grid-column: 1;
          grid-row: span 3;
          -ms-grid-row-span: 3;

          img {
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.34);
            width: auto;
          }
        }

        .image-auteur-wrapper {
          float: left;
          padding-right: 40px;
          grid-column: 1;
          -ms-grid-column: 1;
          grid-row: span 3;
          -ms-grid-row-span: 3;

          @include sm {
            padding-right: 25px;
          }
        }

        .field-name-title-field {
          grid-column: 2;
          -ms-grid-column: 2;
          -ms-grid-row: 1;

          h1 {
            font-family: $fontK2D;
            color: $colorBlueBayoux1;
            margin: 0;
            padding: 0;
            font-size: 3rem;
            font-weight: 800;
            line-height: 31px;
            text-align: left;
          }

          @include sm {
            float: none;
            font-size: 2.8rem;
            line-height: 32px;
            width: 100%;
            white-space: normal;
          }

          @include sm-up {
            width: calc(100% - 165px);
          }
        }

        .field-name-field-auteur-profession {
          font-family: $fontRubik;
          font-size: 1.5rem;
          line-height: 2.3rem;
          margin-top: 10px;
          letter-spacing: letterSpacing(05);
          color: $colorEmperor;
          grid-column: 2;
          -ms-grid-column: 2;
          -ms-grid-row: 2;

          @include sm {
            white-space: normal;
          }

          &::after {
            content: '';
            display: block;
            margin-top: 10px;
            width: 105px;
            height: 3px;
            background-color: $colorBetterave;
          }
        }

        .field-name-field-auteur-biographie {
          margin-top: 46px;
          font-family: $fontRubik;
          font-size: 1.4rem;
          line-height: 2.3rem;
          letter-spacing: letterSpacing(05);
          color: $colorBlack;
          text-align: left;
          grid-column: 2;
          -ms-grid-column: 2;
          -ms-grid-row: 3;

          @include sm {
            grid-column: 1 / 3;
            -ms-grid-column: 1 / 3;
          }

          ul {
            @extend %styleUl;

            li {
              display: flex;

              &::before {
                margin-top: 8px;
              }
            }
          }

          ol {
            @extend %styleOl;
          }

          h3, 
          h4,
          h5 {
            font-weight: 500;
          }

          h3 {
            font-size: 1.7rem;
          }

          h4 {
            font-size: 1.6rem;
          }

          h5 {
            > span {
              font-size: 1.4rem !important;
              letter-spacing: letterSpacing(50) !important;
            }
          }

        }

        > div {
          &:last-child:not(.field-name-field-auteur-biographie) {
            &::after {
              content: '';
              display: block;
              margin-top: 10px;
              width: 105px;
              height: 3px;
              background-color: $colorBetterave;
            }
          }
        }

      }

      .group-savoir-plus {
        width: calc(100% / 12 * 3);
        background-color: $colorGrisClair;
        padding: 33px 20px;
        height: 100%;
        margin-top: 60px;

        @include sm {
          width: 100%;
          margin-top: 0;
          background-color: transparent;
        }

        h3 {
          margin: 0;
          font-size: 1.5rem;
          line-height: 2.3rem;
          color: $colorEmperor;
        }

        .field-item {
          a {
            font-size: 1.3rem;
            line-height: 3rem;
            color: $colorShark;
            text-align: left;
            font-weight: normal;
            text-decoration: underline;
            display: flex;
            align-items: center;

            &::before {
              content: "";
              display: block;
              width: 8px;
              height: 12px;
              margin-right: 14px;
              @include backgroundImage('chevron_right-red.svg', 7px, left center);
            }
          }
        }
      }

      .group-ouvrage-wrapper {
        width: 100%;
        text-align: center;
        padding: 33px 0;
        background-color: $colorWildSand;

        .titre {
          font-family: $fontK2D;
          color: $colorBlueBayoux1;
          margin: 0 0 30px;
          font-size: 3rem;
          font-weight: 800;
          line-height: 31px;
          text-align: left;
          padding: 0 35px;
          text-align: center;

          span {
            color: $colorBetterave;
          }

          &::after {
            content: '';
            display: block;
            margin: 10px auto;
            width: 105px;
            height: 3px;
            background-color: $colorSilver;
          }
        }

        .ouvrage-auteur {
          .slick-list {
            max-width: 1169px;
            margin: 0 auto;

            @include sm {
              max-width: calc(100% - 70px);
            }

            @include xs {
              max-width: calc(100% - 20px);
            }
          }

          .slick-dots {
            .slick-active button {
              background-color: $colorBetterave;
            }
          }

          .node-ouvrage {
            .group-bottom {
              flex-direction: column;

              .hidden {
                display: none;
              }
            }

            .field-name-field-ouvrage-image {
              width: auto;
            }
          }
        }

        .ouvrages-auteur-list {
          @extend %noStylelist;
          @extend %container;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;

          li {
            width: 25%;

            @include sm {
              width: 33.33%;
            }

            @include xs {
              width: 50%;
            }
          }

          @include sm {
            max-width: 100%;
            padding: 0 20px;
          }
        }


      }
    }
  }
}