@import 'user-register';
@import 'user-password';

.page-user {
  .tabs {
    display: none;
  }

  &.page-user-login {
    .region-content form {
      max-width: 870px;
      margin: 0 auto;

      .form-item {
        margin-bottom: 20px;
      }

      .description {
        margin-top: 5px;
      }

      .form-actions {
        text-align: right;

        @include sm {
          text-align: center;
        }
      }

      label {
        width: 100%;
        margin-bottom: 5px;
        color: $colorShark;
        font-size: 1.3rem;
        font-weight: 400;
        text-transform: none;
        text-align: left;
      }

      input[type='submit'] {
        @extend %buttonDefault;
      }
    }
  }
}

body.context-user-login-register {

  .region-content {
    @extend %container;
    display: flex;

    @include sm {
      flex-wrap: wrap;
    }
  }

  #block-bordas-block-bordas-refonte-login,
  #block-bordas-block-bordas-refonte-register {
    @extend %commerce-pane;
    max-width: 50%;
    margin-bottom: 80px;
    background-color: transparent;

    @include sm {
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }

    .titre,
    h3 {
      display: block;
      margin: 0;
      color: $colorGray1;
      font-size: 1.7rem;
      font-weight: 500;
      text-align: center;
    }

    label,
    .intro-text {
      margin-bottom: 0.5em;
      font-size: 1.3rem;
      font-weight: normal;
      text-transform: none;
    }

    input[type='submit'] {
      @extend %buttonInput;
      display: block;
      margin: 1em auto;
      font-weight: 500;
      text-align: center;
      text-transform: none;
    }
  }

  #block-bordas-block-bordas-refonte-login {
    margin-right: 15px;

    @include sm {
      margin-right: 0;
    }

    .form-item {
      margin: 1.5em 0;
    }

    .forgot-password {
      text-align: center;

      a {
        color: $colorShark;
        font-size: 1.2rem;
      }
    }
  }

  #block-bordas-block-bordas-refonte-register {
    margin-left: 15px;
    text-align: center;

    @include sm {
      margin-left: 0;
    }

    .intro-text {
      margin-bottom: 36px;
      margin-top: 41px;
    }

    .form-type-radio {
      margin: 0.4em 0;
      label.option {
        font-weight: 500;
      }
    }

    a.button {
      @extend %buttonInput;
      display: inline-block;
      margin: 1em auto;
      font-weight: 500;
      text-align: center;
      text-transform: none;
    }
  }
}

#user-login-form,
#user-login {
  .form-type-password {
    position: relative;
  }

  span.pwd_show {
    @include eyeShowPassword;
  }
  
  a.show_pwd {
    display: none;
  }
}

#div_login {
  .show_pwd {
    top: 12px!important;
  }
}

#div_password {
  .show_pwd {
    top: 12px !important;
  }
}

#bordsso-change-password-form {
  .form-type-password {
    position: relative;
  }
  span.pwd_show {
    @include eyeShowPassword;
    top: 12px!important;
    right: 28px!important;
    @include sm {
      top: 28px;
      right: 20px;
    }
  }

  a.show_pwd {
    display: none;
  }
}

.page-user-change-password {
  #form_account {
    span.pwd_show {
      @include eyeShowPassword;
      right: 150px;

      @include sm {
        top: 28px;
        right: 20px;
      }
    }
  }
}

#commerce-checkout-form-authentify {
  .form-item-bordas-login-pass {
    position: relative;
    span.pwd_show {
      @include eyeShowPassword;
    }
  }
}