// ========================================
// Table of content
// --------------------------------------
// - Global
// - Paragraph Texte
// - Paragraph Image
// - Paragraph CTA
// - Paragraph Image et Texte
// - Paragraph Video

.entity-paragraphs-item {
  width: 100%;
  margin-bottom: 48px;

  @include sm {
    margin-bottom: 35px;
  }

  @include xs {
    margin-bottom: 30px;
  }

  &.paragraphs-item-cta {
    @include min($sm-width) {
      margin-bottom: 40px;
    }
  }

  .field-item:last-child & {
    margin-bottom: 0;
  }

  .field-name-field-para-titre,
  .field-name-title-field {
    h2 {
      margin: 0 0 25px;
      font-size: 1.375em;
    }
  }
}

body[contenteditable="true"],
.field-name-field-para-texte,
.field-name-field-page-texte {
  letter-spacing: letterSpacing(35);
  line-height: 1.7;

  h2,
  h3 {
    font-size: 1.125em;
  }

  h3,
  h4,
  h5 {
    font-weight: 500;
    display: block;

    &:after {
      display: block;
      border-bottom: 1px solid;
      width: 60px;
      content: '';
    }
  }

  h3:after {
    border-color: $colorBetterave;
  }

  h4 {
    font-size: 1em;
    &:after {
      width: 50px;
      border-color: $colorSilver;
    }
  }

  h5 {
    font-size: 0.875em;
    &:after {
      width: 40px;
      border-color: $colorSilver;
    }
  }

  ol,
  p,
  ul {
    font-size: 0.875em;

    @include sm {
      font-size: 0.813em;
    }
  }

  ul {
    @extend %styleUl;

    li {
      display: block;
    }
  }

  ol {
    @extend %styleOl;
  }
}

// Paragraph Texte
// --------------------------------------
.paragraphs-item-texte {}

// Paragraph Image
// --------------------------------------
.paragraphs-item-image {
  .field-name-field-para-image {
    text-align: center;

    img {
      margin: 0 auto;

      @include xs {
        max-width: 80%;
      }
    }
  }
}

// Paragraph CTA
// --------------------------------------
.paragraphs-item-cta {
  .paragraph-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 $colorBlack30;

    a,
    p {
      display: block;
      height: 100%;
      width: 100%;
      padding: 49px 41px;
      background-color: $colorBetterave;
      color: $colorWhite;
      font-size: 1.313em;
      font-weight: 300;
      line-height: 1.48;
      text-align: center;
      text-decoration: none;
      will-change: color, background-color;

      @include sm {
        padding: 29px 30px 37px;
        font-size: 1.188em;
      }

      @include xs {
        padding: 42px 14px 45px;
        font-size: 1.125em;
      }
    }

    p {
      margin-bottom: 0;
    }

    a {
      &:focus,
      &:hover {
        background-color: $colorWildsand1;
        color: $colorEmperor;
      }
    }
  }
}

// Paragraph Image et Texte
// --------------------------------------
.paragraphs-item-image-texte {
  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .field-name-field-para-titre {
    width: 100%;
  }

  .field-name-field-para-image,
  .field-name-field-para-texte {
    flex: 0 0 50%;
    max-width: 50%;

    @include xs {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .field-name-field-para-texte {
    padding-left: 20px;

    @include xs {
      padding-left: 0;
    }
  }

  .field-name-field-para-image {
    @include xs {
      margin-bottom: 25px;
      text-align: center;
    }
  }

  img {
    width: 100%;
    object-fit: cover;

    @include xs {
      max-width: 80%;
    }
  }
}

// Paragraph Video
// --------------------------------------
.paragraphs-item-video {
  .field-name-field-para-video {
    text-align: center;
  }

  iframe,
  object {
    max-width: 100%;
  }
}
