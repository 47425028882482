.item-list .pager {
  @extend %noStylelist;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  li {
    @include transition;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28.8px;
    width: 28.8px;
    margin: 0 3.6px;
    border: 1px solid $colorSilver;
    border-radius: 50%;
    background-color: $colorWhite;

    @include xs {
      flex-shrink: 0;
      height: 25px;
      width: 25px;
      margin: 0 2px;
    }

    &.pager-current,
    &:focus,
    &:hover {
      background-color: $colorBetterave;
      border-color: $colorBetterave;
      color: $colorWhite;
      line-height: 1;

      @include xs {
        font-size: 1.2rem;
        font-weight: 400;
      }

      a {
        color: $colorWhite;
      }
    }

    &.pager-next,
    &.pager-previous {
      &:focus,
      &:hover {
        a {
          @include backgroundImage('pager_chevron_right_white.svg');
        }
      }

      a {
        @extend %hiddenText;
        @include backgroundImage('pager_chevron_right.svg', 9px 14.4px, center);
        padding: 13px;
      }
    }

    &.pager-previous a {
      transform: rotateY(180deg);
    }

    &.pager-first,
    &.pager-last {
      display: none;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $colorGrayDark;
    font-size: 1.4rem;
    line-height: 1;
    text-decoration: none;

    @include xs {
      font-size: 1.2rem;
    }
  }
}
