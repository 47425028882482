.node-page {
  > .content {
    @extend %container;
    max-width: 970px;
    padding-top: 38px;
    margin: 0 auto 70px;
  }

  .title {
    margin: 0 0 6px;
    color: $colorBlueBayoux1;
    font-family: $fontK2D;
    font-size: 3rem;
    font-weight: 800;
    line-height: 1.1;
  }

  .field-name-field-page-sous-titre {
    margin-bottom: 38px;

    h2 {
      margin: 0 0 10px;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.55;
      letter-spacing: letterSpacing(35);

      &::after {
        @include overstrike($colorBetterave, 105px, 3px, 10px 0 0);
      }
    }
  }

  .field-name-field-page-texte {
    ol,
    ul {
      font-size: 1.6rem;
    }
  }
}

// 403 page styling

.notice-block {
  max-width: 970px;
  padding-top: 38px;
  margin: 0 auto 70px;
  color: $colorBlueBayoux1;
  font-family: $fontK2D;
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
  padding-bottom: 650px;
  @include backgroundImage('illus-404.png', 600px, center bottom);

  @include sm {
    padding: 38px 20px 650px;
    background-size: contain;
  }

  @include xs {
    padding: 38px 20px 350px;
  }

}

// Signalment page styling

.page-aca-signalement {
  .region-content .block-system {
    max-width: 600px;
    margin: 100px auto;
    text-align: center;
    border: 1px $colorGrayDark solid;
    border-radius: 10px;
    background:$colorWhite;
    padding: 20px;

    h1 {
      margin: 10px;
      padding-bottom: 0;
    }
  }
}

// Access forbidden styling 

.notice-block {
  .prescription-forbidden-wrapper.message-wrapper.error {
    color: $colorBlueBayoux1;
    background-image: none;
    background-color: transparent;
    padding-bottom: 450px;

    .prescription-paragraph.title {
      background-color: $colorWhite;
      font-size: 3rem;
    }

    .item-list {
      ul {
        font-size: 1.6rem;
        text-align: left;

        li {
          margin: 0 0 0.5em 1.5em
        }
      }
    }
    .prescription-paragraph {
      margin-top: 30px;
      background-color: $colorWildSand;
      font-size: 2.4rem;
      padding: 20px;
    }
  }
}

.message-wrapper.success {
  text-align: center;
  
  .prescription-paragraph {
    .link-button {
      @include backgroundImage('add_cart.svg', 22px 31px, left 15px center);
      display: block;
      height: 47px;
      max-width: 200px;
      padding: 15px 23px 15px 55px;
      background-color: $colorBetterave;
      color: $colorWhite;
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: none;
      margin: 30px auto 0;

      &:focus,
      &:hover {
        input:not([disabled]) {
          text-decoration: underline;
        }
      }
    }
  }
}
