.page-recherche {
  h1.title {
    margin-bottom: 49px;
  }

  #content > .section {
    @extend %container;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    > * {
      flex: 0 0 100%;
    }
  }

  aside {
    order: -1;
    max-width: 286px;
    padding-right: 15px;

    @include sm {
      max-width: 100%;
      padding-right: 0;
    }

    #block-bordas-search-affiner {
      border-bottom: inherit;
    }

    #facetapi-facet-apachesolrsolr-block-sm-field-ouvrage-type-index {
      span {
        &.label {
          background: $colorGrayClair;
          padding: 0.15rem 0.5rem 0.15rem;
          top: -2px;
          position: relative;
        }
      }
    }

    .block {
      border-bottom: 1px solid $colorSilver;
      margin-bottom: 10px;

      &.block-current-search {
        h2 {
          margin: 0 0 19px;
          color: $colorGray1;
          font-size: 1.7rem;
          line-height: 1.3;
          text-transform: none;
        }

        ul:not(.contextual-links) {
          display: flex;
          flex-wrap: wrap;
        }

        li {
          display: inline-flex;
          margin-right: 20px;

          a {
            width: auto;
          }
        }
      }

      &:not(.block-current-search) {
        h2 {
          &::after {
            @include transition;
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-top-color: $colorBlack;
          }

          &.show-filters {
            &::after {
              transform: rotateX(180deg);
            }

            + .content,
            + .contextual-links-wrapper + .content {
              max-height: 5000px;
              opacity: 1;
              visibility: visible;
              margin-top: 12px;
            }
          }

          + .content,
          + .contextual-links-wrapper + .content {
            @include transition;
            max-height: 0;
            opacity: 0;
            visibility: hidden;
          }

          + .contextual-links-wrapper {
            right: 20px;
          }
        }
      }
    }

    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      color: $colorShark;
      font-size: 1.3rem;
      line-height: 1.7;
      line-height: 23px;
      text-transform: uppercase;
      cursor: pointer;
    }

    h3 {
      margin: 0 0 19px;
      color: $colorGray1;
      font-size: 1.7rem;
      line-height: 1.3;

      &.title {
        color: $colorBetterave;
        padding: 0;
        margin: 0 0 5px 0;
      }
    }

    ul {
      &.facetapi-facetapi-checkbox-links {
        li {
          display: flex;
          align-items: center;
        }
      }

      &.facetapi-bordas-hierarchy {
        li {
          > span:not(.label):not(.count) {
            display: block;
            width: 100%;
            margin-bottom: 15px;
            color: $colorShark;
            font-size: 1.3rem;
            font-weight: 500;
          }

          ul {
            padding-left: 12px;
            border-left: 1px solid $colorSilver;
          }

          .expanded > a {
            margin-bottom: 14px;
          }
        }

        .item-list {
          width: 100%;
        }
      }

      li {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        font-size: 1.3rem;

        span.count {
          margin-left: auto;
          padding-left: 5px;
          width: auto;
          font-weight: 400;
        }
      }

      a {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: $colorShark;
        font-size: 1.3rem;
        text-decoration: none;
        
        .count {
          margin-left: auto;
          padding-left: 5px;
        }

        &.facetapi-active {
          width: auto;
        }
      }
    }

    a {
      display: inline-block;

      &.facetapi-limit-link,
      &.reset {
        display: block;
        margin-bottom: 20px;
        color: $colorBetterave;
        font-size: 1.2rem;
        line-height: 1.7;
        text-align: right;
      }

      &.reset {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &::before {
          @include backgroundImage('reset.svg', 100% auto, left center);
          content: '';
          display: inline-block;
          width: 13px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }

    .remove-filter {
      display: none;
    }
  }

  #main-content {
    max-width: calc(100% - 286px);

    @include sm {
      max-width: 100%;
    }

    .region-content {
      padding-left: 15px;
      border-left: 1px solid $colorSilver;

      @include sm {
        padding-left: 0;
        border-left: 0;
      }

      > .block > .content {
        display: flex;
        flex-wrap: wrap;

        > .node {
          width: 33.33%;
          max-width: 33.33%;

          @include max(580px) {
            width: 50%;
            max-width: 50%;
          }
        }

        > .item-list {
          width: 100%;
        }
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 53px;
      color: $colorGray1;

      @include sm {
        flex-wrap: wrap;
        margin-bottom: 36px;
      }

      > div {
        flex: 0 0 100%;
      }

      .sort {
        // A réafficher ultérieurement #42599.
        display: none;
        justify-content: flex-end;
        max-width: calc(100% - 500px);
        margin-top: 10px;
        font-size: 1.7rem;
        font-weight: bold;
        line-height: 1.3;

        @include md {
          max-width: 50%;
          font-size: 1.6rem;
        }

        @include sm {
          justify-content: space-between;
          max-width: 100%;
        }

        a {
          color: $colorShark;
          font-size: 1.3rem;
          font-weight: 500;
          line-height: 1.1;
          text-decoration: none;
        }

        .item-list {
          max-width: calc(100% - 100px);
          margin-top: -7px;

          @include sm {
            max-width: calc(100% - 80px);
          }
        }

        ul {
          @include transition;
          @include backgroundImage('chevron_bottom.svg', 12px 7px, right 13px top 16px);
          display: flex;
          flex-wrap: wrap;
          padding: 8px 28px 8px 12px;
          border: 1px solid $colorSilver2;
          margin-left: 10px;
          max-height: 39px;
          overflow: hidden;

          &.show {
            max-height: 200px;
          }
        }

        li {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: flex-end;
          width: 100%;
          padding: 5px 0;

          &.active {
            order: -1;
          }
        }

        img {
          display: inline-block;
          margin-top: -4px;
          margin-left: 8px;
        }
      }
    }
  }

  div.count {
    max-width: 498px;
    color: $colorGray1;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;

    @include md {
      max-width: 50%;
    }

    @include sm {
      max-width: 100%;
      padding: 0 10px;
      margin-bottom: 30px;
      font-size: 1.4rem;
    }

    @include xs {
      padding: 0 20px;
      font-size: 1.3rem;
    }

    .input-search {
      color: $colorBetterave;
    }
  }

  ul {
    @extend %noStylelist;
  }
}
