////////////////////////
// Mixins Partials
//
// All of your mixins should go in this file.
////////////////////////

@mixin overstrike($color: $colorWhite, $width: 90px, $height: 1px, $margin: 10px 0) {
	content: '';
	display: block;
	width: $width;
	height: $height;
	margin: $margin;
	background-color: $color;
}

@mixin placeholder-color($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}
	&:-moz-placeholder {
	/* FF 4-18 */
		color: $color;
	}
	&::-moz-placeholder {
		/* FF 19+ */
		color: $color;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: $color;
	}
}
@mixin placeholder-uppercase() {
	&::-webkit-input-placeholder {
		text-transform: uppercase;
	}
	&:-moz-placeholder {
	/* FF 4-18 */
		text-transform: uppercase;
	}
	&::-moz-placeholder {
		/* FF 19+ */
		text-transform: uppercase;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		text-transform: uppercase;
	}
}

@mixin arrow($direction, $color, $width, $height) {
  display: block;
  height: 0;
  width: 0;
  content: '';
	border-style: solid;

	@if $direction == 'top' {
		border-width: 0 $width/2 $height $width/2;
		border-color: transparent transparent $color transparent;
	}
	@else if $direction == 'bottom' {
		border-width: $height $width/2 0 $width/2;
		border-color: $color transparent transparent transparent;
	}
	@else if $direction == 'left' {
		border-width: $height/2 $width $height/2 0;
		border-color: transparent $color transparent transparent;
	}
	@else if $direction == 'right' {
		border-width: $height/2 0 $height/2 $width;
		border-color: transparent transparent transparent $color;
	}
	@else if $direction == 'top-left' {
		border-width: $height $width 0 0;
		border-color: $color transparent transparent transparent;
	}
	@else if $direction == 'top-right' {
		border-width: 0 $width $height 0;
		border-color: transparent $color transparent transparent;
	}
	@else if $direction == 'bottom-left' {
		border-width: $height 0 0 $width;
		border-color: transparent transparent transparent $color;
	}
	@else if $direction == 'bottom-right' {
		border-width: 0 0 $height $width;
		border-color: transparent transparent $color transparent;
	}
}

@mixin box-sizing($box_sizing) {
	-webkit-box-sizing: $box_sizing;
	-moz-box-sizing: $box_sizing;
	box-sizing: $box_sizing;
	// *behavior: url(../javascripts/boxsizing.htc);
}

// Mixins for responsive design
@mixin max($maxwidth) {
  @media only screen and (max-width: $maxwidth) {
    @content;
  }
}

@mixin min($minwidth) {
  @media only screen and (min-width: $minwidth) {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: #{$md-width}) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin sm-up {
  @media (min-width: #{$sm-up}) {
    @content;
  }
}

@mixin xs {
  @media only screen and (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin xs-header {
  @media only screen and (max-width: #{$xs-header}) {
    @content;
  }
}

// Mixin for background images
@mixin backgroundImage($image: '', $size: '', $position: '') {
	@if ($image != '') {
		background-image: url(../images/#{$image});
	}
  @if ($size != '') {
    background-size: $size;
  }
	@if ($position != '') {
		background-position: $position;
	}

	background-repeat: no-repeat;
}

// Mixin for full width background
@mixin fullWidthBackground($color, $direction: both) {
  position: relative;
  background-color: $color;

  @if ($direction == "left") or ($direction == "both") {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      width: 9999px;
      height: 100%;
      background-color: $color;

      @include md {
        width: 10px;
			}

			@include xs {
				width: 20px;
			}

    }
  }

  @if ($direction == "right") or ($direction == "both") {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 9999px;
      height: 100%;
      background-color: $color;

      @include md {
        width: 10px;
			}

			@include xs {
				width: 20px;
			}
    }
  }
}

// Mixin for transitions
@mixin transition($element: all, $effect: ease-out, $duration: 0.3s) {
	transition: $element $effect $duration;
}

// Mixin for form buttons
@mixin buttonForm($image-name) {
  @include backgroundImage('buttons/#{$image-name}' + '.png', 100% auto, center);
  height: 41px;
  width: 165px;

  &:hover,
  &:focus {
    @include backgroundImage('buttons/#{$image-name}' + '-hover.png');
  }
}

// Mixin for placeholder styles
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

// font size mixin

@function get-vw($target) {
  $vw-context: (768*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

@mixin eyeShowPassword {
  height: 100%;
  width: 20px;
  position: absolute;
  background: url(/sites/all/themes/bordas2019/assets/images/eye_icon.svg) no-repeat center;
  top: 12px;
  background-size: 100%;
  right: 15px;

  &:hover {
    cursor: pointer;
  }
}
