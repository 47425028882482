.page-mes-commandes {
  #breadcrumb ul li+li {
    padding-left: 25px;
    margin-left: 18px;
    position: relative;
    display: inline-flex;
    align-items: center;
    color: $colorTundora;
    font-size: 1.2rem;
    line-height: 1.2;

    >a::before,
    >span::before {
      display: none;
    }

    &::before {
      @include backgroundImage('arrow.svg', cover, center);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 12px;
      height: 12px;

      @include xs {
        top: auto;
      }
    }
  }

  .region-content {
    position: relative;
    width: 100%;
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 10px 40px;

    @include sm {
      padding: 0 35px;
    }

    @include xs {
      padding: 40px 20px;
    }
  }

  h1 {
    color: $colorBetterave;
	  text-align: center;
  }

  .mes-commandes:visited {
    color: $colorWhite;
  }

  .link-order {
    color: $colorWhite;
    background-color: $colorBetterave;
    font-size: 13px;
    font-weight: bold;
    padding: 8px 18px;
    text-decoration: underline;
  }

  .wrapper-entete {
    display: flex;
    color: $colorEmperor;
    margin-bottom: 11px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.9;
    justify-content: space-between;
    margin-top: 3rem;
  }

  .line-product {
    background-color: $colorGrisClair;
    font-size: 1.2rem;
    line-height: 1.44;
    display: flex;

    @include sm {
      flex-direction: column;
    }

    &+.line-product {
      margin-top: -1px;
    }

    .product {
      color: $colorEmperor;
      text-decoration: underline;
      font-weight: 400;

      @include sm-up {
        width: 33.33%;
      }
    }

    .product-price {
      font-weight: 500;
      margin-left: auto;

      @include sm {
        margin-left: 0;
      }
    }

    .product-detail {
      display: flex;
      margin: 0 -10px;
      align-items: flex-start;
      width: 100%;

      @include sm {
        flex-direction: column;
      }

      >* {
        padding: 0 10px;
      }
    }

    .quantity {
      background-color: $colorWhite;
      font-weight: 500;

      @include sm-up {
        margin: 0 auto;
      }
    }

    .product-informations {
      margin-left: auto;

      @include sm-up {
        width: 33.33%;
      }

      @include sm {
        margin-left: 0;
      }
    }
  }

  .line-product,
  .wrapper-informations {
    border: 1px solid $colorSilver;
    padding: 24px 20px;
  }

  .wrapper-payment,
  .wrapper-sub-total,
  .wrapper-total {
    padding: 24px 20px;
  }

  .wrapper-sub-total,
  .wrapper-informations,
  .wrapper-payment {
    font-size: 13px;
  }

  .wrapper-sub-total,
  .wrapper-total {
    background-color: $colorWhite;
    float: right;

    @include sm {
      float: none;
    }

    >div {
      display: flex;
      margin: 0 -10px 5px;

      >div {
        padding: 0 10px;
      }

      .wrapper-label-sub {
        font-weight: 500;
      }
    }
  }

  .wrapper-sub-total {
    margin: 26px 0 10px;
    padding-bottom: 0;
  }

  .wrapper-total {
    clear: right;
    padding-top: 0;

    .price-sous-total {
      font-weight: 500;
      color: $colorBetterave;
    }
  }

  .wrapper-informations,
  .wrapper-payment {
    .entete {
      margin: 0 0 5px;
      font-weight: 500;
    }
  }

  .wrapper-informations {
    display: flex;
    width: 100%;
    max-width: 570px;
    margin: 26px auto 22px 0;

    @include sm {
      display: block;
      max-width: 100%;
      float: none;
    }

    .wrapper-facturation {
      margin-left: 20px;

      @include sm {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  .wrapper-payment {
    padding-top: 0;
  }
}

table#mes-commandes {
  @extend %cart-summary-table;
  margin-bottom: 5rem;

  thead {
    th {
      padding: 11px 5px;

      &, a {
        color: $colorGrayDark;
        text-decoration: none;
      }
    }
  }

  td.order-details:last-child {
    padding-left: 0;
  }

  .aide-state {
    display: inline-flex;
    align-items: center;
    font-size: 0;
    vertical-align: middle;

    &::before {
      @include backgroundImage('question_icon.svg', 100% auto, center);
    }
  }

  .order-details {
    .link-order {
      background-color : #d93b63;
      border-color : #D8325C;
      text-decoration: none;
      display: inline-block;
      &:focus {
        text-decoration: underline;
        background-color : #D8325C;
      }
    }
  }
}
.page-mes-commandes {
  .wrapper-sub-total {
    float: none;
  }
  .wrapper-total {
    float: none;
  }
  .wrapper-details .wrapper-price {
    padding: 0 10px;
  }
}

.commandes-desktop {
  @include sm {
    display: none;
  }
}

.commandes-mobile {
  font-size: 14px;
  line-height: 1.44;

  @include sm-up {
    display: none;
  }

  .link-order {
    display: inline-block;
    margin: 10px 0;
  }

  li {
    list-style: none;
    margin: 0 0 3rem;
  }

  .state-label {
    font-weight: 500;
    color: $colorGrayDark;
  }
}
