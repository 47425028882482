.page-prescription {
    main .contextual-links-region .section {
        @extend %container;
        .block-system {
            >.content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0 4px 8px 0 $colorGray2_50;
                background-color: $colorWhite;
                padding: 60px 40px;
                margin: 25px auto;
                max-width: 750px;
                @include xs {
                    padding: 30px 10px;
                }
                h4.field-type-text-long {
                    text-align: left;
                    width: 100%;
                    padding-left: 200px;
                    @include backgroundImage('logo.svg', 140px, left top);
                    padding-bottom: 15px;
                    border-bottom: 1px solid $colorWildSand;
                    @include max(530px) {
                        padding-top: 80px;
                        padding-left: 0;
                        background-position: top center;
                        text-align: center;
                    }
                }
                .prescription-header-wrapper {
                    display: flex;
                    justify-content: space-between;
                    margin: 30px auto;
                    font-size: 1.4rem;
                    @include xs {
                        width: 100%;
                        flex-wrap: wrap;
                    }
                    >div {
                        width: 45%;
                        @include xs {
                            width: 100%;
                            margin-bottom: 35px;
                        }
                        .item-list ul {
                            @extend %noStylelist;
                            li {
                                margin-bottom: 5px;
                                .field-label {
                                    color: $colorBoulder;
                                    text-transform: uppercase;
                                    &::after {
                                        content: ":";
                                        display: inline-block;
                                        margin-right: 3px;
                                    }
                                }
                            }
                        }
                    }
                    .prescription-encart-title {
                        width: 100%;
                        margin-bottom: 12px;
                        font-family: $fontK2D;
                        font-size: 2.4rem;
                        font-weight: 800;
                        color: $colorOrange;
                        line-height: 1;
                        text-align: left;
                        text-transform: none;
                        @include sm {
                            font-size: 2.4rem;
                        }
                    }
                }
                .prescription-attestation-wrapper {
                    text-align: center;
                    background-color: $colorWildSand;
                    padding: 30px 15px;
                    margin-bottom: 25px;
                    .prescription-attestation-title,
                    .prescription-paragraph-title {
                        width: 100%;
                        margin-bottom: 12px;
                        font-size: 1.6rem;
                        font-weight: 600;
                        color: $colorBlueBayoux;
                        line-height: 1;
                        text-align: center;
                        text-transform: none;
                    }
                    .prescription-attestation-title {
                        font-size: 2.4rem;
                        margin-bottom: 25px;
                        &::after {
                            @include overstrike($colorSilver, 120px, 3px, 10px auto);
                        }
                    }
                    .prescription-paragraph-title {
                        margin: 25px 0 15px;
                    }
                    .item-list {
                        text-align: left;
                        margin-bottom: 25px;
                        li {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            .btn-validation-wrapper {
                text-align: center;
                a {
                    @extend %buttonDefault;
                }
            }
        }
    }
}