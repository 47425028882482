.entity-ressource-numerique {
  > .content {
    display: flex;
    margin-bottom: 30px;
    
    > div {
      width: 100%;
    }
  }
  
  .group-ressource-left {
    max-width: 128px;
    margin-right: 10px;
    
    img {
      width: 100%;
      max-height: 170px;
      box-shadow: 0 2px 8px 0 $colorBlack34;
    }
  }

  .group-ressource-right {
    max-width: calc(100% - 128px);
    padding-right: 20px;
  }

  .ressource-ouvrage-wrapper {
    > div {
      padding: 0;
    }

    .content {
      padding-top: 0;
    }
  }

  h4 {
    margin: 15px 0 0;

    @include xs {
      margin-top: 5px;
    }

    a {
      color: $colorShark;
      font-size: 1.3rem;
      line-height: 1.7;

      @include xs {
        font-size: 1.2rem;
      }
    }
  }

  .serial-wrapper,
  .expiration-wrapper {
    margin-bottom: 20px;
    word-break: break-all;
    word-break: break-word;

    @include xs {
      margin-bottom: 10px;
    }
  }

  .ressources-links {
    a {
      @extend %buttonIcon;
      padding: 8px 13px 8px 38px;
      margin-bottom: 10px;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.1;

      &.link-consulter {
        &::before {
          @include backgroundImage('arrow_white.svg');
          left: 13px;
          width: 11px;
          height: 11px;
          transform: rotate(-90deg);
        }
  
        &:focus,
        &:hover {
          &::before {
            transform: scale(1.1) rotate(-110deg);
          }
        }
      }

      &.link-telecharger {
        &::before {
          @include backgroundImage('icon_download.svg');
          left: 13px;
          width: 20px;
          height: 17px;
        }

        &:focus,
        &:hover {
          &::before {
            transform: scale(1.1) rotate(10deg);
          }
        }
      }

    }

    ul {
      @extend %noStylelist;
    }
  }
}