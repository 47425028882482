////////////////////////
// Extendables Partials
//
// All of your extendable classes, ids, and placeholder extendables
//   should go in this file.
////////////////////////

%clearfix {
  &::after {
	  content: " ";
	  display: block;
	  clear: both;
  }
}

%container {
  position: relative;
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 10px;

  @include sm {
    padding: 0 35px;
  }

  @include xs {
    padding: 0 20px;
  }
}

%noStylelist {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

%styleUl {
  list-style: none;

  li {
    display: inline-block;
    padding: 0;
    margin: 0 0 5px;
    list-style: none;

    &::before {
      content: '';
      display: inline-flex;
      flex-shrink: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-top: 5px;
      margin-right: 17px;
      background-color: $colorBetterave;

      @include sm {
        margin-top: 6px;
        margin-right: 10px;
      }
    }
  }
}

%styleOl {
  > li {
    padding: 8px 0;

    &::before {
      position: relative;
      top: 0;
      left: 0;
      display: inline-block;
      min-width: 25px;
      padding: 0;
      background-color: transparent;
      color: $colorBetterave;
      font-size: 1.188em;
      font-weight: 700;
      line-height: 1;
      text-align: left;

      @include sm {
        min-width: 20px;
      }
    }
  }
}

%hiddenText {
  font-size: 0;
  color: transparent;
}

%sectionTitle {
  width: 100%;
  margin: 0;
  font-family: $fontK2D;
  font-size: 3rem;
  font-weight: 800;
  line-height: 31px;
  text-align: center;
  text-transform: none;

  @include sm {
    font-size: 2.4rem;
  }

  &::after {
    @include overstrike($colorSilver, 120px, 3px, 10px auto);
  }
}

%boxShadowDefault {
  box-shadow: 2px 2px 8px $colorBlack25;
}

%buttonDefault {
  @include transition;
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  color: $colorWhite;
  font-family: $fontRubik;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: $colorBetterave;
  z-index: 0;

  @include sm {
    padding: 7.5px 14.5px;
  }

  &:focus,
  &:hover {
    @extend %boxShadowDefault;
    text-decoration: underline;
    opacity: 0.8;
  }
}

%buttonBlue,
%buttonOrange,
%buttonMauve,
%buttonVerte,
%buttonGris {
  @extend %buttonDefault;
}

%buttonBlue {
  background-color: $colorBleuClaire;
}

%buttonBlueDark {
  background-color: $colorBlueBayoux;
}

%buttonOrange {
  background-color: $colorOrange;
}

%buttonMauve {
  background-color: $colorMauve;
}

%buttonVerte {
  background-color: $colorVerte;
}

%buttonGris {
  background-color: $colorWildsand1;
  color: inherit;
}

%buttonInput {
  @extend %buttonDefault;
  background-color: $colorBetterave;
}

%buttonIcon {
  @extend %buttonDefault;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 15px 30px 15px 55px;
  text-transform: none;

  &::before {
    @include backgroundImage('', 100% auto, center);
    @include transition;
    content: '';
    position: absolute;
    left: 18px;
    display: inline-block;
    width: 22px;
    height: 31px;
  }

  &:focus,
  &:hover {
    &::before {
      transform: scale(1.1) rotate(10deg);
    }
  }
}

%tableTextMobile {
  content: '';
  display: block;
  width: 100px;
  height: auto;
  color: $colorEmperor;
  font-weight: 500; 
}

%cart-summary-table {
  width: 100%;
  font-size: 1.2rem;

  thead {
    @include sm {
      display: none;
    }

    th {
      border: none;
      background-color: transparent;
      color: $colorGrayDark;
      text-align: left;
      padding: 11px 20px 11px 0;
      font-weight: 500;
      line-height: 1.9;

      &:first-child {
        padding-left: 20px;
      }

      &.views-align-right {
        text-align: right;
      }
    }
  }

  tbody {
    border: none;

    tr {
      padding: 0;
      border: 1px solid $colorSilver;
      background-color: $colorGrisClair;
    }

    td {
      padding: 23px 5px;
      text-align: left;

      @include sm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 5.5px 0;
      }
      
      &:first-child {
        padding: 23px 17px;

        @include sm {
          padding: 0;
        }
      }

      &:last-child {
        padding: 23px 24px;

        @include sm {
          padding: 0;
        }
      }

      &.views-align-right {
        text-align: right;
      }

      &.views-field-field-ouvrage-image {
        width: 100px;

        @include sm {
          -ms-grid-column: 1;
          grid-column: 1;
          -ms-grid-row: 1;
          -ms-grid-row-span: 4;
          grid-row: 1 / 5;
          width: 100%;
          padding: 0;
          align-self: flex-start;
        }

        @include xs {
          width: 45px;
        }

        img {
          max-height: 85px;
          max-width: 64px;

          @include sm {
            width: 100%;
            max-height: 125px;
            max-width: 94px;
          }

          @include xs {
            max-height: 60px;
            max-width: 45px;
          }
        }
      }

      &.views-field-line-item-title {
        max-width: 370px;
        padding: 0 10px 0 0;

        @include sm {
          -ms-grid-column: 2;
          grid-column: 2;
          -ms-grid-row: 1;
          grid-row: 1;
          max-width: 100%;
          padding-top: 33px;
          padding-bottom: 30px;
        }

        @include sm {
          padding-top: 22px;
          padding-bottom: 15px;
        }

        a {
          color: $colorEmperor;
        }
      }

      &.views-field-commerce-total {
        color: $colorBetterave;
        font-weight: 700;

        @include sm {
          -ms-grid-column: 2;
          grid-column: 2;
          -ms-grid-row: 5;
          grid-row: 5;
          width: 100%;
          max-width: 234px;
          margin-left: auto;
        }

        @include xs {
          max-width: 150px;
        }

        &::before {
          @extend %tableTextMobile;

          @include sm {
            content: 'Total TTC';
          }
        }
      }

      &.views-field-commerce-unit-price,
      &.views-field-field-ean13 {
        font-weight: 500;
      }

      &.views-field-field-ean13 {
        @include sm {
          -ms-grid-column: 2;
          grid-column: 2;
          -ms-grid-row: 2;
          grid-row: 2;
        }

        &::before {
          @extend %tableTextMobile;

          @include sm {
            content: 'Référence';
          }
        }
      }

      &.views-field-commerce-unit-price {
        @include sm {
          -ms-grid-column: 2;
          grid-column: 2;
          -ms-grid-row: 3;
          grid-row: 3;
        }

        &::before {
          @extend %tableTextMobile;

          @include sm {
            content: 'Prix unitaire TTC';
          }
        }
      }

      &.views-field-edit-quantity {
        @include sm {
          -ms-grid-column: 2;
          grid-column: 2;
          -ms-grid-row: 4;
          grid-row: 4;
        }

        &::before {
          @extend %tableTextMobile;

          @include sm {
            content: 'Quantité';
          }
        }

        .form-item {
          display: flex;
          align-items: center;

          @include sm {
            justify-content: flex-end;
          }
        }

        input {
          height: 39px;
          width: 100%;
          max-width: 70px;
          padding: 13px 17px 11px 17px;
          font-weight: 500;

          @include sm {
            margin-right: -21px;
          }
        }
      }

      &.views-field-quantity {
        font-weight: 500;

        @include sm {
          -ms-grid-column: 2;
          grid-column: 2;
          -ms-grid-row: 4;
          grid-row: 4;
        }

        &::before {
          @extend %tableTextMobile;

          @include sm {
            content: 'Quantité';
          }
        }
      }

      &.views-field-edit-delete {
        text-align: center;

        @include sm {
          -ms-grid-column: 1;
          grid-column: 1;
          -ms-grid-row: 5;
          grid-row: 5;
        }

        @include xs {
          margin-right: -20px;
        }
        
        input {
          @include backgroundImage('delete_grey.svg', 14px 18px, center top);
          padding-top: 24px;
          background-color: transparent;
          color: $colorBetterave;
          font-size: 1.1rem;
          font-weight: normal;
          text-decoration: underline;
          text-transform: none;

          @include sm {
            padding: 10px 0 10px 25px;
            background-position: left center;
          }
        }
      }
    }
  }
}

%commerce-pane {
  border: 1px solid $colorSilver;
  // background-color: $colorGrisClair;
  background-color: $colorWhite;
  padding: 24px 45px;

  @include xs {
    padding: 24px 15px;
  }
}