.vocabulary-collection {
  .content {
    >.group-global-wrapper {
      @extend %container;
      display: flex;
      flex-wrap: wrap;
    }

    .group-main-wrapper {
      width: calc(75% - 40px);
      margin: 0 auto;
      overflow: hidden;
      padding: 60px 0;
      display: grid;
      display: -ms-grid;
      grid-template-columns: auto 2fr;
      grid-template-rows: auto 1fr;
      -ms-grid-columns: auto 2fr;
      -ms-grid-template-rows: auto 1fr;
      position: relative;

      @include sm {
        padding: 0;
        width: 100%;
        margin: 30px 0;
        grid-template-columns: auto 2fr;
        -ms-grid-columns: auto 2fr;
        justify-items: start;
      }

      .field-type-image,
      .field-name-field-collection-image {
        padding-right: 40px;
        grid-column: 1;
        -ms-grid-column: 1;
        grid-row: span 2;
        -ms-grid-row-span: 2;

        img {
          max-width: 115px;
          object-fit: cover;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.34);

          &.figure-auteur-none {
            box-shadow: none;
          }
        }

        @include sm {
          padding-right: 25px;
          grid-row: 1;
          -ms-grid-row: 1;
          top: 0;
        }
      }

      h1.title {
        font-family: $fontK2D;
        color: $colorBlueBayoux1;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        font-size: 3rem;
        font-weight: 800;
        line-height: 31px;
        text-align: center;
        grid-column: 2;
        -ms-grid-column: 2;
        grid-row: 1;
        -ms-grid-row: 1;


        @include sm {
          font-size: 2.8rem;
          line-height: 32px;
          width: 100%;
          white-space: normal;
        }

        @include sm-up {
          width: 100%;
        }
      }

      >.field-name-field-collection-image {
        position: relative;
        top: -10px;
        grid-column: 1;
        -ms-grid-column: 1;

        img {
          max-width: 115px;
          margin: 10px;
        }

        @include sm {
          top: 0;
        }

        + h1.title {
          text-align: left;
        }

        ~ .field-name-field-collection-matiere {
          display: block;
        }
      }

      .field-name-field-description {
        margin-top: 40px;
        font-family: $fontRubik;
        font-size: 1.4rem;
        line-height: 2.3rem;
        letter-spacing: letterSpacing(05);
        color: $colorBlack;
        text-align: left;
        width: 100%;
        grid-column: 2;
        -ms-grid-column: 2;

        @include sm {
          width: 100%;
          grid-column: 1 / span 2;
          -ms-grid-column-span: 3;
          grid-row: 2;
          -ms-grid-row: 2;
          margin-top: 20px;
        }

        ul {
          @extend %styleUl;
        }
      }
      .field-name-field-collection-matiere {
        font-family: $fontRubik;
        font-size: 1.5rem;
        line-height: 2.3rem;
        letter-spacing: letterSpacing(05);
        color: $colorEmperor;
        grid-column: 2;
        -ms-grid-column: 2;
        -ms-grid-row: 2;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include sm {
          white-space: normal;
        }

        &::after {
          content: '';
          display: block;
          margin-top: 10px;
          width: 105px;
          height: 3px;
          background-color: $colorBetterave;
        }
      }

    }

    .group-link-wrapper {
      width: calc(100% / 12 * 3);
      background-color: $colorGrisClair;
      padding: 33px 20px;
      height: 100%;
      margin-top: 60px;
      margin-left: 40px;

      @include sm {
        width: 100%;
        margin-top: 0;
        background-color: transparent;
      }

      h3 {
        margin: 0;
        font-size: 1.5rem;
        line-height: 2.3rem;
        color: $colorEmperor;
      }

      .field-item {
        a {
          font-size: 1.3rem;
          line-height: 3rem;
          color: $colorShark;
          text-align: left;
          font-weight: normal;
          text-decoration: underline;
          display: flex;
          align-items: center;

          &::before {
            content: "";
            display: block;
            width: 8px;
            height: 12px;
            margin-right: 14px;
            @include backgroundImage('chevron_right-red.svg', 7px, left center);
          }
        }
      }
    }

    .ouvrage-collection-wrapper {
      width: 100%;
      text-align: center;
      padding: 33px 0;
      background-color: $colorWildSand;

      >.title {
        font-family: $fontK2D;
        color: $colorBlueBayoux1;
        margin-bottom: 30px;
        font-size: 3rem;
        font-weight: 800;
        line-height: 31px;
        text-align: left;
        padding: 0 35px;
        text-align: center;

        >a {

          font-family: $fontRubik;
          color: $colorShark;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.4;
          text-decoration: none;

          &:focus,
          &:hover {
            color: $colorBlueBayoux;
            text-decoration: underline;
          }

          @include xs {
            font-size: 1.2rem;
          }
        }

        span {
          color: $colorBetterave;
        }

        &::after {
          content: '';
          display: block;
          margin: 10px auto;
          width: 105px;
          height: 3px;
          background-color: $colorSilver;
        }
      }

      .item-list {
        @extend %container;

        .ouvrages-list {
          @extend %noStylelist;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          &.slick-slider {
            display: block;
          }

          @include md {
            max-width: calc(100% - 160px);
            margin: 0 auto;
          }
      
          @include sm {
            max-width: calc(100% - 40px);
          }
      
          @include xs {
            max-width: 100%;
          }

          .slick-list {
            @include xs {
              max-width: calc(100% + 260px);
              margin-right: -130px;
              margin-left: -130px;
            }
          }

          .slick-slide .node {
            @include xs {
              margin: 0 20px;
            }
          }

          
          .slick-dots {
            .slick-active button {
              background-color: $colorBetterave;
            }

            li:not(:last-child) {
              padding-right: 11px;
            }
          }

          .slick-arrow {
            top: 25%;
      
            @include sm {
              height: 48px;
              width: 48px;
              background-size: 18px 18px;
            }
      
            &.slick-next {
              right: -80px;
      
              @include sm {
                right: -40px;
              }
            }
      
            &.slick-prev {
              left: -80px;
      
              @include sm {
                left: -40px;
              }
            }
          }
        }
      }


      .link-collection-ouvrages {
        @extend %buttonDefault;
        margin: 25px 10px 0;

      }
    }
  }
}