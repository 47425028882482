.page-mes-favoris {
  main {
    @extend %container;

    .view-header {
      margin-top: 17px;
      margin-bottom: 45px;
      color: $colorShark;
      font-size: 1.3rem;
      line-height: 1.5;
    }

    .view-content {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 30px;

      >* {
        flex: 0 0 100%;
      }

      .views-row {
        width: 25%;
        max-width: 25%;

        @include sm {
          width: 33.33%;
          max-width: 33.33%;
        }

        @include max(580px) {
          width: 50%;
          max-width: 50%;
        }
      }
    }
  }
}