input[type="text"],
input[type="password"] {
  height: 43px;
  border: 1px solid $colorSilver;
  background-color: $colorWhite;
  padding: 14px 18px 12px;
}

form {
  .form-item {
    margin: 0;
  }
}

label {
  font-size: 1.4rem;
  text-transform: uppercase;

  &.label_error {
    color: $colorCrimson !important;

    + select,
    + input {
      border: 1px solid $colorCrimson !important;
      background: $colorCrimson_10;
      color: $colorCrimson !important;
    }

    img {
      display: none;
    }
  }
}

label.option {
  text-transform: none;
}

input,
textarea {
  border: 1px solid $colorGray;
  padding: 4px;
  background-color: $colorWhite;
  color: $colorBlack;
}

input[type=email],
input[type=password],
input[type=text],
select,
textarea {
  width: 100%;
}

a.button,
input[type=submit] {
  width: auto;
  border: none;
  color: $colorWhite;
  background-color: $colorBlack;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 3px 20px;
}

input[type=checkbox] {
  width: auto;
}

input,
select {
  &[disabled] {
    opacity: 0.5;
  }
}

select {
  @include backgroundImage('chevron_bottom.svg', 12px 8px, right 16px center);
  -webkit-appearance: none;
  -moz-appearance: none;
  width: auto;
  padding: 13px 34px 13px 14px;
  background-color: $colorWhite;
  border: 1px solid $colorSilver2;
  border-radius: 0;
  color: $colorBlack;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.1;

  &::-ms-expand {
    display: none;
  }
}

input.facetapi-checkbox {
  -webkit-appearance: none;
  border: 0;
}

a {
  &.facetapi-active,
  &.facetapi-checkbox,
  &.facetapi-inactive {
    position: relative;
    padding-left: 26px;

    &::after,
    &::before {
      position: absolute;
      display: block;
      height: 16px;
    }

    &::before {
      content: '';
      top: -2px;
      left: 0;
      width: 16px;
      border: 1px solid $colorSilver;
      border-radius: 2px;
      background-color: $colorWhite;
    }

    &::after {
      top: -8px;
      left: 8px;
      width: 7px;
      border: solid $colorBetterave;
      border-top: 2px;
      border-left: 2px;
      transform: rotate(45deg);
    }
  }

  &.facetapi-active {
    @extend %hiddenText;
    display: inline-block !important;
    height: 16px;

    &::after {
      content: '';
    }
  }

  .current-search-item-active-items & {
    @extend %hiddenText;
    position: relative;
    display: inline-block !important;
    height: 16px;
    padding-left: 26px;

    &::after,
    &::before {
      content: '';
      position: absolute;
      display: block;
      height: 16px;
    }

    &::before {
      top: -2px;
      left: 0;
      width: 16px;
      border: 1px solid $colorSilver;
      border-radius: 2px;
      background-color: $colorWhite;
    }

    &::after {
      top: -8px;
      left: 8px;
      width: 7px;
      border: solid $colorBetterave;
      border-top: 2px;
      border-left: 2px;
      transform: rotate(45deg);
    }
  }
}

#ws_global {
  width: 100%;
}

#div_profil_choice,
#form_account,
#group_institution_infos,
#group_personal_infos,
#div_registration_confirmation {
  width: 100%;
  border: 0;
  margin-bottom: 20px;

  > div[id]:not(#div_registration_confirmation) {
    width: 100%;
    border: 0;
  }
}

#form_account {
  color: $colorShark;
  font-family: $fontRubik;
}

#ws_global {
  #form_account_errors_display {
    width: 100%;
    padding: 0;
    border: 0;
    background-color: transparent;
    
    table {
      width: 100%;
      color: $colorCrimson;
      font-family: $fontRubik;
      font-size: 1.3rem;
      font-weight: 500;
      
      tbody {
        border: 0;
      }
    }
    
    td {
      display: block;
      padding: 13px 14px;
      border: 1px solid $colorCrimson;
      margin-bottom: 21px;
      background: $colorCrimson_10;
      color: $colorCrimson;
    }
  }
}

.marker, 
.form-required {
  color: $colorCrimson;
}

// Buttons
// - Creér un compte
#div_profil_choice {
  .submit_button {
    @include buttonForm('button_red_create_account');
  }
}

// - Recherche
#div_institution_ens {
  #institution_ens_first_step_submit {
    @include buttonForm('button_red_search');
  }
}

// - Valider
#global_registration_ele_submit,
#global_registration_ens_submit,
#global_registration_ent_submit,
#global_registration_etu_submit,
#global_registration_par_submit,
#div_lost_password #edit-submit {
  @include buttonForm('button_red_submit');
}

// - Add a child
#div_profil_par_subform {
  .submit_button {
    @include buttonForm('button_red_add_child');
  }
}

// - Calendar
#div_profil_ele_subform,
#div_profil_ens_subform,
#div_profil_ent_subform,
#div_profil_etu_subform,
#div_profil_par_subform {
  button.calendar {
    @include backgroundImage('buttons/button_red_calendar.svg', 100% auto, center);
    height: 20px;
    width: 20px;
    border: 0;
    margin-left: 5px;
  }
}

.acaForm__select select {
  padding-top: 8px;
  line-height: 24px;
}
