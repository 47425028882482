// #postface,
// #toolbar {
//   .section {
//     @extend %clearfix;
//     max-width: $site-max-width;
//     margin: 0 auto;
//     position: relative;
//   }
// }
//
// .region {
//   @extend %clearfix;
// }
//
// .block {
//   position: relative;
// }

.social-sharing-items {
  @extend %noStylelist;
  display: flex;

  li {
    display: flex;
    align-items: center;
    margin-right: 30px;

    a {
      display: flex;
      align-items: center;

      &::before {
        @include backgroundImage('', contain, center);
        content: '';
        display: inline-block;
        padding-right: 13px;
      }

      &.link-print::before {
        @include backgroundImage('printer_icon.svg');
        width: 20px;
        height: 20px;
      }

      &.flag::before {
        @include backgroundImage('add_favoris.svg');
        width: 18px;
        height: 20px;
      }

      &.flag.unflag-action::before {
          @include backgroundImage('remove_favoris.svg');
      }

      &.flag {
        @include transition;
        &:focus,
        &:hover {
          &.flag-action::before {
            @include backgroundImage('add_favoris_blue.svg');
          }

           &.unflag-action::before {
            @include backgroundImage('remove_favoris_blue.svg');
          }
        }
      }

      &.link-social::before {
        @include backgroundImage('share_icon.svg');
        width: 19px;
        height: 20px;
      }
    }
  }

  .flag-wrapper {
    display: flex;
  }

  a {
    color: $colorBlack;
    font-size: 1.1rem;
    line-height: 1.1;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  .list-social {
    @extend %noStylelist;
    display: none;

    &.show {
      display: flex;

      &::before {
        content: '>';
      }
    }

    &::before {
      display: inline-block;
      width: 10px;
      height: 12px;
      margin-left: 10px;
      font-size: 1.1rem;
    }
  }
}

#main-content {
  & > div.tabs {
    @extend %container;
  }
}

