.region-pre-footer {
  .surfooter-title {
    @extend %sectionTitle;
  }

  .block-bordas-block {
    .content {
      @extend %container;
      display: flex;
      flex-wrap: wrap;
    }
  }

  #block-bordas-block-bordas-refonte-surfooter-top {
    background-color: $colorLynch;
    overflow: hidden;

    @include sm {
      background-color: $colorBlueBayoux1;
    }

    .content {
      padding-top: 29px;
      padding-bottom: 66px;
      flex-wrap: wrap;

      @include sm {
        padding-bottom: 25px;
      }

      > div,
      > form {
        width: 100%;
        max-width: 50%;

        @include sm {
          max-width: 100%;
        }

        &::before {
          position: absolute;
          top: 50%;
          display: block;
          border-radius: 0 60% 60% 0;
          transform: translateY(-50%);

          @include min($sm-width) {
            content: '';
          }
        }
      }
      > div.surfooter-title.titre-newsletter {
        max-width: 100%;
      }

      > form {
        display: block;

        &::before {
          right: 47%;
          height: 800px;
          width: 55vw;
          background-color: $colorBlueBayoux;
          z-index: 1;
        }
      }

      .social-links {
        @include sm {
          display: flex;
          flex-direction: column;

          .surfooter-subtitle,
          .item-list {
            z-index: 2;
            position: relative;
          }
        }
      }
    }

    .surfooter-title {
      position: relative;
      color: $colorWhite;
      z-index: 2;
    }

    form {
      > div {
        position: relative;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        z-index: 2;

        @include xs {
          justify-content: center;
          margin-bottom: 29px;
          // display: block;
        }
      }

      label {
        display: inline-block;
        margin-bottom: 20px;
        color: $colorWhite;
        font-size: 1.4rem;
        font-weight: 400;
        text-transform: none;
      }

      .form-item-cible {
        max-height: 39px;
        margin-right: 10px;

        @include xs {
          width: 100%;
          margin-right: 0;
          margin-top: 38px;
        }

        select {
          width: 147px;
          height: 39px;
          padding-top: 10px;
          padding-bottom: 12px;
          border: 0;
          background-size: 8px 6px;
          -webkit-appearance: none;
          -moz-appearance: none;

          @include xs {
            width: 100%;
          }
        }
      }

      .form-item-email {
        width: calc(100% - 157px - 52px);

        @include xs {
          width: 100%;
        }

        label {
          @include min($sm-width) {
            transform: translateX(-157px);
          }

          @include sm {
            position: absolute;
            top: 10px;
            left: 0;
            right: 0;
            text-align: center;
          }

          @include xs {
            top: 0;
          }
        }

        input {
          @include placeholder-color($colorShark);
          max-height: 39px;
          padding: 13px 14px;
          border: 0;
          font-size: 1.3rem;
          font-weight: 500;

          @include sm {
            margin-top: 40px;
          }

          @include xs {
            margin-top: 15px;
          }
        }
      }

      .form-item-checkbox {
        display: flex;
        align-items: flex-start;
        margin-top: 14px;

        input {
          flex-shrink: 0;
          width: 16px;
          margin-right: 10px;
        }

        p {
          margin-bottom: 0;
          font-size: 1.1rem;
          line-height: 1.6;
        }

        a {
          color: $colorWhite;
          text-decoration: underline;

          &:focus,
          &:hover {
            text-decoration: none;
          }
        }
      }

      input[type='submit'] {
        max-height: 39px;
        padding: 11px 8.88px;
        margin-left: 9px;
        font-size: 1.4rem;
        font-weight: 500;
        box-shadow: 0 4px 6px 0 $colorBlack25;
        margin-top: 15px;

        @include xs {
          order: 1;
        }

        &:not([disabled]) {
          background-color: $colorBetterave;
        }

        +.form-item-checkbox {
          margin-bottom: -15px;
        }
      }
    }

    .social-links {
      display: flex;
      align-content: flex-start;
      justify-content: flex-end;
      margin-left: auto;
      flex-wrap: wrap;
      padding: 0 44px 0 0;
      color: $colorWhite;
      font-family: $fontRubik;
      font-size: 1.4rem;
      line-height: 20px;

      @include sm {
        position: relative;
        justify-content: center;
        padding: 18px 0 0;
      }

      &::after {
        @include sm {
          @include overstrike($colorSilver, 120px, 1px, 10px auto 15px);
          content: '';
          position: absolute;
          top: 0;
        }

        @include xs {
          opacity: 0.5;
        }
      }

      &::before {
        right: -44px;
        height: 608px;
        width: 675px;
        background-color: $colorWaikawaGray;
      }

      .surfooter-subtitle {
        width: 100%;
        padding-top: 17px;
        margin-bottom: 17px;
        color : $colorWhite;
        z-index: 2;

        @include min($sm-width) {
          padding-left: calc(100% - 329px);
        }

        @include sm {
          padding-top: 15px;
          text-align: center;
        }
      }

      .item-list {
        position: relative;
      }

      ul {
        li:not(:last-child) {
          margin-right: 31px;

          @include sm {
            margin-right: 21px;
          }
        }
      }
    }
  }

  #block-bordas-block-bordas-refonte-surfooter-bottom {
    background-color: $colorWildSand;

    .content {
      padding-top: 48px;
      padding-bottom: 62px;
      flex-direction: column;

      @include sm {
        padding-top: 40px;
        padding-bottom: 33px;
      }
    }

    .surfooter-title {
      color: $colorBlueBayoux;
      display: block;

      span {
        color: $colorBetterave;
      }
    }

    .main-menu-vous-avez-des-question {
      width: 100%;
      padding-top: 55px;
    }

    .nav {
      justify-content: space-between;

      @include xs {
        flex-wrap: wrap;
        display: flex;
      }

      li {
        display: block;

        @include xs {
          width: 100%;
          max-width: 50%;
          margin-bottom: 30px;
        }

        &[class*="contactez-la-relation-client"] a::before {
          @include backgroundImage('contact_mail-01.svg');
        }

        &[class*="contactez-votre-delegue"] a::before {
          @include backgroundImage('contact_delegue.svg');
        }

        &[class*="la-faq"] a::before {
          @include backgroundImage('contact_faq.svg');
        }

        &[class*="vous-souhaitez-devenir"] a::before {
          @include backgroundImage('contact_auteur.svg');
        }
      }

      a {
        display: block;
        color: $colorBlack;
        font-family: $fontRubik;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        text-decoration: none;
        @include transition($effect: ease);

        &:focus,
        &:hover {
          color: $colorBlueBayoux;
          text-decoration: underline;

          &::before {
            transform: translate(0, 25px) rotate(-25deg);
          }
        }

        @include sm {
          font-size: 0.6875em;
          line-height: 16px;
        }

        &::before {
          @include backgroundImage('', contain, center);
          @include transition;
          content: "";
          display: block;
          width: 100%;
          height: 36px;
          margin-bottom: 19px;
          transform-origin: bottom left;
        }
      }
    }
  }

  .social-links {
    a {
      @include backgroundImage('', 100% 100%, center);
      display: block;
      height: 59px;
      width: 59px;
      border-radius: 50%;
      @include transition(box-shadow);

      &:focus,
      &:hover {
        background-size: 110% 110%;
        @extend %boxShadowDefault;
      }

      span {
        @extend %hiddenText;
      }
    }

    .facebook {
      @include backgroundImage('facebook_logo.svg');
    }

    .instagram {
      @include backgroundImage('instagram_logo.svg');
    }

    .twitter {
      @include backgroundImage('xtwitter_logo.png');
    }

    .linkedin {
      @include backgroundImage('linkedin_logo.svg');
    }
  }

  ul {
    @extend %noStylelist;
    display: flex;
  }
}

.region-footer {
  .block-bordas-block {
    .content {
      @extend %container;
    }
  }

  #block-bordas-block-bordas-refonte-copyright {
    padding: 24px 20px;
    color: $colorGray1;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;

    @include xs {
      font-size: 0.813em;
    }
  }
}

#block-bordas-block-bordas-refonte-surfooter-inter,
#surfooter-inter-cible-marche {
  .content {
    @extend %container;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 34px;
    padding-bottom: 33px;
    max-width: 920px;

    @include xs {
      flex-wrap: wrap;
    }
  }

  .surfooter-inter-picture {
    flex-shrink: 1;
    margin-right: 27px;
    &.image-only {
      margin: 0 auto;
    }

    @include xs {
      width: 100%;
      margin-right: 0;
      text-align: center;
    }
  }

  .surfooter-inter-separator {
    flex-shrink: 0;
    height: 100%;
    min-height: 153px;
    width: 4px;
    background-color: $colorBetterave;

    @include sm {
      min-height: 171px;
    }

    @include xs {
      min-height: 4px;
      width: 100%;
    }
  }

  .surfooter-inter-text {
    flex-shrink: 2;
    padding: 25px 30px;

    @include xs {
      padding: 15px 0;
      text-align: center;
    }

    p {
      margin-bottom: 20px;
      font-size: 1.4rem;
      line-height: 1.6;

      @include sm {
        font-size: 0.813em;
      }

      &:last-child {
        margin-bottom: 0;

        a {
          margin-right: 10px;

          @include xs {
            margin-right: 0;
          }

          &:visited {
            color: $colorBetterave;
          }
        }
      }

      a {
        @include xs {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

#back-top {
  position: fixed;
  right: 15px;
  bottom: 30px;
  margin-left: 4px;

  a {
    @extend %hiddenText;
    @extend %boxShadowDefault;
    @include transition;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $colorBetterave;
    text-align: center;
    opacity: 0.8;

    @include sm {
      width: 39px;
      height: 39px;
    }

    &::after {
      @include backgroundImage('chevron_top_white.svg', 100% auto, center center);
      content: '';
      display: block;
      width: 20px;
      height: 20px;

      @include sm {
        width: 16px;
        height: 16px;
      }
    }

    &:hover,
    &:focus {
        opacity: 1;
        box-shadow: none;
    }
  }
}

#aca-modal {
  position: relative;
  z-index: 2;
}