.section-cibles-wrapper {
  @extend %container;
  @include fullWidthBackground($colorWildSand);

  > .title {
    @extend %sectionTitle;
    color: $colorBlueBayoux;
    font-size: 3rem;
    line-height: 1.05;
  }

  a {
    text-decoration: none;
  }

  ul {
    @extend %noStylelist;
    display: flex;
    justify-content: space-between;
    margin-top: 33px;

    @include max(580px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    li {
      @include max(580px) {
        display: inline-block;
        margin-right: 9px;
        min-height: 100% !important;
        align-self: stretch;
      }

      &:nth-child(2n) {
        @include max(580px) {
          margin-right: 0;
        }
      }
    }

    li {
      width: 100%;
    }
  }

  .front & {
    padding-top: 33px;
    padding-bottom: 55px;

    > .title {
      span {
        color: $colorBetterave;
      }
    }

    .taxonomy-term {
      @include transition;
      height: 100%;
      padding-top: 0;

      &:focus,
      &:hover {
        box-shadow: 0 2px 8px 0 $colorBlack25;
        opacity: 0.8;

        .title {
          opacity: 0;
        }

        .hover-wrapper {
          bottom: 9px;
          opacity: 1;
        }
      }

      .content {
        position: relative;
        height: 100%;
        color: $colorWhite;
        font-weight: 500;
        text-align: center;

        > div {
          padding: 9px 11px 0;
        }

        img {
          @include sm {
            max-width: 100%;
          }
        }
      }

      .title {
        @include transition;
        margin-bottom: 10px;
        opacity: 1;
      }

      .hover-wrapper {
        @include transition;
        position: absolute;
        bottom: -100px;
        font-size: 1.5rem;
        line-height: 1.5;
        opacity: 0;

        @include sm {
          font-size: 1rem;
          line-height: 1.3;
        }
      }

      .title {
        margin-top: 23px;
        font-size: 1.9rem;
        font-weight: 500;
        line-height: 1.25;

        @include sm {
          margin-top: 15px;
          font-size: 1.3rem;
          line-height: 1.2;
        }
      }
    }

    ul {
      li {
        height: auto;
        max-width: calc(25% - 15px);

        @include sm-up {
          min-height: 270px;
        }

        @include sm {
          width: calc(25% - 12px);
          height: auto;
        }

        @include max(580px) {
          width: calc(50% - 9px);
          max-width: none;
          margin-bottom: 17px;
          min-height: 180px;
          height: auto;
        }

        &.cible-marche-term-1 {
          background-color: $colorOrange;
        }

        &.cible-marche-term-2 {
          background-color: $colorBleuClaire;
        }

        &.cible-marche-term-3 {
          background-color: $colorMauve;
        }

        &.cible-marche-term-4 {
          background-color: $colorVerte;
        }
      }
    }
  }

  .not-front & {
    padding-top: 33px;
    padding-bottom: 40px;
    margin-bottom: 94px;

    @include sm {
      padding-top: 35px;
      padding-bottom: 37px;
      margin-bottom: 5px;
    }

    @include max(580px) {
      padding-bottom: 50px;
      margin-bottom: 17px;
    }

    .item-list {
      margin-right: -15px;
      margin-left: -15px;

      @include sm {
        margin-left: -12px;
        margin-right: -12px;
      }

      @include max(580px) {
        margin-right: -9px;
        margin-left: -9px;
      }
    }

    ul {
      margin-bottom: 37px;

      @include sm {
        margin-top: 45px;
        margin-bottom: 30px;
      }

      @include max(580px) {
        justify-content: center;
        margin-top: 26px;
        margin-bottom: 39px;
      }

      li {
        padding: 0 15px;
        width: 100%;
        width: calc(25% - 15px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    
        @include sm {
          padding: 0 12px;
          width: calc(25% - 12px);
        }
    
        @include max(580px) {
          width: calc(50% - 9px);
          max-width: none;
          padding: 0 9px;
        }

        > a {
          display: block;

          @include xs {
            margin: 0 9px 30px;
          }

          &:focus,
          &:hover {
            .title {
              opacity: 0.8;
            }

            img {
              transform: translateY(-40px);

              @include sm {
                transform: translateY(-70px);
              }
            }

            .hover-wrapper {
              bottom: 49px;
              max-height: 200px;
              padding: 15px 13px;
              opacity: 1;
              visibility: visible;

              @include xs {
                bottom: 46px;
              }
            }
          }
        }

        .content {
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          text-align: center;
          color: $colorWhite;
        }

        img {
          @include transition;
          transition-delay: 0.1s;
        }
      }
    }

    .main-wrapper {
      display: flex;
      flex-direction: column-reverse;

      .title {
        padding: 14px;
        margin: 0;
        color: $colorWhite;
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: letterSpacing(05);
        line-height: 1.2;
        text-align: center;
        box-shadow: 0 2px 8px 0 $colorBlack25;

        @include xs {
          font-size: 1.5rem;
        }
      }

      .field-type-image {
        max-width: calc(100% - 22px);
        margin: 0 auto;

        @include sm {
          max-width: calc(100% - 21px);
        }

        @include max(580px) {
          max-width: calc(100% - 40px);
        }

        img {
          @include max(580px) {
            height: auto;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .hover-wrapper {
      @include transition;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: 0;
      padding: 0 13px;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.6;
      opacity: 0;
      visibility: hidden;

      @include xs {
        font-size: 1rem;
      }
    }
  }

  // Page colors
  .not-front.color-term-1 & {
    > .title {
      span {
        color: $colorOrange;
      }
    }

    .taxonomy-term {
      .title,
      .hover-wrapper {
        background-color: $colorOrange;
      }
    }
  }

  .not-front.color-term-2 & {
    > .title {
      span {
        color: $colorBleuClaire;
      }
    }

    .taxonomy-term {
      .title,
      .hover-wrapper {
        background-color: $colorBleuClaire;
      }
    }
  }

  .not-front.color-term-3 & {
    > .title {
      span {
        color: $colorMauve;
      }
    }

    .taxonomy-term {
      .title,
      .hover-wrapper {
        background-color: $colorMauve;
      }
    }
  }

  .not-front.color-term-4 & {
    > .title {
      span {
        color: $colorVerte;
      }
    }

    .taxonomy-term {
      .title,
      .hover-wrapper {
        background-color: $colorVerte;
      }
    }
  }
}
