.node-landing {
  > .content {
    @extend %container;
    max-width: 970px;
    padding-top: 38px;
    margin: 0 auto 70px;
  }

  .field-name-title-field {
    h1 {
      margin: 0 0 6px;
      color: $colorBlueBayoux1;
      font-family: $fontK2D;
      font-size: 3rem;
      font-weight: 800;
      line-height: 1.1;
    }
  }

  .field-name-field-landing-sous-titre {
    margin-bottom: 38px;

    h2 {
      margin: 0 0 10px;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.55;
      letter-spacing: letterSpacing(35);

      &::after {
        @include overstrike($colorBetterave, 105px, 3px, 10px 0 0);
      }
    }
  }

  .paragraphs-items {
    .field-items {
      display: flex;
      flex-wrap: wrap;

      > .field-item {
        width: 100%;

        &.demi {
          @include min($sm-width) {
            max-width: calc(50% - 15px);
            margin-right: 15px;
          }

          + .demi {
            @include min($sm-width) {
              margin-right: 0;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}
