.entity-mea {
  &.mea-slide {
    margin-bottom: 22px;
    height: 300px;

    @include sm {
      height: 285px;
    }

    @include xs {
      height: auto;
    }

    .content,
    .slide-wrapper {
      @include sm {
        height: 100%;
      }
    }

    .slide-wrapper {
      position: relative;
    }

    .field-name-field-visuel {
      @include sm {
        height: 100%;
      }

      div,
      img {
        @include sm {
          height: 100%;
        }
      }

      img {
        height: 300px;
        object-fit: cover;

        @include sm {
          height: 285px;
        }

        @include xs {
          height: 145px;
        }
      }
    }

    .slide-infos-wrapper {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between !important;
      width: 100% !important;
      max-width: 1170px !important;
      margin-left: auto !important;
      margin-right: auto !important;

      @include xs {
        position: static !important;
      }
    }

    .field-name-field-accroche {
      max-width: 615px !important;
      width: 100% !important;
      padding: 31px 25px 38px !important;
      margin: 37px auto 0 !important;
      background-color: $colorWhite !important;
      color: $colorBlack !important;
      font-family: $fontK2D !important;
      font-size: 2.1rem !important;
      line-height: 1.25 !important;
      text-align: center !important;

      @include sm {
        width: auto !important;
        max-width: 474px !important;
        padding: 18px 22px 19px !important;
        margin-top: 23px !important;
        font-size: 1.7rem !important;
      }

      @include xs {
	      font-size: 1.5rem !important;
      }
    }

    .field-name-field-label {
      margin-bottom: 37px;
      text-align: center;

      @include sm {
        margin-bottom: 23px;
        font-size: 1.4rem;
      }

      @include xs {
        margin-bottom: 0;
      }

      .button {
        @extend %buttonDefault;
        font-size: 1.8rem;
        text-transform: none;
      }
    }
  }

  &.mea-menu-autopromo {
    .autopromo-wrapper {
      position: relative;
    }

    .field-name-field-label {
      position: absolute;
      right: 0;
      bottom: 25px;
      left: 0;
      font-weight: 700;
      text-align: center;
    }

    .button {
      @extend %buttonDefault;
    }

    .color-term-1 & {
      .button {
        @extend %buttonOrange;
      }
    }

    .color-term-2 & {
      .button {
        @extend %buttonBlue;
      }
    }

    .color-term-3 & {
      .button {
        @extend %buttonMauve;
      }
    }

    .color-term-4 & {
      .button {
        @extend %buttonVerte;
      }
    }
  }
}
