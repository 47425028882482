body {
  overflow-x: hidden;

  @include xs-header {
    background-color: red;
  }
}

.header-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background-color: $colorWhite;

  &::before {
    @include transition;
    content: '';
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $colorAlto1_80;
    opacity: 0;
    z-index: 100;
    pointer-events: none;

    @include sm {
      display: none;
    }
  }

  @include sm {
    max-height: 80px;
  }

  .logo {
    padding: 0;
    border: 0;
    margin: 0;
  }

  > .section {
    @extend %container;
    width: 100%;

    @include sm {
      max-height: 80px;
      padding: 0 35px;
    }

    @include xs-header {
      padding: 0 11px;
    }
  }

  .region-header {
    display: flex;
    align-items: center;
  }

  .block-bordas-search {
    display: flex;
    align-items: center;
    flex: 0 1 100%;
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;

    @include sm {
      margin-right: 10px;
      margin-top: 35px;
    }

    @include xs-header {
      margin-top: auto;
      margin-bottom: 12px;
      margin-right: 10px;
    }

    .mobile-search-form-title {
      display: none;
    }

    .content,
    .form-item {
      width: 100%;
    }

    .content {
      margin-right: 15px;
      margin-left: 15px;

      @include sm {
        @include backgroundImage('search.svg', 100% 100%, center);
        width: 19px;
        height: 19px;
        margin-left: auto;
        cursor: pointer;
      }

      @include xs-header {
        margin-top: -15px;
        margin-right: 5px;
      }

      &.show {
        @include sm {
          @include backgroundImage('icon-fermer.svg', 100% 100%, center);
        }

        .container-inline {
          @include sm {
            bottom: -63px;
            max-height: 200px;
            padding: 10px 35px;
            visibility: visible;
            opacity: 1;
            z-index: 1;
          }

          @include xs-header {
            padding: 10px 11px;
          }
        }
      }

      .color-term-1 & {
        @include sm {
          @include backgroundImage('search-orange.svg');
        }
      }

      .color-term-2 & {
        @include sm {
          @include backgroundImage('search-bleu.svg');
        }
      }

      .color-term-3 & {
        @include sm {
          @include backgroundImage('search-mauve.svg');
        }
      }

      .color-term-4 & {
        @include sm {
          @include backgroundImage('search-verte.svg');
        }
      }
    }

    .container-inline {
      @include transition;
      display: flex;

      @include sm {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 35px;
        background-color: $colorWhite;
        max-height: 0;
        opacity: 0;
        visibility: hidden;
      }

      @include xs-header {
        padding: 0 11px;
      }
    }

    .form-actions {
      @include backgroundImage('search.svg', 19px 19px, center);
      border: 1px solid $colorSilver;
      border-left: 0;

      .color-term-1 & {
        @include backgroundImage('search-orange.svg');
      }

      .color-term-2 & {
        @include backgroundImage('search-bleu.svg');
      }

      .color-term-3 & {
        @include backgroundImage('search-mauve.svg');
      }

      .color-term-4 & {
        @include backgroundImage('search-verte.svg');
      }
    }

    input[type="text"] {
      border-right: 0;
      color: $colorGrayDark;
      font-size: 1.4rem;
      line-height: 1.25;
    }

    input[type="submit"] {
      @extend %hiddenText;
      background-color: transparent;
      width: 100%;
      height: 100%;
    }

    input {
      &:focus {
        outline: none;
      }
    }
  }

  .block-bordas-block {
    position: relative;
    pointer-events: initial;

    &.open {
      z-index: 102;
    }

    .content > .wrapper {
      opacity: 0;
      transform: scale(0);
      pointer-events: none;
    }

    .content.open > .wrapper {
      opacity: 1;
      transform: scale(1);
      pointer-events: unset;
    }

    .button-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: center;
      height: 116px;
      width: 100px;
      color: $colorWhite;
      cursor: pointer;
      @include transition;

      @include sm {
        height: 80px;
        width: 80px;
      }

      @include xs-header {
        width: 50px;
      }

      &:focus,
      &:hover {
        span {
          color: $colorBlueBayoux;
          text-decoration: underline;

          &::before {
            transform: scale(1.1);
          }
        }
      }

      &.menu-open {
        position: relative;
        z-index: 2;
      }

      span {
        @include transition($duration: 0.5s);
        position: relative;
        display: inline-flex;
        justify-content: center;

        &:not(.count) {
          padding-bottom: 8px;
          font-family: $fontRubik;
          font-size: 1.1rem;
          line-height: 13px;
          text-align: center;

          @include sm {
            height: 0;
            width: 100%;
            padding-bottom: 0;
            font-size: 0;
          }

          &::before {
            @include backgroundImage('', 100% 100%, bottom center);
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            display: block;
            margin: auto;

            @include sm {
              top: -10px;
              bottom: 20px;
            }
          }
        }

        &.count {
          top: -9px;
          width: 100%;
          font-size: 1.875em;
          font-weight: 700;
          text-decoration: none;

          @include xs-header {
            font-size: 2.4rem;
          }
        }
      }
    }

    .wrapper {
      @include transition;
      position: absolute;
      padding: 10px;
      background-color: $colorWhite;
      z-index: 100;
      transform-origin: top center;
      will-change: transform;

      > div {
        padding: 22px 22px 16px;
        background-color: $colorWhite;
        border: 1px solid $colorSilver;

        form > div {
          text-align: center;
        }

        &.intro-wrapper,
        &.intro {
          border: 0;

          .titre {
            margin-bottom: 10px;
            color: $colorBlack;
            font-weight: 400;
            text-align: left;
          }
        }

        .intro {
          padding: 0;
          color: $colorEmperor;
          font-size: 1.3rem;
        }

        .titre {
          margin-bottom: 20px;
          color: $colorGray1;
          font-weight: 600;
          letter-spacing: letterSpacing(50);
          text-align: center;
        }

        .item-list,
        .sub-titre {
          color: $colorShark;
          font-size: 1.4rem;
        }

        .sub-titre {
          margin: 10px 0;
          font-weight: 600;
        }
      }
    }

    .top {
      margin-bottom: 5px;

      .form-item {
        margin-bottom: 21px;

        label {
          margin-bottom: 10px;
          color: $colorShark;
          font-size: 1.3rem;
          font-weight: 400;
          line-height: 1.15;
          text-align: left;
          text-transform: none;
        }
      }

      .form-type-checkbox {
        font-size: 1.6rem;
        line-height: 1.2;
        text-align: left;
      }

      .forgot-password {
        margin-top: 15px;
      }

      input:not(input[type="submit"]) {
        padding: 13px 17px 11px;
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.15;

        @include placeholder {
          color: $colorShark;
        }
      }

      input[type="submit"] {
        text-transform: none;
      }
    }

    .bottom {
      display: flex;
      flex-wrap: wrap;

      .item-list {
        li {
          margin: 0 0 10px;

          &::before {
            content: '';
            background-color: $colorCeriseRed;
            display: inline-block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
    }

    .forgot-password {
      a {
        margin-top: 15px;
        color: $colorShark;
        font-size: 1.2rem;
        text-decoration: underline;

        &:focus,
        &:hover {
          color: $colorBlueBayoux;
        }
      }
    }

    .form-item-remember-me {
      label {
        color: $colorShark;
        font-size: 1.3rem;
      }
    }

    .creer-compte {
      @extend %buttonDefault;
      margin: 0 auto;
      text-transform: none;

      &::after {
        background-color: $colorCeriseRed;
      }
    }

    input[type="submit"] {
      @extend %buttonInput;
      background-color: $colorCeriseRed;
      text-transform: none;
    }

    ul {
      @extend %noStylelist;
    }

    .logged-in & {
      .wrapper {
        > div {
          border: 0;
        }

        .top {
          padding-bottom: 0;
        }

        .bottom {
          ul {
            list-style: none;
          }

          li::before {
            display: none;
          }

          li > a:not(.button) {
            display: flex;
            align-items: center;
            padding: 12px 0;
            color: $colorBlack;

            &::before {
              @include backgroundImage('', 23px 23px, center);
              content: '';
              display: block;
              flex-shrink: 0;
              width: 23px;
              height: 23px;
              margin-right: 10px;
            }

            &.mes_informations_personnelles {
              &::before {
                @include backgroundImage('compte_red.svg');
              }
            }

            &.mes_ressources_numeriques,
            &.mes_manuels_numeriques {
              &::before {
                @include backgroundImage('laptop_red.svg');
              }
            }

            &.mes_commandes {
              &::before {
                @include backgroundImage('commandes_red.svg');
              }
            }

            &.deconnexion {
              &::before {
                @include backgroundImage('deconnexion.svg');
              }
            }
          }
        }
      }
    }
  }

  #block-bordas-block-bordas-refonte-logo {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 170px;

    @include sm {
      flex-shrink: 0;
      max-width: 100px;
      margin-top: 15px;
    }

    @include xs-header {
      max-width: 80px;
      transform-origin: left center;
      transform: scale(0.8);
    }

    @include max(360px) {
      transform: scale(0.6);
    }

    .content {
      width: 100%;

      @include xs-header {
        margin-left: 5px;
      }

      a {
        @include backgroundImage('logos/bordas_logo_red.png');
        display: block;
        width: 170px;
        height: 61px;
        animation: none;

        @include max(580px) {
          @include backgroundImage('logos/bordas_logo_red_mobile.png');
          width: 117px;
          height: 42px;
        }

        &.animate {
          animation: moveY 2s steps(60) forwards;

          @include max(580px) {
            animation: moveYm 2s steps(60) forwards;
          }
        }

        @keyframes moveY {
          from {
            background-position-y: 0;
          }

          to {
            background-position-y: -4260px;
          }
        }

        @keyframes moveYm {
          from {
            background-position-y: 0;
          }

          to {
            background-position-y: -3120px;
          }
        }

        .color-term-1 & {
          @include backgroundImage('logos/bordas_logo_orange.png');

          @include max(580px) {
            @include backgroundImage('logos/bordas_logo_orange_mobile.png');
          }
        }

        .color-term-2 & {
          @include backgroundImage('logos/bordas_logo_blue.png');

          @include max(580px) {
            @include backgroundImage('logos/bordas_logo_blue_mobile.png');
          }
        }

        .color-term-3 & {
          @include backgroundImage('logos/bordas_logo_violet.png');

          @include max(580px) {
            @include backgroundImage('logos/bordas_logo_violet_mobile.png');
          }
        }

        .color-term-4 & {
          @include backgroundImage('logos/bordas_logo_green.png');

          @include max(580px) {
            @include backgroundImage('logos/bordas_logo_green_mobile.png');
          }
        }
      }

      img {
        display: none;
      }
    }
  }

  #block-bordas-block-bordas-refonte-mon-compte {
    .content:not(.open) {
      .button-wrapper {
        &:focus,
        &:hover {
          background-color: $colorWhite;

          span {
            color: $colorBlueBayoux;

            &::before {
              @include backgroundImage('compte-darkblue.svg');
            }
          }
        }
      }
    }

    .button-wrapper {
      background-color: $colorWildsand1;

      span {
        color: $colorBlack;

        .logged-in & {
          &::after {
            content: '';
            position: absolute;
            right: -13px;
            display: block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $colorBetterave;

            @include min($sm-width) {
              top: -40px;
              right: 18px;
            }

            @include sm {
              top: -36px;
              right: 26px;
            }

            @include xs-header {
              right: 14px;
            }
          }
        }

        .sticky-header & {
          &::after {
            @include min($sm-width) {
              top: -30px;
              right: 38px;
            }
          }
        }

        &::before {
          @include backgroundImage('compte.svg');
          width: 18px;
          height: 20px;

          @include min($sm-width) {
            top: -36px;
          }

          @include sm {
            top: -25px;
          }
        }
      }
    }

    .open .button-wrapper span::after {
      display: none;
    }

    .wrapper {
      right: -100px;
      left: -160px;

      @include sm {
        // Without Favorites block
        right: -80px;
        left: -160px;

        // With Favourites block
        // right: -160px;
        // left: -40px;
      }

      @include max(495px) {
        // Without Favorites block
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 20px);
        position: fixed;
        height: 100%;
        max-height: calc(100vh - 70px);
        overflow-y: auto;

        // With Favourites block
        // right: -100px;
        // left: -85px;
      }
    }

    .logged-in & {
      .wrapper {
        > div {
          .titre {
            margin: 0;
            color: $colorBlueBayoux;
            text-align: left;
          }
        }
      }
    }
  }

  #block-bordas-block-bordas-refonte-mes-favoris {
    .content:not(.open) {
      .button-wrapper {
        &:focus,
        &:hover {
          background-color: $colorWhite;

          span {
            color: $colorBlueBayoux;

            &::before {
              @include backgroundImage('favoris-darkblue.svg');
            }
          }
        }
      }
    }

    .wrapper {
      right: -100px;
      left: -160px;

      @include sm {
        right: -80px;
        left: -120px;
      }

      @include max(495px) {
        right: -50px;
        left: -135px;
      }
    }

    .button-wrapper {
      background-color: $colorBlueBayoux;

      span {
        &::before {
          @include backgroundImage('favoris_white.svg');
          width: 20px;
          height: 18px;

          @include min($sm-width) {
            top: -34px;
          }

          @include sm {
            top: -25px;
          }
        }
      }
    }

    .logged-in & {
      .wrapper {
        .top {
          .titre {
            margin-bottom: 10px;
            color: $colorBlack;
            font-weight: 400;
            text-align: left;
          }
        }

        .mes-favoris {
          width: 100%;
        }

        .button {
          @extend %buttonInput;
          margin: 10px auto 0;
          text-transform: none;
        }
      }
    }

    .flag-message {
      padding: 80px 17px 15px;
      max-width: unset;
      position: absolute;
      top: -180px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  #block-bordas-block-bordas-refonte-mon-panier {
    .content:not(.open) {
      .button-wrapper {
        &:focus,
        &:hover {
          background-color: $colorWhite;

          span {
            color: $colorBlueBayoux;

            &::before {
              @include backgroundImage('cart-darkblue.svg');
            }
          }
        }
      }
    }

    .wrapper {
      right: 0;
      left: -260px;

      @include sm {
        left: -200px;
      }

      @include max(495px) {
        left: -185px;
      }
    }

    .button-wrapper {
      background-color: $colorBetterave;

      span {
        &::before {
          @include backgroundImage('cart_white.svg');
          width: 20px;
          height: 20px;

          @include min($sm-width) {
            top: -36px;
          }

          @include sm {
            top: -25px;
          }
        }
      }

      .count {
        @include transition;

        &.add-to-cart {
          animation-delay: 5s;
          position: absolute;
          top: 50%;
          align-items: center;
          height: 100%;
          max-height: 116px;
          background-color: $colorBetterave;
          opacity: 0.8;
          transform: translateY(-50%) scale(1.2);
          z-index: 103;

          @include sm {
            max-height: 80px;
          }

          .sticky-header & {
            max-height: 76px;
            color: $colorWhite;
            font-size: 1.875em;
          }
        }
      }
    }

    .wrapper {
      .top {
        border: 0;
        text-align: center;
        display: block;

        .intro {
          text-align: left;
        }
      }

      .bottom {
        border: 0;
        display: block;

        a {
          color: $colorBlack;
        }
      }

      .flag-wrapper {
        > a {
          text-align: center;
        }
      }

      .titre,
      .sub-titre {
        margin-bottom: 10px;
        color: $colorBlack;
        font-weight: 400;
        text-align: left;
      }

      .sub-titre {
        font-size: 1.6rem;
        letter-spacing: letterSpacing(50);
      }

      .button {
        @extend %buttonInput;
        margin: 10px auto 0;
        text-transform: none;
      }
    }
  }

  #block-bordas-block-bordas-refonte-mes-favoris {

    .flag-ouvrages-favoris {
      text-align: center;
    }

    .count {
      &.favoris-effect {
        animation-delay: 5s;
        position: absolute;
        top: 50%;
        align-items: center;
        height: 100%;
        max-height: 116px;
        background-color: $colorBlueBayoux;
        opacity: 0.8;
        transform: translateY(-50%) scale(1.2);
        z-index: 103;

        @include sm {
          max-height: 80px;
        }

        .sticky-header & {
          max-height: 76px;
          color: $colorWhite;
          font-size: 1.875em;
        }
      }
    }

    .nouveaute-wrapper {
      font-weight: 700;
      color: $colorWhite;
      text-align: center;
      margin-bottom: 8px;
      background-color: $colorBleuClaire;
      padding: 2px 4px;
    }
  }

  .open {
    .button-wrapper {
      background-color: $colorWhite !important;

      span {
        color: $colorBlack !important;

        &::before {
          background-image: url("../images/icon-fermer.svg") !important;
        }

        &.count {
          display: none;
        }
      }
    }
  }

  &.sticky {
    .region-header {
      height: 76px;

      .block-bordas-block {
        width: auto;

        &#block-bordas-block-bordas-refonte-logo img {
          height: 56px;
        }

        .button-wrapper {
          height: 76px;

          span {
            width: 100%;
            font-size: 0;
            color: transparent;
          }
        }

        &#block-bordas-block-bordas-refonte-mon-compte,
        &#block-bordas-block-bordas-refonte-mon-panier,
        &#block-bordas-block-bordas-refonte-mes-favoris {
          .button-wrapper {
            span {
              &::before {
                @include min($sm-width) {
                  top: -26px;
                }
              }
            }
          }
        }
      }

      .block-bordas-search {
        padding: 0;

        .desktop-menu a {
          &::before {
            display: none;
          }
        }

        .hamburger {
          padding-top: 0;

          .menu-open & {
            right: 40px;
          }
        }
      }
    }

    #block-bordas-search-search-form {
      position: relative;
      margin-right: 10px;
      max-width: 43px;

      &::after {
        @include backgroundImage('search.svg', 19px 19px, center);
        content: '';
        position: absolute;
        left: 0;
        display: inline-block;
        width: 43px;
        height: 43px;

        @include sm {
          display: none;
        }
      }

      .color-term-1 & {
        &::after {
          @include backgroundImage('search-orange.svg');
        }
      }

      .color-term-2 & {
        &::after {
          @include backgroundImage('search-bleu.svg');
        }
      }

      .color-term-3 & {
        &::after {
          @include backgroundImage('search-mauve.svg');
        }
      }

      .color-term-4 & {
        &::after {
          @include backgroundImage('search-verte.svg');
        }
      }

      .container-inline {
        opacity: 0;
        transform: translateX(100%);
      }

      &.show {
        &::after {
          display: none;
        }
      }
    }
  }

  @include sm {
    &.sticky {
      transition: height ease 0.3s;
    }

    &.scroll-up {
      height: 13vw;
    }
  }

  &.scroll-up {
    @include xs-header {
      height: 60px;

      .button-wrapper {
        height: 60px;
        transform-origin: top left;
      }
    }
  }
}


