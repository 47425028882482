.popin_newsletter {
	display: none;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 24px;
  background-color: $colorWhite50;
  z-index: 9;

  > div {
		@extend %boxShadowDefault;
		position: relative;
    max-width: 50vw;
		padding: 28px 24px;
    margin: auto;
		background-color: $colorWhite;

		@include md {
			max-width: 75vw;
		}

		@include xs {
			max-width: 80vw;
			padding: 24px 20px;
		}
  }

	.wrapper-close {
		text-align: right;

		a {
			display: inline-flex;
			align-items: flex-end;
			color: $colorBetterave;
			font-size: 1.3rem;
			font-weight: 500;
			text-decoration: none;
			cursor: pointer;

			&:hover {
				&::after {
					transform: rotate(-180deg);
				}
			}

			&::after {
				@include transition;
				@include backgroundImage('icon-fermer.svg', 100% auto, center);
				content: '';
				display: inline-block;
				width: 20px;
				height: 20px;
				margin-left: 5px;
			}
		}
	}

	.wrapper-texte {
		max-height: 440px;
		overflow-y: auto;
		margin-top: 20px;

		p {
			font-size: 1.5rem;
			line-height: 1.6;
		}
	}
}

.ui-widget-overlay {
	background: $colorWhite50;
	opacity: 1;
}

.ui-widget-content {
	@extend %boxShadowDefault;
	padding: 28px 24px;
	border: 0;
	max-width: 80vw;
	background: $colorWhite;
	color: $colorBlack;
}

.ui-dialog-titlebar {
	display: flex;
	align-items: flex-start;
	padding: 0 !important;
	border: 0;
	margin-bottom: 20px;
	background: transparent;
	color: $colorShark;

	.ui-dialog-title {
		@extend %sectionTitle;
		display: inline-block;
		text-align: left;
		font-size: 2.6rem;
		white-space: normal;

		&::after {
			margin: 10px 0;
			background-color: $colorBetterave;
		}
	}

	.ui-dialog-titlebar-close {
		position: static;
		display: inline-flex;
		align-items: flex-end;
		flex-direction: row-reverse;
		flex-shrink: 0;
		height: auto;
		width: 100%;
		max-width: 100px;
		margin: 0;
		border: 0;
		background: transparent;

		text-decoration: none;
		cursor: pointer;

		&:hover,
		&:focus {
			outline: none;

			.ui-icon {
				transform: rotate(-180deg);
			}
		}

		.ui-icon {
			@include transition;
			@include backgroundImage('icon-fermer.svg', 100% auto, center);
			content: '';
			position: static;
			display: inline-block;
			width: 20px;
			height: 20px;
			margin: 0;
			margin-left: 5px;
		}

		.ui-button-text {
			color: $colorBetterave;
			font-family: $fontRubik;
			font-size: 1.3rem;
			font-weight: 500;
		}
	}
}

.ui-button-icon-only .ui-button-text,
.ui-button-icons-only .ui-button-text {
	padding: 0;
	text-indent: 0;
}

.ui-dialog .ui-dialog-content {
	padding: 0;
	box-shadow: none;
}

.ui-corner-all {
	border-radius: 0;
}
