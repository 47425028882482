* {
  box-sizing: border-box;
}

html {
	font-size: 62.5%;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	scroll-behavior: smooth !important;
}

body {
	background-color: $colorWhite;
	color: $colorBlack;
	font-family: $fontRubik;
	font-size: 1.6rem;
	line-height: 1.2;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;

	&::before {
		@include transition;
		content: '';
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $colorAlto1_80;
		opacity: 0;
		transform: translateY(-100%);
		z-index: 100;

		@include sm {
			display: none;
		}
	}

	&.menu-open-header::before {
		opacity: 1;
		transform: translateY(0);
	}

	&.menu-open .header-top::before {
		opacity: 1;
	}

	&.menu-open #header {
		@include sm {
			position: relative;
			z-index: 9;
			background-color: $colorWhite;
		}
	}
}

a {
	color: $colorBetterave;
	@include transition;

	&:focus,
	&:hover {
		text-decoration: none;
	}

	&:visited {
		color: $colorBlueBayoux;
	}
}

.btn {
	@extend %buttonDefault;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
	// text-transform: uppercase;
}

h1 {
	padding-bottom: 10px;
}

h1.title {
	color: $colorBetterave;
	text-align: center;
}

.titre-h2,
h2 {
	color: $colorEmperor;
	font-size: 1.9rem;
	font-weight: 700;
	letter-spacing: letterSpacing(25);
}

h3, h4, h5 {
	display: inline-block;
	padding-bottom: 10px;

	.field-name-field-page-texte &,
	.field-name-field-auteur-biographie & {
		border-bottom: 1px solid $colorSilver;
	}
}

h3 {
	.field-name-field-page-texte &,
	.field-name-field-auteur-biographie & {
		border-color: $colorBetterave;
	}
}

h4 {
	color: $colorBlueBayoux;
}

h5 {
	color: $colorEmperor;
}

ul {
	margin: 0 0 25px;
	padding: 0;

	li {
		list-style: square;
	}
}

li {
	margin: 0;
	padding: 0 0 0 10px;

	.field-name-field-page-texte & {
		margin: 0 0 15px;
		font-size: 1.4rem;
		line-height: 1.65;
		list-style: none;
		letter-spacing: letterSpacing(35);
	}
}

ol {
	margin: 0;
	padding: 0;
	counter-reset: li;

	> li {
		list-style: none;
		position: relative;
		margin: 0;
		padding: 8px 8px 8px 28px;

		&::before {
			content: counter(li)'.';
			counter-increment: li;
			position: absolute;
			top: 6px;
			left: 0;
			min-width: 1em;
			min-height: 1em;
			padding: 4px;
			border-radius: 50%;
			color: $colorWhite;
			background-color: $colorBetterave;
			font-size: 1em;
			font-weight: 300;
			line-height: 1em;
			text-align: center;
		}
	}
}

sup {
	top: -0.3em;
}

sub {
	bottom: 0;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

fieldset {
	padding: 0;
	margin: 0 0 20px;
	border: none;

	.fieldset-wrapper {
		padding: 10px;
		border: 1px solid $colorGray;
	}
}

legend {
	background-color: $colorBlack;
	color: $colorWhite;
	font-weight: 600;
	text-transform: uppercase;
	padding: 10px 20px;
	width: 100%;
}

p {
	margin: 0 0 1em;
}

.cursor {
	position: absolute;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	pointer-events: none;
	background-color: $colorSilver;
	opacity: 0.7;
	z-index: 999;
	transform: scale(0);
	transition: transform ease 1.5s, top linear 0.1s, left linear 0.1s, height ease 0.3s, width ease 0.3s;

	&.visible {
		transform: scale(1);
	}

	&.link-hover {
		width: 35px;
		height: 35px;
	}

	&.color-term-1 {
		background-color: $colorOrange;
	}

	&.color-term-2 {
		background-color: $colorBleuClaire;
	}

	&.color-term-3 {
		background-color: $colorMauve;
	}

	&.color-term-4 {
		background-color: $colorVerte;
	}
}

table {
	border: $colorSilver1;
	text-align: center;
	margin: 0 auto;

	caption {
		padding: 0 0 10px;
		border-bottom: 1px solid $colorSilver;
		margin-bottom: 20px;
		color: $colorEmperor;
		font-size: 0.875em;
		font-weight: 500;
		text-align: left;
	}

	th {
		@include transition;
		border-bottom-width: 1px;
		color: $colorWhite;
		background-color: $colorBetterave;
		text-align: center;
		will-change: background-color;

		&:focus,
		&:hover {
			background-color: $colorMauve;
		}
	}

	td,
	th {
		padding: 17px 10px 5px;
	}

	td {
		color: $colorEmperor;
	}
}

#messages {
  section {
    @extend %container;
	}
}

.messages,
div.messages {
	width: 100%;
	padding: 13px 14px;
	margin: 0 0 21px;
	font-size: 1.3rem;
	font-weight: 500;

	.path-recherche & {
		margin-bottom: 0;
	}

	&.error,
	.error {
		border-color: $colorCrimson;
		background: $colorCrimson_10;
		color: $colorCrimson;

		a {
			color: $colorCrimson;
		}
	}

	&.status,
	.status,
	&.ok,
	.ok,
	&.success,
	.success {
		border-color: $colorJapaneseLaurel;
		background: $colorJapaneseLaurel_10;
		color: $colorJapaneseLaurel;

		a {
			color: $colorJapaneseLaurel;
		}
	}

	&.warning,
	.warning {
		border-color: $colorTrinidad;
		background: $colorTrinidad_10;
		color: $colorTrinidad;

		a {
			color: $colorTrinidad;
		}
	}

	&.info {
		border-color: $colorDenim;
		background: $colorDenim_10;
		color: $colorDenim;

		a {
			color: $colorDenim;
		}
	}

	li {
		@include max(580px) {
			word-break: break-word;
		}

		.placeholder {
			word-break: break-word;
		}
	}
}
