////////////////////////
// Variables Partials
//
// All of your variables should go in this file.
////////////////////////

//////////////////////////////
// Fonts
//////////////////////////////
$fontK2D:      'K2D', Arial, sans-serif;
$fontRubik:    'Rubik', Arial, sans-serif;

//////////////////////////////
// Widths
//////////////////////////////
$md-width:                  1024px;
$sm-width:                  768px;
$sm-up:                     769px;
$xs-width:                  375px;
$xs-header:                 520px;
$container-width:           1169px;

$site-max-width:             850px;

//////////////////////////////
// Named Colors : http://chir.ag/projects/name-that-color
// Sorted by groups and intensities
//////////////////////////////
// Blue

// Red
$colorMilanoRed:		    #D22100;
$colorCeriseRed:        #DA2B59;
$colorSunsetOrange:     #FF584C;

// Blue
$colorJava:		          #21B9D0;
$colorCuriousBlue:      #2C8AC7;
$colorLynch:            #6D829F;
$colorWaikawaGray:      #5B718E;
$colorBlueBayoux:       #435D7B;
$colorBlueBayoux1:      #495D78;

// White
$colorWhite:			      #FFFFFF;
$colorWhite50:          rgba(255, 255, 255, 0.5);
$colorConcrete:         #F3F3F3;
$colorWildSand:         #F4F4F4;
$colorWildsand1:        #F5F5F5;
$colorWildsand1_40:     rgba(245, 245, 245, 0.4);
$colorAlabaster:        #F8F8F8;
$colorMercury:          #E6E6E6;
$colorAlto:             #E0E0E0;
$colorAlto1:            #DEDEDE;
$colorAlto1_80:         rgba(222, 222, 222, 0.8);
$colorSilver1:          #CBCBCB;
$colorSilver:           #C6C6C6;
$colorSilver2:          #C4C4C4;
$colorDustyGray1:       #979797;
$colorDustyGray:        #959595;
$colorGray2_50:         rgba(136, 136, 136, 0.5);
$colorGray:				      #808080;
$colorGray1:            #838383;
$colorBoulder:          #797979;
$colorDoveGray:         #717171;
$colorEmperor:          #524F4F;
$colorTundora:          #4C4C4C;
$colorTundora1:         #444444;
$colorMineShaft:        #3A3A3A;
$colorShark:            #27282B;
$colorBlack:			      #000000;
$colorBlack25:          rgba(0, 0, 0, 0.25);
$colorBlack26:          rgba(0, 0, 0, 0.26);
$colorBlack30:          rgba(0, 0, 0, 0.3);
$colorBlack34:          rgba(0, 0, 0, 0.34);
$colorBlack50:          rgba(0, 0, 0, 0.5);

$colorGrisClair:        #F5F5F5;
$colorBetterave:        #D8325C;
$colorBleuGris:         #435D7B;
$colorGrayDark:         #524F4F;
$colorGrayClair:        #D6DCE5;

// Messages colors
$colorCrimson:          #DE132C;
$colorCrimson_10:       rgba(222, 19, 44, 0.1);
$colorTrinidad:         #E85909;
$colorTrinidad_10:      rgba(232, 89, 9, 0.1);
$colorJapaneseLaurel:   #307F07;
$colorJapaneseLaurel_10:rgba(48, 127, 7, 0.1);
$colorDenim:            #1265C3;
$colorDenim_10:         rgba(18, 101, 195, 0.1);

// Sections colors
$colorOrange:           #EF6A00;
$colorBleuClaire:       #00B7CE;
$colorMauve:            #7F4A90;
$colorVerte:            #A6CE39;
