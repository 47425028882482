#breadcrumb {
  @include fullWidthBackground($colorWildsand1_40);

  .section {
    @extend %container;
  }

  .breadcrumb {
    padding: 25px 0 23px;
  }

  a,
  li span {
    color: $colorTundora;
    font-size: 1.2rem;
    line-height: 1.2;
  }

  ul {
    @extend %noStylelist;
    display: flex;
    align-items: center;

    li {
      + li {
        padding-left: 25px;
        margin-left: 18px;

        > * {
          position: relative;
          display: inline-flex;
          align-items: center;

          &::before {
            @include backgroundImage('arrow.svg', cover, center);
            content: '';
            position: absolute;
            top: 0;
            left: -25px;
            display: inline-block;
            width: 12px;
            height: 12px;

            @include xs {
              top: auto;
            }
          }
        }
      }
    }

    li:not(.first):not(.last) {
      @include xs {
        display: none;
      }
    }
  }

  .node-type-page & {
    @include fullWidthBackground($colorWildsand1);
  }
}
