.page-manuel-videoprojetable {
  .block-system {
    > .content {
      @extend %container;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: minmax(200px, 270px) 1fr;
      grid-template-columns: minmax(200px, 270px) 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: repeat(2, auto);
      align-items: start;
      margin-top: 38px;
      margin-bottom: 45px;

      @include sm {
        -ms-grid-columns: minmax(200px, 223px) 1fr;
        grid-template-columns: minmax(200px, 223px) 1fr;
      }

      @include max(580px) {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 36px;
      }
    }
  }

  h2.title {
    margin: 12px 0 19px;
    color: $colorBleuGris;
    font-family: $fontK2D;
    font-size: 3rem;
    font-weight: 800;
    line-height: 1;
    text-align: left;

    @include max(580px) {
      font-size: 2.2rem;
    }

    &::after {
      @include overstrike($colorBetterave, 90px, 3px, 16px 0 0);

      @include max(580px) {
        display: none;
      }
    }
  }

  .field-name-field-ouvrage-image {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / 3;
    box-shadow: 0 2px 8px 0 $colorBlack34;

    @include xs {
      margin-bottom: 37px;
    }

    @include max(580px) {
      max-width: 200px;
      margin: 0 auto 30px;
    }

    img {
      width: 100%;
    }
  }

  .confirmation-left-wrapper {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;
    padding: 0 32px;

    @include sm {
      padding: 0 13px;
    }

    @include max(580px) {
      order: -1;
      padding: 0;
    }
  }

  .confirmation-right-wrapper {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 2;
    grid-row: 2;
    padding: 0 32px;

    @include sm {
      padding: 0 13px;
    }

    @include max(580px) {
      padding: 0;
    }
  }

  .download-message-wrapper {
    p {
      margin: 0;
      color: $colorBlack;
      letter-spacing: 0.5px;
      line-height: 1.5;
    }

    ul {
      @extend %noStylelist;
      margin: 20px 0 5px;

      li {
        margin-bottom: 5px;
        color: $colorDoveGray;
        font-size: 1.5rem;
        letter-spacing: 0.5px;
        line-height: 1.5;

        @include max(580px) {
          font-size: 1.3rem;
        }
      }
    }

    a {
      display: inline-block;
      margin-top: 15px;
      font-weight: 400;
      line-height: 1.1;
    }
  }

  p,
  a.download-link,
  .download-link-wrapper {
    font-size: 1.5rem;

    @include max(580px) {
      font-size: 1.3rem;
    }
  }

  a {
    color: $colorBetterave;
    font-size: 1.3rem;
  }
}