.page-user-register,
.page-user {
  .region-content {
    > .block {
      @extend %container;
      padding-top: 35px;
      padding-bottom: 45px;
    }

    h1 {
      margin: 0 0 34px;
      color: $colorBetterave;
      font-size: 3.2rem;
      font-weight: 700;
      text-align: center;
    }

    .back {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      font-size: 1.2rem;
      font-weight: 500;

      &::before {
        @include backgroundImage('chevron_back.svg', 100% auto, center);
        content: '';
        display: inline-block;
        width: 8px;
        height: 12px;
        margin-right: 12px;
      }

      a {
        display: inline-block;
        padding: 4px 0 0;
        color: $colorEmperor;
        text-decoration: none;
      }
    }

    p {
      margin-bottom: 20px;
      font-size: 1.3rem;
      line-height: 1.5;
    }
  }

  #div_registration_confirmation {
    width: 100%;
    max-width: 50%;
    padding: 24px 45px 14px;
    margin: 0 auto;
    border: 1px solid $colorSilver;
    border-bottom: 0;

    @include sm {
      max-width: 100%;
    }

    @include max(580px) {
      padding: 24px 20px 10px;
    }

    .fieldset {
      border: 0;
      padding: 0;
      background-color: transparent;
    }

    .login_infos {
      margin-bottom: 20px;
      background-color: $colorWildSand;
      border-color: $colorSilver;
    }

    h2 {
      display: block;
      margin: 0 0 11px;
      border: 0;
      color: $colorGray1;
      font-family: $fontRubik;
      font-size: 1.7rem;
      font-weight: 500;
      text-align: center;
    }

    p {
      margin: 0 0 21px;
      font-size: 1.3rem;
      text-align: left;
    }

    + .submit {
      width: 100%;
      max-width: 50%;
      border: 1px solid $colorSilver;
      border-top: 0;
      margin: 0 auto;
      text-align: center;

      @include sm {
        max-width: 100%;
      }

      > div {
        padding-bottom: 24px;

        @include max(580px) {
          padding-bottom: 18px;
        }
      }

      a {
        color: $colorWhite;
        text-decoration: none;
        text-transform: none;
      }
    }
  }

  #ws_global {
    margin-top: 52px;

    #per-connect-header-form {
      text-align: left;
      margin-bottom: 52px;
    }

    #form_account {
      margin-left: 0;
      max-width: 870px;

      .per-connect-widget-text {
        max-width: 570px;
        margin-left: auto;
        margin-right: 20px;
      }
    }

    #div_profil_choice {
      li {
        @include xs {
          padding-bottom: 15px;
        }
      }
    }

    #group_institution_infos {
      margin-left: 0;
      padding-right: 20px;
    }

    #div_options_list_form {
      display: grid;
      margin-right: 20px;
      width: 100%;
      max-width: 570px;
      margin-left: auto;

      > * {
        display: inline-block;
        margin-bottom: 10px;

        br {
          display: none;
        }
      }

      input {
        grid-column: 1;
        margin-right: 10px;

        &:first-of-type {
          -ms-grid-row: 1;
          grid-row: 1;
        }

        @for $i from 2 through 8 {
          &:nth-of-type(#{$i}) {
            -ms-grid-row: $i;
            grid-row: $i;
          }
        }
      }

      label {
        -ms-grid-column: 2;
        grid-column: 2;
        font-size: 1.3rem;
        text-transform: none;

        &:first-of-type {
          -ms-grid-row: 1;
          grid-row: 1;
        }

        @for $i from 2 through 8 {
          &:nth-of-type(#{$i}) {
            -ms-grid-row: $i;
            grid-row: $i;
          }
        }
      }
    }

    #div_password {
      input + p {
        -ms-grid-row: 1;
        grid-row: 1;

        + p {
          -ms-grid-row: 2;
          grid-row: 2;
        }
      }
    }

    #per-connect-layout-group-footer {
      text-align: left;
    }

    .fieldset {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr minmax(min-content, 570px);
      grid-template-columns: 1fr minmax(auto, 570px);
      border: 0;
      padding: 0;
      background-color: transparent;

      @include sm {
        -ms-grid-columns: 1fr minmax(min-content, 525px);
        grid-template-columns: 1fr minmax(auto, 525px);
      }

      @include xs {
        display: block;
      }

      > *:not(h2) {
        // -ms-grid-row: 1;
        -ms-grid-column: 2;
        grid-column: 2;
        padding: 20px 15px 0;
        border: solid $colorSilver;
        border-width: 0 1px;

        @for $i from 2 through 8 {
          &:nth-child(#{$i}) {
            -ms-grid-row: $i - 1;
            grid-row: $i - 1;
          }
        }

        &:last-child {
          border-bottom: 1px solid $colorSilver !important;
          padding-bottom: 20px !important;
        }
      }

      h2 {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-column: 1;
        border-bottom: 0;
        padding-right: 20px;
        color: $colorShark;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 1.1;

        @include xs {
          padding-right: 0;
          padding-bottom: 16px;
          font-size: 1.9rem;
        }
      }

      p {
        margin: 0;
        border-top: 1px solid $colorSilver;

        &.password_edit_link,
        &.institution_edit_link {
          border-top: 0;

          a {
            color: $colorBetterave;
            font-size: 1.3rem;
            font-weight: normal;
          }
        }
      }

      input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]),
      select {
        width: 100%;
        min-height: 39px;
        min-width: 20px;
        border: 1px solid $colorSilver2;
        border-radius: 0;
        padding: 13px 17px;
        color: $colorShark;
        font-size: 1.3rem;
        font-weight: 500;

        &:focus {
          border-color: $colorEmperor;
          outline: none;
        }

        &.readonly {
          background: $colorWildSand;
          opacity: 0.8;
          font-style: italic;
        }
      }

      select {
        @include backgroundImage('chevron_bottom.svg', 12px 8px, right 16px center);
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 39px;
        padding: 10px 34px 10px 13px;
        line-height: 1;
      }

      input[type="radio"],
      input[type="checkbox"] {
        + label {
          font-weight: 700;
        }
      }

      .adult_birthday_inputs,
      .child_birthday_inputs {
        display: inline-flex;
        align-items: center;
        margin-top: 10px;

        input {
          padding: 0 !important;

          &[disabled] {
            opacity: 1;
          }

          &:last-of-type {
            border-width: 1px 1px 1px 0 !important;
          }
        }
      }

      #div_button_add_child {
        padding: 15px 0;
        margin: 0;
      }

      #children_list {
        .children_box {
          padding: 10px;
          border: 1px solid $colorSilver;
          background-color: $colorWhite;

          li:first-child {
            display: flex;
            align-items: center;

            label {
              width: auto;
            }

            span {
              padding-left: 5px;
              color: $colorShark;
            }
          }
        }

        .delete_child {
          @include backgroundImage('buttons/button_red_delete.svg', 100% auto, center);
          height: 20px;
          width: 20px;
          border: 0;
        }
      }
    }

    .submit {
      padding-right: 0;

      #global_registration_ens_submit,
      #global_registration_par_submit,
      #global_registration_ele_submit {
        margin-right: 35px;
      }
    }

    ul {
      @extend %noStylelist;

      li {
        margin-bottom: 10px;

        @include xs {
          margin-bottom: 0;
          padding-bottom: 11px;
        }
      }

      label {
        width: 100%;
        margin-bottom: 5px;
        color: $colorShark;
        font-size: 1.3rem;
        font-weight: 400;
        text-transform: none;
        text-align: left;
      }

      span.help {
        display: inline-flex;
        align-items: center;
        border-bottom: 0;
        color: $colorShark;
        font-weight: 400;
        text-decoration: underline;

        &::after {
          @include backgroundImage('question_icon.svg', 100% auto, center);
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }
      }

      img.help {
        display: none;
      }
    }
  }

  #div_global_registration_ens {
    display:flex;
    flex-direction:column;
    align-items:flex-end;

    #div_options_list_form {
      input {
        &:nth-of-type(2) {
          grid-row: 1;
        }
      }
    }
  }

  &.logged-in {
    #div_global_registration_ens {
      #div_options_list_form {
        input {
          &:nth-of-type(2) {
            grid-row: 2;
          }
        }
      }
    }
  }

  #per-connect-footer-form {
    padding-bottom: 30px;
    font-size: 12px;

    span {
      font-size: 14px;
    }
  }

  div.calendar,
  div.calendar div,
  div.calendar table,
   {
     width: auto;
   }
}

.tip-wrap {
  box-shadow: 0 2px 8px 0 $colorBlack25 !important;
  font-family: $fontRubik !important;

  .tip {
    border: 0;
    padding: 10px;
    background-color: $colorBlueBayoux;
  }

  .tip-top {
    border: 0;
  }

  .tip-title {
    color: $colorWhite;
    background-color: transparent;
    font-size: 1.2rem;
  }

  .tip-text {
    background-color: transparent;
    color: $colorWhite;
    font-size: 1.2rem;
  }

  ul {
    @extend %styleUl;

    li {
      display: inline-block;
    }
  }
}
