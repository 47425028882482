.taxonomy-term {
  padding-top: 25px;

  .content > .title {
    @extend %container;
    margin-bottom: 32px;
    font-family: $fontRubik;
    font-size: 3.2rem;
    line-height: 1.2;
    text-align: center;
  }

  .taxonomy-term-description {
    @extend %container;
    margin: 0 auto 35px;

    p {
      color: $colorGrayDark;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.55;
      letter-spacing: letterSpacing(35);
      text-align: left;

      a {
        text-decoration: none;

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .slider-wrapper {
    .slide-wrapper {
      position: relative;
      justify-content: center;
      text-align: center;

      > div {
        width: 100%;
      }
    }

    .slide-infos-wrapper {
      position: static;
      top: 40px;
      bottom: 45px;
      max-width: 615px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      z-index: 2;

      .field-name-field-accroche {
        padding: 31px 25px 38px;
        color: $colorBlack;
        background-color: $colorWhite;
        font-family: $fontK2D;
        font-size: 1.7rem;
        line-height: 1.25;
      }

      .button {
        @extend %buttonDefault;
        font-size: 1.8rem;
        text-transform: none;
      }
    }

    li > a {
      display: block;
    }

    ul {
      @extend %noStylelist;

      &.slick-dots {
        margin: 20px 0 18px;
      }
    }

    img {
      height: 300px;
      object-fit: cover;

      @include sm {
        height: 285px;
      }

      @include xs {
        height: 145px;
      }
    }
  }

  // Page colors
  .color-term-1 & {
    h1 {
      color: $colorOrange;
    }

    .taxonomy-term-description p a {
      color: $colorOrange;
    }

    .slide-infos-wrapper {
      .button {
        @extend %buttonOrange;
      }
    }

    .slick-dots {
      .slick-active button {
        background-color: $colorOrange;
      }
    }
  }

  .color-term-2 & {
    h1 {
      color: $colorBleuClaire;
    }

    .taxonomy-term-description p a {
      color: $colorBleuClaire;
    }

    .slide-infos-wrapper {
      .button {
        @extend %buttonBlue;
      }
    }

    .slick-dots {
      .slick-active button {
        background-color: $colorBleuClaire;
      }
    }
  }

  .color-term-3 & {
    h1 {
      color: $colorMauve;
    }

    .taxonomy-term-description p a {
      color: $colorMauve;
    }

    .slide-infos-wrapper {
      .button {
        @extend %buttonMauve;
      }
    }

    .slick-dots {
      .slick-active button {
        background-color: $colorMauve;
      }
    }
  }

  .color-term-4 & {
    h1 {
      color: $colorVerte;
    }

    .taxonomy-term-description p a {
      color: $colorVerte;
    }

    .slide-infos-wrapper {
      .button {
        @extend %buttonVerte;
      }
    }

    .slick-dots {
      .slick-active button {
        background-color: $colorVerte;
      }
    }
  }
}

.vocabulary-cible-marche {
  .content > .title {
    margin-bottom: 20px;
  }
}
