.ouvrages-slider {
  padding-top: 33px;
  padding-bottom: 30px;
  scroll-margin-top: 5rem;

  &:nth-last-child(2n) {
    @include fullWidthBackground($colorWildSand);

    &::after,
    &::before {
      @include sm {
        width: 34px;
      }

      @include xs {
        width: 20px;
        z-index: -1;
      }
    }
  }

  h2.title {
    @extend %sectionTitle;
    margin-bottom: 21px;
    color: $colorBlueBayoux;
    font-size: 3rem;
    line-height: 1.05;

    @include xs {
      font-size: 2.4rem;
    }
  }

  .item-list {
    @include xs {
      max-width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .ouvrages-list {
    @include md {
      max-width: calc(100% - 160px);
      margin: 0 auto;
    }

    @include sm {
      max-width: calc(100% - 40px);
    }

    @include xs {
      max-width: 100%;
    }

    .slick-track {
      display: flex;
    }

    .slick-list {
      @include xs {
        max-width: calc(100% + 260px);
        margin-right: -130px;
        margin-left: -130px;
      }
    }

    .slick-active .node {
      @include xs {
        margin: 0 20px;
      }
    }

    .node-teaser {
      margin-bottom: 0;
    }

    .slick-arrow {
      top: 25%;

      @include sm {
        height: 48px;
        width: 48px;
        background-size: 18px 18px;
      }

      &.slick-next {
        right: -80px;

        @include sm {
          right: -40px;
        }
      }

      &.slick-prev {
        left: -80px;

        @include sm {
          left: -40px;
        }
      }
    }

    .slick-dots {
      @include sm {
        flex-wrap: wrap;
      }

      li {
        @include sm {
          margin-bottom: 3px;
        }

        &:only-child {
          display: none;
        }
      }
    }

    img {
      width: auto;
      margin: 0 auto;
    }
  }

  ul {
    @extend %noStylelist;
  }

  // Page colors
  .color-term-1 & {
    .title span {
      color: $colorOrange;
    }

    .slick-dots {
      .slick-active button {
        background-color: $colorOrange;
      }
    }
  }

  .color-term-2 & {
    .title span {
      color: $colorBleuClaire;
    }

    .slick-dots {
      .slick-active button {
        background-color: $colorBleuClaire;
      }
    }
  }

  .color-term-3 & {
    .title span {
      color: $colorMauve;
    }

    .slick-dots {
      .slick-active button {
        background-color: $colorMauve;
      }
    }
  }

  .color-term-4 & {
    .title span {
      color: $colorVerte;
    }

    .slick-dots {
      .slick-active button {
        background-color: $colorVerte;
      }
    }
  }
}
