#sliding-popup.sliding-popup-bottom {
  font-family: $fontRubik !important;

  .popup-content {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1170px;
    padding: 11px 30px 12px;

    @include sm {
      padding-right: 15px;
      padding-left: 15px;
    }

    &::before {
      @include backgroundImage('cookies_img3@2x.png', 100% auto, center);
      content: '';
      position: absolute;
      top: 50%;
      left: 35px;
      display: inline-block;
      height: 90px;
      width: 90px;
      transform: translateY(-50%);

      @include sm {
        left: 10px;
        height: 70px;
        width: 70px;
      }

      @include xs {
        position: static;
        height: 60px;
        width: 60px;
        transform: none;
      }
    }

    #popup-buttons,
    #popup-text {
      max-width: 100%;
      width: 100%;
      margin: 0;
    }
  }

  #popup-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 90px;
    text-align: center;

    @include sm {
      padding: 0 70px;
    }

    @include xs {
      padding: 0;
    }

    p,
    button {
      color: $colorWhite !important;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6;
    }

    a {
      color: $colorWhite;
    }
  }

  #popup-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    @include xs {
      flex-wrap: wrap;
    }

    button {
      @include transition;
      padding: 8px 28px;
      border: 1px solid $colorDustyGray1;
      border-radius: 0;
      margin: 16px 25px 0;
      background: transparent;
      box-shadow: none;
      color: $colorWhite;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
      text-decoration: none;
      text-shadow: none;

      @include sm {
        margin: 13px 10px 0;
      }

      @include xs {
        margin: 10px 5px 0;
      }

      &:focus,
      &:hover {
        background-color: $colorDustyGray1;
      }
    }
  }
}
