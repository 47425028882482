.page-mes-ressources,
.page-mes-manuels-numeriques {
  .ressources_manum {
    @extend %container;

    h2 {
      margin: 0;
      color: $colorBlueBayoux;
      font-size: 2.1rem;
      font-weight: 500;

      @include xs {
        font-size: 1.7rem;
      }

      &::after {
        @include overstrike($colorSilver, 105px, 3px, 13px 0 30px);
      }
    }

    .block-edu-jdp {
      padding-bottom: 20px;
      border-bottom: 1px solid $colorBetterave;
      margin-bottom: 40px;
      font-size: 1.3rem;
      line-height: 1.5;

      &:last-child {
        border: 0;
      }
      
      .content > ul:not(.guides) {
        @extend %styleUl;
        
        li {
          display: block;
          margin-bottom: 20px;
          margin-left: 25px;
    
          &::before {
            margin-left: -25px;
          }
        }
      }

      .guides {
        @extend %noStylelist;
        width: 100%;
        font-family: $fontRubik !important;

        li {
          margin-bottom: 21px;
        }
      }

      .ressources {
        > ul {
          @extend %noStylelist;
          display: flex;
          flex-wrap: wrap;

          li {
            width: 100%;
            max-width: 50%;

            @include sm {
              max-width: 100%;
            }
          }
        }
      }

      p {
        margin: 0 0 20px;
        font-family: $fontRubik;
      }
    }
  }

  a.pdf-icon {
    display: inline-flex;
    align-items: center;
    font-size: 1.3rem;
    color: $colorShark;
    line-height: 1;

    &::before {
      @include backgroundImage('pdf_icon.svg', 21px 29px, center center);
      content: '';
      display: block;
      flex-shrink: 0;
      width: 21px;
      height: 29px;
      margin-right: 9px;
    }
  }

  .content > .content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .content-left {
      margin-right: 15px;

      @include sm {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    .content-right {
      margin-left: 15px;

      @include sm {
        margin-left: 0;
        margin-bottom: 40px;
      }
    }
  }

  .content-left,
  .content-right {
    width: 100%;
    max-width: calc(50% - 15px);
    padding: 18px 11px;
    border: 1px solid $colorSilver;
    margin-bottom: 45px;
    background-color: $colorAlabaster;

    @include sm {
      max-width: 100%;
    }

    .title {
      display: inline-block;
      width: 100%;
      color: $colorBlueBayoux1;
      font-weight: 700;
      
      p {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
      }
    }

    .links {
      @extend %noStylelist;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      @include max(580px) {
        flex-wrap: wrap;
      }

      li {
        width: 100%;
        max-width: calc(25% - 8px);

        @include max(580px) {
          max-width: calc(50% - 8px);
          margin-bottom: 20px;
        }
      }

      a {
        display: inline-flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        height: auto;
        text-align: center;
        text-decoration: underline;
        text-transform: none;

        &::before {
          @include backgroundImage('', 100% auto, center);
          content: '';
          display: inline-block;
          width: 100%;
          height: 38px;
          margin-bottom: 11px;
        }

        &.ico-google-play::before {
          @include backgroundImage('gplay_logo.svg', 130px auto);
        }

        &.ico-apple-store::before {
          @include backgroundImage('app_store_logo.svg', 125px auto);
        }

        &.ico-microsoft-store::before {
          @include backgroundImage('ms_store_logo.svg', 108px auto);
        }

        &.ico-windows::before {
          @include backgroundImage('windows_10_logo.svg', 113px 24px);
        }
      }
    }

    .biblio-ico {
      @include backgroundImage('biblio_logo.png', 100% auto, center);
      display: inline-block;
      flex-shrink: 0;
      width: 49px;
      height: 49px;
      margin-right: 13px;
      box-shadow: 0 2px 4px 0 $colorBlack34;

      @include xs {
        padding-bottom: 0;
      }
    }

    a {
      color: $colorBlueBayoux1;
    }
  }

  p {
    font-size: 1.3rem;
  }
}