// ========================================
// Block ouvrage
// --------------------------------------
// - Block ouvrage niveau
// - Block ouvrage discipline

.entity-bloc-ouvrage-niveau,
.entity-bloc-ouvrage-discipline {
  > .content {
    @extend %container;

    @include sm {
      padding: 0 35px;
    }

    form {
      padding-top: 33px;
      padding-bottom: 40px;

      @include sm {
        padding-top: 35px;
        padding-bottom: 37px;
      }

      @include xs {
        padding-bottom: 50px;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .titre {
    @extend %sectionTitle;
    color: $colorBlueBayoux;
    font-size: 3rem;
    line-height: 1.05;

    @include xs {
      font-size: 2.4rem;
    }
  }

  ul {
    @extend %noStylelist;
    display: flex;

    @include xs {
      flex-wrap: wrap;
    }

    li {
      width: 100%;
    }
  }

  .sous-bloc-titre {
    padding-top: 10px;
    border-bottom: 1px solid $colorSilver;
    margin: 20px 0 18px;
    color: $colorTundora1;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }
  .step-1 {
    text-align: center;
  }
  .step-2 {
    text-align: center;


    .form-type-select {
      display: flex;
      justify-content: center;
    }

    select {
      width: 100%;
      max-width: 379px;
    }
  }

  .form-actions {
    margin: 33px 0 0;
    text-align: center;

    @include sm {
      margin-top: 21px;
    }

    input[type='submit'] {
      @extend %buttonDefault;

      @include sm {
        padding: 11px 39px;
      }
    }
  }

  // Page colors
  .color-term-1 & {
    .titre span {
      color: $colorOrange;
    }

    .entity-regroupement-niveau {
      .title {
        background-color: $colorOrange;
        display: block;
        margin: 0;
      }
    }

    .hover-wrapper {
      background-color: $colorOrange;
    }

    .entity-regroupement-discipline {
      .title {
        color: $colorOrange;
        display: block;
        margin: 0;
      }
    }

    input[type='submit'] {
      @extend %buttonOrange;
    }
  }

  .color-term-2 & {
    .titre span {
      color: $colorBleuClaire;
    }

    .entity-regroupement-niveau {
      .title {
        background-color: $colorBleuClaire;
        display: block;
        margin: 0;
      }
    }

    .hover-wrapper {
      background-color: $colorBleuClaire;
    }

    .entity-regroupement-discipline {
      .title {
        color: $colorBleuClaire;
        display: block;
        margin: 0;
      }
    }

    input[type='submit'] {
      @extend %buttonBlue;
    }
  }

  .color-term-3 & {
    .titre span {
      color: $colorMauve;
    }

    .entity-regroupement-niveau {
      .title {
        background-color: $colorMauve;
        display: block;
        margin: 0;
      }
    }

    .hover-wrapper {
      background-color: $colorMauve;
    }

    .entity-regroupement-discipline {
      .title {
        color: $colorMauve;
        display: block;
        margin: 0;
      }
    }

    input[type='submit'] {
      @extend %buttonMauve;
    }
  }

  .color-term-4 & {
    .titre span {
      color: $colorVerte;
    }

    .entity-regroupement-niveau {
      .title {
        background-color: $colorVerte;
        display: block;
        margin: 0;
      }
    }

    .hover-wrapper {
      background-color: $colorVerte;
    }

    .entity-regroupement-discipline {
      .title {
        color: $colorVerte;
        display: block;
        margin: 0;
      }
    }

    input[type='submit'] {
      @extend %buttonVerte;
    }
  }

  .page-home-home & {
    .titre span {
      color: $colorBetterave;
    }

    .entity-regroupement-niveau {
      .title {
        background-color: $colorBetterave;
        display: block;
        margin: 0;
      }
    }

    .hover-wrapper {
      background-color: $colorBetterave;
    }
    .entity-regroupement-discipline {
      .title {
        color: $colorBetterave;
        display: block;
        margin: 0;
      }
    }
  }
}

.entity-bloc-ouvrage-niveau {
  @include fullWidthBackground($colorWildSand);

  > .content {
      form {
        margin-bottom: 0;

        @include sm {
          margin-bottom: 5px;
        }

        @include xs {
          margin-bottom: 17px;
          padding-bottom: 20px;
        }
      }
    }


  .title {
    padding: 14px;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: letterSpacing(05);
    line-height: 1.2;
    text-align: center;
    box-shadow: 0 2px 8px 0 $colorBlack25;

    @include xs {
      font-size: 1.5rem;
    }
  }

  .item-list {
    margin: 0 -15px;

    @include sm {
      margin-right: -12px;
      margin-left: -12px;
    }
    @include xs {
      margin-right: -9px;
      margin-left: -9px;
    }
  }

  ul {
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 33px;
    margin-bottom: 37px;

    @include sm {
      margin-top: 45px;
      margin-bottom: 30px;
    }

    @include xs {
      justify-content: center;
      margin-top: 26px;
      margin-bottom: 59px;
    }

    li {
      padding: 0 15px;
      width: 100%;
      max-width: calc(25% - 15px);

      @include sm {
        padding: 0 12px;
        max-width: calc(25% - 12px);
      }

      @include xs {
        max-width: calc(50% - 9px);
        padding: 0 9px;
      }

      &:focus,
      &:hover,
      &.active {
        .title {
          opacity: 0.8;
        }

        img {
          transform: translateY(-80px);

          @include sm {
            transform: translateY(-110px);
          }
        }

        .hover-wrapper {
          bottom: 49px;
          max-height: 200px;
          padding: 15px 13px;
          opacity: 1;
          visibility: visible;

          @include sm {
            bottom: get-vw(49px);
          }
          @include xs {
            bottom: 46px;
          }
        }
      }

      &.active {
        img {
          transform: translateY(-80px) scale(1.05) rotate(-5deg);

          @include sm {
            transform: translateY(-110px) scale(1.05) rotate(-5deg);
          }
        }
      }

      > a {
        display: block;

        @include xs {
          margin: 0 0 30px;
        }
      }

      .content {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        color: $colorWhite;
      }

      img {
        @include transition;
        transition-delay: 0.1s;
      }

      .entity-regroupement-niveau {
        .title {
          text-transform: uppercase;

          @include sm {
            font-size: get-vw(15px);
          }

          @include xs {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .main-wrapper {
    .field-type-image {
      max-width: calc(100% - 22px);
      margin: 0 auto;

      @include sm {
        max-width: calc(100% - 21px);
      }

      @include xs {
        max-width: calc(100% - 70px);
      }

      img {
        @include xs {
          height: auto;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .hover-wrapper {
    @include transition;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 0;
    padding: 0 13px;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.6;
    opacity: 0;
    visibility: hidden;

    @include sm {
      font-size: get-vw(13px);
    }

    @include xs {
      bottom: 46px;
      font-size: 1rem;
    }
  }
}

.entity-bloc-ouvrage-discipline {
  @include fullWidthBackground($colorWildSand);
}

.entity-bloc-ouvrage-discipline {
  margin-bottom: 23px;

  .trouver-ouvrage-form {
    ul {
      flex-wrap: wrap;
      justify-content: flex-end;
      margin: 0 -15px;

      @include sm {
        margin: 0 -10px;
      }

      li {
        width: 100%;
        max-width: 33.33%;
        padding: 0 15px 10px;

        @include sm {
          max-width: 50%;
          padding: 0 10px 10px;
        }

        @include xs {
          max-width: 50%;
        }

        & a:focus,
        & a:hover,
        &.active a {
          box-shadow: 0 1px 8px 0 $colorBlack26;
          text-decoration: underline;
          text-decoration-color: $colorBlueBayoux;

          .title {
            color: $colorBlueBayoux !important;
          }
        }

        > a {
          @include transition;
          display: flex;
          justify-content: center;
          border: 1px solid $colorSilver;
          background-color: $colorWhite;
          height: 100%;
          flex-direction: column;

          @include xs {
            align-items: center;
            flex-direction: column;
          }

          .title {
            @include transition;
            padding: 16px 10px;
            color: $colorOrange;
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;
          }
        }
      }
    }
  }

  @include sm {
    margin-bottom: 0;
  }
}
