.page-user-password {
  .region-content {
    @extend %container;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  #bordsso-lost-password-form {
    .fieldset {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr minmax(auto, 570px);
      grid-template-columns: 1fr minmax(auto, 570px);
      max-width: 870px;
      width: 100%;
      padding: 20px 15px;
      border: 0;
      color: $colorShark;

      @include sm {
        -ms-grid-columns: 1fr minmax(auto, 525px);
        grid-template-columns: 1fr minmax(auto, 525px);
      }

      @include max(480px) {
        display: block;
        padding: 20px 0;
        text-align: center;
      }

      > *:not(h2) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        grid-column: 2;
        padding: 20px 15px 0;

        @include max(480px) {
          text-align: left;
        }

        &:last-child {
          padding-bottom: 20px;
        }
      }

      h2 {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-column: 1;
        border-bottom: 0;
        padding-right: 20px;
        padding-top: 20px;
        color: $colorShark;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 1.1;

        @include max(480px) {
          padding: 20px 15px 16px;
          font-size: 1.9rem;
          text-align: left;
        }
      }

      p {
        margin: 0;
        font-size: 1.3rem;
        line-height: 1.5;
      }

      .form-type-textfield {
        padding-bottom: 20px;

        label {
          width: 100%;
          margin-bottom: 5px;
          color: $colorShark;
          font-size: 1.3rem;
          font-weight: 500;
          text-transform: none;
          text-align: left;
        }

        input {
          width: 100%;
          min-height: 39px;
          border: 1px solid $colorSilver2;
          border-radius: 0;
          padding: 13px 17px;
          color: $colorShark;
          font-size: 1.3rem;
          font-weight: 500;

          &:focus {
            border-color: $colorEmperor;
            outline: none;
          }
        }
      }
    }
  }
}
