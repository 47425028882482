.region-content .node-ouvrage {
  &:not(.node-teaser) {
    @extend %container;

    @include sm {
      padding-right: 34px;
      padding-left: 34px;
    }

    @include xs {
      padding-right: 20px;
      padding-left: 20px;
    }

    > .content {
      padding-top: 40px;

      > .complements-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0 32px;
        gap: 2rem;
      }

      > .group-ouvrage-wrapper {
        margin-bottom: 47px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        @include xs {
          display: block;
        }

        .wrapper-title {
          -ms-grid-column: 2;
          grid-column: 2;
          -ms-grid-row: 2;
          grid-row: 2;
          padding: 0 32px;

          @include sm {
            padding: 0 12px;
          }
        }
      }
    }

    .social-sharing-items {
      width: 100%;
      margin-bottom: 36px;
    }

    .flag-ouvrages-favoris {
      position: relative;
    }


    .group-ouvrage-left-wrapper {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include min($xs-width) {
        margin-bottom: 4rem;

        .field-name-field-ouvrage-image {
          max-width: 270px;
        }
      }

      @include min($sm-width) {
        max-width: 270px;
        margin-bottom: 0;

        .field-name-field-ouvrage-image {
          max-width: 223px;
        }
      }

      @include xs {
        align-items: center;
        margin-bottom: 2rem;
      }

      .container {
        @include xs {
          margin-bottom: 37px;
          text-align: center;
        }
      }

      .field-name-field-ouvrage-image {
        box-shadow: 0 2px 8px 0 $colorBlack34;

        @include xs {
          margin-bottom: 37px;
        }
      }
    }

    .group-ouvrage-right-wrapper {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 32px;

      .author-title {
        margin-right: 0.5rem;
      }

      .item-list {
        .wrapper-auteurs {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          list-style-type: none;

          li {
            &:after {
              content: ", ";
            }
            &:last-child {
              &:after {
                content: "";
              }
            }
            list-style: none;
            margin: 0;

            span, a {
              color: $colorDoveGray;
              font-size: 1.5rem;
              letter-spacing: 0.5px;
              line-height: 1.5;
            }
          }
        }
      }

      .group-feuilleter-ressources-sites {
        display: flex;
        flex-wrap: wrap;
        margin: 2rem 0;
        gap: 2rem;

        @include xs {
          justify-content: center;

          .feuilleteur-ouvrage-wrapper,
          .ressources-gratuites-wrapper {
            max-width: 220px;
            width: 100%;

            a {
              width: 100%;
            }
          }
        }
      }

      .presentation-anchor-wrapper {
        color: $colorDoveGray;
        font-size: 1.5rem;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin-bottom: .5rem;

        .anchor-technique {
          color: $colorDoveGray;
          font-size: 1.5rem;
          letter-spacing: 0.5px;
          line-height: 1.5;
          font-weight: bold;
        }
      }

      .fiche-technique-anchor-wrapper {
        color: $colorDoveGray;
        font-size: 1.5rem;
        letter-spacing: 0.5px;
        line-height: 1.5;

        .anchor-technique {
          color: $colorDoveGray;
          font-size: 1.5rem;
          letter-spacing: 0.5px;
          line-height: 1.5;
          font-weight: bold;
        }
      }

      .author-wrapper {
        display: flex;
        color: $colorDoveGray;
        font-size: 1.5rem;
        letter-spacing: 0.5px;
        line-height: 1.5;
      }

      .title {
        max-width: 100%;
      }

      @include min($sm-width) {
        padding: 0 12px;
        width: 100%;
      }

      @include xs {
        padding: 0;
        width: 100%;
        max-width: none;

        div {
          order: 4;
        }

        .nouveaute-wrapper {
          order: 1;
        }

        .field-name-field-ouvrage-image {
          order: 3;
          max-width: 200px;
          margin: 0 auto 37px;
        }

        .title {
          order: 2;
        }
      }

      .complements-wrapper {
        display: flex;
        margin-top: auto;
        flex-wrap: wrap;

        @include sm {
          margin-top: 30px;
        }

        @include xs {
          text-align: center;
          justify-content: center;
          margin-top: 20px;
        }

        > div {
          margin-top: 37px;

          @include sm {
            margin-top: 10px;
          }

          + div {
            margin-left: 30px;

            @include md {
              margin-left: 10px;
            }

            @include sm {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }
      }

      .wrapper-complement {
        margin-top: 4rem;
        color: $colorBleuGris;
        font-weight: 700;
      }
    }

    .nouveaute-wrapper {
      display: inline-block;
      padding: 4.5px 24px;
      color: $colorWhite;
      font-size: 1.7rem;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
    }

    h1 {
      max-width: 100%;
      margin: 12px 0 19px;
      color: $colorBleuGris;
      font-family: $fontK2D;
      font-size: 3rem;
      font-weight: 800;
      line-height: 1;
      text-align: left;

      &::after {
        @include overstrike('', 90px, 3px, 16px 0 0);
      }
    }

    .reference-ean13-wrapper,
    .support-wrapper,
    .collection-wrapper,
    .field-name-field-ouvrage-description,
    .author-title {
      margin-bottom: 5px;
      color: $colorDoveGray;
      font-size: 1.5rem;
      letter-spacing: 0.5px;
      line-height: 1.5;
    }

    .author-title {
      flex-shrink: 0;
    }

    .field-name-field-ouvrage-description {
      margin-top: 20px;
      color: $colorBlack;

      p {
        margin-bottom: 10px;
      }

      ul {
        @extend %styleUl;

        li {
          &::before {
            margin-top: 7px;
          }
        }
      }
    }

    .group-wrapper-commerce {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      margin: 10px auto 0;
      min-width: 270px;

      @include min($sm-width) {
        min-width: 150px;
        max-width: 270px;
        flex: 1;
      }

      > div {
        p {
          font-size: 1.3rem;
          line-height: 1.5rem;
        }

        .title {
          font-weight: 700;

          .ico-tooltip-info {
            @include backgroundImage('question_icon.svg', 15px auto, center);
            display: inline-block;
            width: 15px;
            height: 15px;
            padding: 15px;
            position: relative;
            top: 11px;
            cursor: help;
          }
        }

        .tooltip-info {
          background-color: $colorBlueBayoux;
          color: $colorWhite;
          font-size: 1.2rem;
          width: 260px;
          padding: 10px;
          border-radius: 3px;
          box-shadow: 0 2px 8px 0 rgba($colorBlack, 0.25);
          position: relative;
          display: none;

          &.visible {
            display: block;
          }

          &::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            bottom: -28px;
            left: calc(50% - 15px);
            border-top: 15px solid $colorBlueBayoux;
            border-right: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 15px solid transparent;
          }
        }
      }

      .mvp-adoptant-checkbox {
        label {
          font-size: 1.3rem;
          line-height: 1.5rem;
        }
      }

      > div:not(.ressources-gratuites-wrapper) {
        width: 100%;
        padding: 17px 10px 0;
        background-color: $colorWildsand1;

        @include sm {
          max-width: 335px;
          padding: 19px 30px 33px;
          text-align: center;
        }

        @include xs {
          padding: 19px 15px 23px;
        }

        &.field-name-commerce-price,
        &.field-name-field-prix-forfait {
          display: flex;
          flex-direction: column-reverse;

          .field-item {
            margin-bottom: 8px;
            color: $colorBetterave;
            font-size: 3.2rem;
            font-weight: 700;
            line-height: 1.2;
          }

          .field-label {
            color: $colorBlack;
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.1;
          }
        }

        &.field-name-field-prix-forfait {
          padding-top: 27px;

          @include xs {
            padding-top: 0;
          }

          .field-label {
            display: flex;
            padding: 0 12px;
            margin-top: 5px;
            font-size: 1.3rem;

            @include sm {
              padding: 0 20px;
            }

            &::before {
              @include backgroundImage('info.svg', 100% auto, center);
              content: '';
              display: inline-block;
              flex-shrink: 0;
              width: 20px;
              height: 20px;
            }
          }
        }

        &.disponibilite-wrapper {
          display: flex;
          align-items: center;
          padding-bottom: 50px;
          color: $colorBlack;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1.2;

          @include sm {
            display: inline-flex;
            justify-content: center;
          }

          @include xs {
            padding-bottom: 11px;
          }

          &::before {
            @include backgroundImage('info.svg', 100% auto, center);
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 12px;
          }

          &.disponible {
            &::before {
              @include backgroundImage('check_article_dispo.svg');
            }
          }
        }

        &.field-name-field-product-reference,
        &.cmd-affiliation,
        &.cmd {
          .link-cmd-affiliation,
          .link-cns,
          &.cmd > a,
          &.cmd > span {
            @extend %buttonDefault;
            @extend %buttonInput;
            position: relative;
            width: 100%;
            max-width: 200px;
            padding: 15px 23px 15px 55px;
            color: $colorWhite;
            font-weight: 500;
            text-transform: none;
            box-shadow: 0 2px 8px 0 rgba($colorBlack, 0.25);

            &::before {
              @include transition;
              @include backgroundImage('add_cart.svg', 22px 31px, center center);
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 15px;
              display: inline-block;
              width: 22px;
              height: 31px;
              margin: auto;
              transform-origin: bottom right;
            }

            &:focus,
            &:hover {
              &::before {
                transform: rotate(10deg);
              }

              input:not([disabled]) {
                text-decoration: underline;
              }
            }

            &.link-cmd-affiliation {
              background-color: $colorBlueBayoux;
            }
          }

          .link-cns {
            padding: 15px 13px 15px 45px;
          }

          &.cmd > a,
          &.cmd > span {
            padding: 15px 13px 15px 45px;

            &.disabled {
              cursor: default;
              opacity: 0.6;

              &:focus,
              &:hover {
                text-decoration: none;

                &::before {
                  transform: none;
                }
              }
            }
          }
        }

        &.cmd {
          padding-bottom: 13px;

          @include xs {
            padding-bottom: 23px;
          }

          &.free {
            > a {
              padding: 15px 30px;

              &::before {
                background-image: none;
              }
            }
          }
        }

        &.field-name-field-product-reference {
          padding-bottom: 8px;

          @include xs {
            padding-top: 0;
            padding-bottom: 23px;
          }

          .form-submit {
            @include backgroundImage('add_cart.svg', 22px 31px, left 15px center);
            display: inline-block;
            height: 47px;
            max-width: 200px;
            padding: 15px 23px 15px 55px;
            background-color: $colorBetterave;
            color: $colorWhite;
            font-size: 1.4rem;
            font-weight: 500;
            text-transform: none;

            &:focus,
            &:hover {
              @include backgroundImage('add_cart_tilted.svg');
              @extend %boxShadowDefault;
              text-decoration: underline;
              opacity: 0.8;

              input:not([disabled]) {
                text-decoration: underline;
              }
            }

            + .cns-link {
              margin-top: 20px;
            }
          }
        }

        &.cmd-affiliation {
          order: 0;
          padding-bottom: 36px;

          @include xs {
            padding-top: 0;
            padding-bottom: 33px;
          }

          .infos {
            padding: 12px 40px 0;
            font-size: 1.3rem;
            line-height: 1.1;
            text-align: center;
          }
        }
      }

      .ressources-gratuites-wrapper {
        width: 100%;
        margin-top: auto;
        padding-top: 40px;
        justify-content: center;
        align-self: flex-end;

        @include sm {
          width: auto;
          margin-top: 19px;
          align-self: center;
          padding-top: 0;
        }

        @include xs {
          margin-top: 10px;
        }

        a {
          width: 100%;
          color: $colorOrange;

          @include xs {
            width: auto;
          }
        }
      }
    }

    // Buttons
    .complements-wrapper,
    .feuilleteur-ouvrage-wrapper,
    .ressources-gratuites-wrapper,
    .url-presentation-wrapper {
      a {
        @extend %buttonGris;
        display: inline-flex;
        align-items: center;
        padding: 7px 35px;
        font-size: 1.5rem;
        font-weight: 500;
        text-transform: none;

        @include sm {
          font-size: 1.4rem;
        }

        &::before {
          @include backgroundImage('', 20px 18px, center);
          content: '';
          display: inline-block;
          flex-shrink: 0;
          width: 22px;
          height: 31px;
          transform: translateX(-17px);

          @include sm {
            width: 20px;
            height: 20px;
          }
        }

        &.url-presentation::before {
          @include backgroundImage('info_blue.svg');
        }

        &.anchor-complement-numerique::before {
          @include backgroundImage('numerique.svg');
        }

        &.link-feuilleteur-ouvrage::before {
          @include backgroundImage('feuilleter-1.svg');
        }

        &.anchor-complement-papier::before {
          @include backgroundImage('papier.svg');
        }
      }
    }

    .informations-ouvrgage {
      display: flex;
      flex-wrap: wrap;
      margin-top: 4rem;

      @include min($sm-width) {
        padding: 0 32px;
      }

      .title-bloc {
        &.wrapper-technique,
        &.wrapper-presentation {
          margin-bottom: 2rem;
        }
      }

      .title-bloc:not(.wrapper-presentation):not(.wrapper-auteurs):not(.wrapper-technique) {
        display: flex;
        margin-bottom: 2rem;
        color: $colorDoveGray;
        font-size: 1.5rem;
        letter-spacing: 0.5px;
        line-height: 1.5;
      }

      .title-bloc:not([id]) {
        width: 100%;
        font-size: 1.4rem;
        line-height: 1.65;
        letter-spacing: letterSpacing(35);

        @include sm {
          margin-bottom: 35px;
        }
      }

      #wrapper-presentation,
      #wrapper-technique {
        font-size: 1.8rem;
        font-weight: bold;
        color: $colorBleuGris;
        scroll-margin-top: 8rem;
      }

      .wrapper-presentation ul li {
        display: block;
      }

      .wrapper-auteurs-direction {
        .entity-ouvrage-auteurs .content {
          > div {
            display: inline-block;
          }
        }
      }

      ul {
        @extend %styleUl;

        li {
          &::before {
            margin-top: 7px;
          }
        }

        &.wrapper-auteurs-direction,
        &.wrapper-technique {
          li {
            position: relative;
            display: block;

            &::before {
              display: inline-block;
              margin-right: 1.5rem;
            }
          }
        }
      }

      .wrapper-auteurs {
        li {
          display: block;
          list-style: none;

          &::before {
            display: none;
          }

          a {
            text-decoration: none;

            &:focus,
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    // Page colors
    .color-term-1 & {
      h1::after {
        background-color: $colorOrange;
      }

      .nouveaute-wrapper {
        background-color: $colorOrange;
      }

      .complements-wrapper,
      .feuilleteur-ouvrage-wrapper {
        color: $colorOrange;

        a {
          justify-content: center;
        }
      }

      .link-feuilleteur-ouvrage,
      .link-ressources-gratuites {
        background-color: $colorBleuGris;
        color: $colorWhite;
        justify-content: center;
      }

      .link-ressources-gratuites {
        justify-content: center;

        &::before {
          @include backgroundImage('ressources-white.svg', cover);
        }
      }

      .informations-ouvrgage,
      .field-name-field-ouvrage-description {
        ul {
          li {
            &::before {
              background-color: $colorDoveGray;
              width: 4px;
              height: 4px;
              margin-right: 1rem;
            }
          }
        }

        .title-bloc:not([id]) a {
          color: $colorOrange;
        }
      }
    }

    .color-term-2 & {
      h1::after {
        background-color: $colorBleuClaire;
      }

      .nouveaute-wrapper {
        background-color: $colorBleuClaire;
      }
      .presentation-anchor-wrapper a {
        color: $colorBleuClaire;
      }

      .complements-wrapper,
      .feuilleteur-ouvrage-wrapper {
        color: $colorBleuClaire;
      }

      .link-ressources-gratuites {
        &::before {
          @include backgroundImage('ressources-blue.svg', 20px 18px);
        }
      }

      .ressources-gratuites-wrapper a {
        &:focus,
        &:hover {
          color: $colorBleuClaire;
        }
      }

      .informations-ouvrgage,
      .field-name-field-ouvrage-description {
        ul li::before {
          background-color: $colorBleuClaire;
        }

        .title-bloc:not([id]) a {
          color: $colorBleuClaire;
        }
      }
    }

    .color-term-3 & {
      h1::after {
        background-color: $colorMauve;
      }

      .nouveaute-wrapper {
        background-color: $colorMauve;
      }
      .presentation-anchor-wrapper a {
        color: $colorMauve;
      }

      .complements-wrapper,
      .feuilleteur-ouvrage-wrapper {
        color: $colorMauve;
      }

      .link-ressources-gratuites {
        &::before {
          @include backgroundImage('ressources-mauve.svg', 20px 18px);
        }
      }

      .ressources-gratuites-wrapper a {
        &:focus,
        &:hover {
          color: $colorMauve;
        }
      }

      .informations-ouvrgage,
      .field-name-field-ouvrage-description {
        ul li::before {
          background-color: $colorMauve;
        }

        .title-bloc:not([id]) a {
          color: $colorMauve;
        }
      }
    }

    .color-term-4 & {
      h1::after {
        background-color: $colorVerte;
      }

      .nouveaute-wrapper {
        background-color: $colorVerte;
      }
      .presentation-anchor-wrapper a {
        color: $colorVerte;
      }

      .complements-wrapper,
      .feuilleteur-ouvrage-wrapper {
        color: $colorVerte;
      }

      .link-ressources-gratuites {
        &::before {
          @include backgroundImage('ressources-verte.svg', 20px 18px);
        }
      }

      .ressources-gratuites-wrapper a {
        &:focus,
        &:hover {
          color: $colorVerte;
        }
      }

      .informations-ouvrgage,
      .field-name-field-ouvrage-description {
        ul li::before {
          background-color: $colorVerte;
        }

        .title-bloc:not([id]) a {
          color: $colorVerte;
        }
      }
    }
  }

  &.node-teaser {
    margin-bottom: 50px;

    @include sm {
      margin-bottom: 66px;
    }

    @include xs {
      margin-bottom: 49px;
    }

    > .content {
      display: flex;
      align-items: flex-end;
      align-content: baseline;
      flex-wrap: wrap;
      justify-content: center;
      height: 100%;
      padding: 0 12px;

      @include xs {
        padding: 0 5px;
      }

      &:focus,
      &:hover {
        .group-hover {
          top: 0;
          opacity: 1;
        }
      }
    }

    .group-top,
    .group-bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .hidden {
        display: none;
      }
    }

    .group-top {
      position: relative;
      height: 259px;

      @include sm {
        height: auto;
      }

      .group-hover {
        @include transition;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        background-color: $colorConcrete;
        opacity: 0;
        margin-top: 29px;
        flex-direction: column;
        align-items: center;

        @include sm-up {
          align-content: center;
        }

        @include sm {
          margin-top: 0;
          flex-direction: row;
        }

        > div {
          @include transition;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex: 0 0 50%;
          width: 100%;
          max-width: 50%;
          max-height: 50%;
          padding-bottom: 10px;

          @include sm-up {
            padding: 10px;
          }

          &:first-child,
          &:nth-child(2) {
            @include sm {
              margin-top: 15px;
            }
          }
        }

        > .view-details-wrapper {
          @include sm-up {
            order: 1;
          }
        }

        > .field.field-name-field-product-reference {
          @include sm-up {
            order: 3;
          }
        }

        > .flag-outer.flag-outer-ouvrages-favoris {
          @include sm-up {
            order: 2;
          }
        }

        > .feuillteur-wrapper.external {
          @include sm-up {
            order: 4;
          }
        }
      }

      a,
      input,
      .link-feuilleteur-ouvrage {
        padding: 5px;
        background-color: transparent;
        color: $colorEmperor;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.1;
        text-align: center;
        text-decoration: none;
        text-transform: none;

        &:focus,
        &:hover {
          color: $colorBlueBayoux;
          text-decoration: underline;

          .ico-view-details {
            @include backgroundImage('info_blue.svg');
          }

          .ico-feuilleter {
            @include backgroundImage('feuilleter.svg');
          }
        }
      }

      // Info and leaflet botton
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
          @include backgroundImage('', 100% auto, center);
          display: inline-block;
          width: 27px;
          height: 24px;
          margin-bottom: 21px;

          @include sm {
            margin-bottom: 10px;
          }

          // Info
          &.ico-view-details {
            @include backgroundImage('info_red.svg');
            height: 27px;
          }

          //Leaflet
          &.ico-feuilleter {
            @include backgroundImage('feuilleter_red.svg');
          }
        }
      }

      // Add to favourites button

      .flag-wrapper {
        position: static;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;

        &:focus,
        &:hover {
          .flag.flag-action::before {
            @include backgroundImage('add_favoris_blue.svg');
          }

          .flag.unflag-action::before {
            @include backgroundImage('remove_favoris_blue.svg');
          }
        }

        .flag {
          &::before {
            @include backgroundImage('', 100% auto, center);
            content: '';
            display: inline-block;
            width: 27px;
            height: 24px;
            margin-bottom: 21px;
          }

          &.flag-action {
            &::before {
              @include backgroundImage('add_favoris_red.svg');
            }
          }

          &.unflag-action {
            &::before {
              @include backgroundImage('remove_favoris_red.svg');
            }
          }
        }
      }

      // Add to cart button
      .field-name-field-product-reference {
        form > div:not(.message-add-cart) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          input {
            @include backgroundImage('add_cart_red.svg', 29px 42px, top center);
            display: inline-block;
            max-width: 100%;
            padding-top: 62px;
            white-space: inherit;

            @include sm {
              padding-top: 42px;
              background-size: 29px 32px;
            }

            &:focus,
            &:hover {
              @include backgroundImage('add_cart_blue.svg', 29px 42px);

              @include sm {
                background-size: 29px 32px;
              }
            }
          }
        }
      }
    }

    .nouveaute-wrapper {
      display: inline-block;
      padding: 5.5px 15px;
      margin-bottom: 6px;
      background-color: $colorBetterave;
      color: $colorWhite;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;

      ~ .group-hover {
        margin-top: 0;
      }
    }

    .field-name-field-ouvrage-image {
      width: 100%;
      height: 226px;
      padding-bottom: 8px;
      margin-top: auto;
      text-align: center;

      @include sm {
        height: auto;
      }

      * {
        height: 100%;

        @include sm {
          height: auto;
        }
      }

      img {
        height: auto;
        width: auto;
        margin: 0 auto;
        object-fit: cover;
        box-shadow: 0 2px 8px 0 $colorBlack34;
        max-height: 100%;

        @include sm {
          object-fit: unset;
        }
      }
    }

    .field-name-title-field {
      margin-top: 18px;
      margin-bottom: 30px;
      text-align: center;
      width: 100%;

      @include sm {
        margin-top: 12px;
        margin-bottom: 10px;
      }

      h2 {
        margin: 0;
      }

      a {
        color: $colorShark;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4;
        text-decoration: none;

        &:focus,
        &:hover {
          color: $colorBlueBayoux;
          text-decoration: underline;
        }

        @include xs {
          font-size: 1.2rem;
        }
      }
    }

    .field-type-commerce-price {
      color: $colorBetterave;
      font-size: 1.7rem;
      font-weight: 700;
    }
  }
}

header .bottom .mes-favoris {
  .content {
    position: relative;
    display: flex;
    margin-bottom: 15px;

    .group-top {
      width: 100%;
      max-width: 90px;
    }

    .group-bottom {
      width: 100%;
      max-width: calc(100% - 90px);
      margin-bottom: 85px;

      .hidden {
        display: none;
      }
    }

    .field-name-title-field {
      a {
        display: inline-block;
        margin-left: 20px;
        font-weight: 400;
      }
    }

    .field-name-commerce-price {
      margin-left: 20px;
      margin-top: 10px;
      color: $colorBetterave;
      font-size: 1.4rem;
      font-weight: 700;
    }

    a {
      color: $colorShark;
    }

    img {
      box-shadow: 0 2px 8px 0 $colorBlack34;
    }

    .group-hover {
      position: absolute;
      right: 30px;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      max-width: calc(100% - 130px);
      z-index: 1;

      @include max(500px) {
        right: 0;
      }

      .view-details-wrapper,
      .feuillteur-wrapper {
        display: none;
      }

      .flag-wrapper {
        display: flex;
      }

      .flag-outer {
        .flag {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 2px;

          &::before {
            @include backgroundImage('delete_grey.svg', 100% auto, center);
            content: '';
            display: inline-block;
            width: 15px;
            height: 20px;
            margin-bottom: 7px;
          }
        }
      }

      .flag-message {
        top: 0px;
        max-width: 80px;
        background-color: $colorWhite;
        padding: 3px 0;
      }

      .field-name-field-product-reference {
        form > div:not(.message-add-cart) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          input {
            @include backgroundImage('add_cart_red.svg', 29px 24px, top center);
            transition: none;
            display: inline-block;
            max-width: 100%;
            padding: 30px 0 0;
            white-space: inherit;
            background-color: transparent;
            color: $colorShark;
            font-weight: 400;
            text-decoration: underline;
            font-size: 1.2rem;

            @include sm {
              padding-top: 26px;
              background-size: 29px 22px;
            }

            &:focus,
            &:hover {
              @include backgroundImage('add_cart_blue.svg');
              text-decoration: none;
              box-shadow: none;
              opacity: 1;
              transform: none;

              @include sm {
                background-size: 29px 22px;
              }
            }
          }
        }
      }

      a {
        font-size: 1.2rem;
      }
    }
  }
}

.flag-message {
  @extend %boxShadowDefault;
  position: absolute;
  z-index: 100;
  top: -170px;
  display: block;
  width: 180px;
  height: 180px;
  padding: 80px 17px 15px;
  border-radius: 3px;
  background-color: $colorWhite;
  font-size: 1.6rem;
  line-height: 1.92rem;
  font-weight: 500;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 80px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background-color: $colorWhite;
    transform: rotate(45deg);
    background-repeat: no-repeat;
  }

  &::after {
    content: '';
    position: absolute;
    top: 14px;
    right: 65px;
    display: inline-block;
    width: 25px;
    height: 25px;
    @include backgroundImage('icon-success.svg', contain, center);
  }


  &.flag-flagged-message {
    @include backgroundImage('add_favoris.svg', 30px, top 30px center);
  }

  &.flag-unflagged-message {
    @include backgroundImage('remove_favoris.svg', 30px, top 30px center);
  }

  &.error {
    &::after {
      @include backgroundImage('icon-error.svg', contain, center);
    }
  }
}

.flag {
  position: relative;
}

.group-hover {
  .flag-message {
    position: relative;
    top: 0;
    left: 0;
    z-index: 999;
  }
}
