.entity-home {
  > .content > .field-name-field-description {
    @extend %container;
  }

  .slider-wrapper {
    margin-bottom: 18px;

    ul {
      @extend %noStylelist;
    }

    a {
      text-decoration: none;
    }

    .slick-prev {
      @include sm {
        left: 15px;
      }
    }

    .slick-next {
      @include sm {
        right: 15px;
      }
    }
  }
}
