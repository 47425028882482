.slick-slider {
  .slick-arrow {
    @extend %hiddenText;
    @include backgroundImage('', 26px 26px, center);
    @include transition;
    position: absolute;
    top: calc(50% - 35px);
    height: 69px;
    width: 69px;
    border: 3px solid $colorEmperor;
    border-radius: 50%;
    background-color: $colorWhite;
    opacity: 0.5;
    transform: translate(0, -50%);
    z-index: 1;

    @include sm {
      top: calc(50% - 24px);
      height: 48px;
      width: 48px;
      background-size: 18px 18px;
    }

    &:focus,
    &:hover {
      opacity: 0.8;
      outline: none;
    }

    &.slick-prev {
      @include backgroundImage('arrow_slider_l.svg');
      left: 40px;
    }

    &.slick-next {
      @include backgroundImage('arrow_slider_r.svg');
      right: 40px;
    }
  }

  .slick-dots {
    @extend %noStylelist;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    li {
      display: inline-flex;
      align-items: center;

      &:not(:last-child) {
        padding-right: 11px;
      }

      &.slick-active {
        button {
          width: 45px;
          border-radius: 7px;
        }
      }

      button {
        @extend %hiddenText;
        @include transition;
        display: inline-block;
        height: 15px;
        width: 15px;
        border: 0;
        border-radius: 50%;
        background-color: $colorDustyGray;

        &:focus {
          outline: none;
        }
      }
    }
  }

  img {
    width: 100%;
  }
}

.page-home-home {
  .slick-dots {
    .slick-active button {
      background-color: $colorBetterave;
    }
  }
}
