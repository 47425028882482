.entity-section-thematique {
  @extend %container;
  padding-top: 33px;
  padding-bottom: 44px;

  .content {
    display: flex;
    flex-wrap: wrap;

    > .item-list {
      width: 100%;

      > ul:not(.tabs-wrapper) {
        height: 100%;
        min-height: 405px;

        @include sm {
          min-height: 0;
          height: auto;
        }

        @include max(550px) {
          display: block;
        }

        > li {
          @include transition($duration: 0.4s);
          transform: scale(0);
          visibility: hidden;
          width: 100%;
          max-width: 0;
          max-height: 0;
          opacity: 0;
          will-change: transform, opacity, visibility;

          @include max(550px) {
            max-width: 100%;
            max-height: 100%;
            opacity: 1;
            visibility: visible;
            transform: scale(1);
          }

          &.active {
            transform: scale(1);
            max-width: 100%;
            max-height: 100%;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .tabs-wrapper {
    justify-content: center;
    margin-bottom: 24px;

    @include max(550px) {
      display: none;
    }

    li {
      margin: 0 51px;

      @include sm {
        margin: 0 15px 10px;
      }

      &:last-child {
        @include sm {
          margin-right: 0;
        }
      }
    }

    a {
      @include transition;
      color: $colorBoulder;
      font-size: 1.4rem;
      line-height: 1.2;
      text-decoration: none;
      will-change: color;

      &:focus,
      &:hover {
        text-decoration: underline;
      }

      &.active {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }

  .meas-wrapper {
    width: 100%;

    > .item-list {
      margin: 0 -15px;

      @include sm {
        margin: 0 -7px;
      }

      @include xs {
        margin: 0;
      }
    }

    ul {
      @include max(550px) {
        flex-wrap: wrap;
      }
    }

    li {
      width: 100%;
      max-width: 33.33%;
      padding: 0 15px;
      margin-bottom: 20px;

      @include sm {
        padding: 0 7px;
      }

      &.mea-bloc-html-libre-wrapper {
        max-width: 100%;
      }

      @include max(550px) {
        width: 100%;
        max-width: 100%;
      }

      > a {
        width: 100%;
        max-width: 370px;
        display: block;
        height: 100%;
        text-decoration: none;

        @include xs {
          max-width: 100%;
        }
      }
    }

    .entity .entity:not(.mea-autopromo) {
      height: 100%;

      > * {
        height: 100%;
      }
    }

    .content-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      box-shadow: 0 4px 8px 0 $colorGray2_50;
    }

    .mea-contenu-texte-wrapper {
      &:focus,
      &:hover {
        img {
          transform: scale(1.05);
        }
      }

      .field-name-field-label {
        margin-bottom: 10px;
        font-size: 1.7rem;
        line-height: 1.15;
      }

      .wrapper-top {
        overflow: hidden;
      }

      .wrapper-top,
      img {
        max-height: 220px;
      }
    }

    .wrapper-bottom {
      padding: 30px;
      .title-wrapper {
        margin: 0;
        padding: 0;
        font-size: 1.7rem;
        line-height: 1.15;
      }
    }

    .field-name-field-contenu-texte-sous-titre {
      margin-bottom: 7px;
      color: $colorBoulder;
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .field-name-field-accroche {
      color: $colorBoulder;
      font-size:1.4rem;
      line-height: 1.5;
    }
  }

  .mea-autopromo .content {
    @include sm {
      height: auto;
    }
  }

  .autopromo-wrapper {
    position: relative;
    box-shadow: 0 4px 8px 0 $colorGray2_50;

    &:focus,
    &:hover {
      img {
        transform: scale(1.05);
      }

      .button {
        @extend %boxShadowDefault;
        text-decoration: none;

        &::after {
          opacity: 0.8;
        }
      }
    }

    .field-item,
    .field-items,
    .field-name-field-visuel,
    img {
      height: 100%;
      width: 100%;

      @include sm {
        height: auto;
      }
    }

    .field-name-field-label {
      position: absolute;
      right: 0;
      bottom: 25px;
      left: 0;
      text-align: center;

      .button {
        @extend %buttonDefault;
      }
    }
  }

  .titre {
    width: 100%;
    margin: 0;
    color: $colorBlueBayoux;
    font-family: $fontK2D;
    font-weight: 800;
    font-size: 3rem;
    line-height: 1.1;
    text-align: center;
    letter-spacing: 0;

    &::after {
      @include overstrike($colorSilver, 120px, 1px, 15px auto 26px);
    }
  }

  ul {
    @extend %noStylelist;
    display: flex;
  }

  img {
    @include transition;
  }

  // Page colors
  .color-term-1 & {
    .titre span {
      color: $colorOrange;
    }

    .tabs-wrapper .active {
      color: $colorOrange;
    }

    .field-name-field-label {
      color: $colorOrange;

      .button {
        @extend %buttonOrange;
      }
    }
  }

  .color-term-2 & {
    .titre span {
      color: $colorBleuClaire;
    }

    .tabs-wrapper .active {
      color: $colorBleuClaire;
    }

    .field-name-field-label {
      color: $colorBleuClaire;

      .button {
        @extend %buttonBlue;
      }
    }
  }

  .color-term-3 & {
    .titre span {
      color: $colorMauve;
    }

    .tabs-wrapper .active {
      color: $colorMauve;
    }

    .field-name-field-label {
      color: $colorMauve;

      .button {
        @extend %buttonMauve;
      }
    }
  }

  .color-term-4 & {
    .titre span {
      color: $colorVerte;
    }

    .tabs-wrapper .active {
      color: $colorVerte;
    }

    .field-name-field-label {
      color: $colorVerte;

      .button {
        @extend %buttonVerte;
      }
    }
  }

  .page-home-home & {
    .titre span {
      color: $colorBetterave;
    }

    .tabs-wrapper .active {
      color: $colorBetterave;
    }

    .field-name-field-label {
      color: $colorBetterave;
    }
  }
}
.page-home {
  .entity-section-thematique {
    .content {
      > .item-list {
        > ul:not(.tabs-wrapper) {
          height: 100%;
          min-height: 400px;
        }
      }
    }
  }
}