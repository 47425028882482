/**
 * Main menu
 */
#block-system-main-menu {
	margin: 0 0 20px;

	ul.menu {
		width: 100%;
		display: table;
		padding: 0;
		margin: 0;

		li {
			display: table-cell;
			list-style: none;
			margin: 0 5px;
			padding: 0;
			text-align: center;
			border-right: 1px solid $colorWhite;

			&.last {
				border-right: none;
			}

			a {
				display: block;
				background-color: $colorBlack;
				color: $colorWhite;
				padding: 6px 10px;
				text-transform: uppercase;
				font-weight: bold;
				text-decoration: none;
				font-size: 1em;

				&:hover {
					background-color: $colorSunsetOrange;
				}
			}
		}
	}
}

.menu-mobile {
	position: relative;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 18px;
	height: 12px;

	@include max($sm-width) {
		display: flex;
		flex-shrink: 0;
	}

	@include sm {
		margin-top: 15px;
	}

	&.open {
		span {
			&:first-child {
				top: 6px;
				transform: rotate(45deg);
			}

			&:nth-child(2) {
				left: -222px;
				opacity: 0;
			}

			&:last-child {
				top: -4px;
				transform: rotate(-45deg);
			}
		}
	}

	span {
		@include transition;
		position: relative;
		left: 0;
		top: 0;
		display: inline-block;
		width: 100%;
		height: 2px;
		background-color: $colorBlueBayoux;
		opacity: 1;
		transform-origin: center;
	}
}

#block-bordas-block-bordas-refonte-main-menu {
	@include transition($duration: 0.5s);

	&.show {
		@include sm {
			opacity: 1;
			max-height: 1500px;
			visibility: visible;
		}
	}

	@include sm {
		opacity: 0;
		max-height: 0;
		visibility: hidden;
	}

	> .content {
		@extend %container;
		padding-top: 33px;
		padding-bottom: 23px;

		@include sm {
			padding: 16px 35px;
		}

		> .item-list {
			position: relative;
		}
	}

	.nav {
		&.level-0 {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include sm {
				flex-wrap: wrap;
			}

			> li {
				display: inline-flex;

				@include sm {
					display: block;
					width: 100%;
					border-bottom: 1px solid $colorSilver;
				}

				> a {
					padding: 14px 13px;

					@include sm {
						width: 100%;
						padding: 3px 0 7px;
						font-size: 1.063em;
						font-weight: 500;
						line-height: 30px;
					}
				}

				&.open-submenu {
					@include sm {
						border-bottom: 0;
					}

					&.has-child {
						> .item-list {
							@include sm {
								display: block;
							}
						}

						> a {
							@include sm {
								border-bottom: 1px solid $colorMercury;
								margin-bottom: 18px;
							}

							&::after {
								@include backgroundImage('chevron_menu_red.svg');
								transform: translate(0, -50%) rotate(0);
							}
						}
					}
				}

				&.has-child {
					> .item-list {
						@include sm {
							display: none;
						}
					}
				}
			}

			> .has-child {
				@include min($sm-width) {
					&:focus,
					&:hover {
						border: 1px solid $colorMercury;
						border-bottom: 0;

						a {
							&::before {
								height: 1px;
							}
						}
					}

					&.show-submenu {
						> a {
							background-color: $colorWhite;
							z-index: 100;
						}

						.level-1 {
						  opacity: 1;
						  transform: scale(1);
						}
					}
				}

				> a {
					position: relative;
					padding-right: 29px;

					&::after,
					&::before {
						content: '';
						position: absolute;
						display: inline-block;
					}

					&::after {
						@include backgroundImage('chevron_menu.svg', 100% auto, right center);
						width: 10px;
						height: 14px;
						top: 50%;
						right: 10px;
						transform: translate(0, -50%);
					}

					&::before {
						height: 0px;
						background-color: $colorWhite;
						right: 0;
						bottom: 2px;
						left: 0;
						z-index: 100;

						@include sm {
							display: none;
						}
					}
				}
			}
		}

		.level-1 {
			@include transition;
			padding-left: 20px;

			@include min($sm-width) {
				@include fullWidthBackground($colorWhite);
				border-top: 1px solid $colorMercury;
				display: -ms-grid;
				display: grid;
				position: absolute;
				padding: 39px 0;
				top: 48px;
				left: 0;
				right: 0;
				z-index: 102;
				-ms-grid-columns: 1fr 1fr 1fr 1fr;
				grid-template-columns: repeat(4, 1fr);
				-ms-grid-rows: auto auto auto;
				grid-template-rows: repeat(3, auto);
				grid-auto-flow: column;

				opacity: 0;
				transform: scale(0);
				transform-origin: top center;
				will-change: transform, opacity;
			}

			&::after,
			&::before {
				@include min($sm-width) {
					top: -1px;
					border-top: 1px solid $colorMercury;
				}
			}

			> .menu-item {
				margin-bottom: 10px;
				margin-right: 25px;

				&.first {
					-ms-grid-row: 1;
					grid-row-start: 1;
					-ms-grid-row-span: 3;
					grid-row-end: 4;
				}

				&:nth-child(2) {
					-ms-grid-row: 1;
					-ms-grid-column: 2;
				}

				&:nth-child(3) {
					-ms-grid-row: 2;
					-ms-grid-column: 2;
				}

				&:nth-child(4) {
					-ms-grid-row: 3;
					-ms-grid-column: 2;
				}

				&:nth-child(5) {
					-ms-grid-row: 1;
					-ms-grid-column: 3;
				}

				&:nth-child(6) {
					-ms-grid-row: 2;
					-ms-grid-column: 3;
				}
				&:nth-child(7) {
					-ms-grid-row: 3;
					-ms-grid-column: 3;
				}

				&.last {
					a {
						@include sm {
							border: 0;
						}
					}
				}

				+ .autopromo {
					-ms-grid-row: 1;
					-ms-grid-row-span: 3;
					grid-row: 1 / 4;
					-ms-grid-column: 4;
					grid-column: 4;

					@include min($sm-width) {
						padding-left: 15px;
					}

					@include sm {
						text-align: center;
					}
				}

				> span,
				> a {
					display: flex;

					@include min($sm-width) {
						margin-bottom: 15px;
						font-weight: 600;
					}

					@include sm {
						width: 100%;
						border-bottom: 1px solid $colorMercury;
						margin-bottom: 18px;
						padding: 3px 10px 7px 0;
					}
				}

				&.open-submenu {
					@include sm {
						border-bottom: 0;
					}

					&.has-child {
						> .item-list {
							@include sm {
								display: block;
							}
						}

						> a {
							@include sm {
								border-bottom: 1px solid $colorMercury;
								margin-bottom: 18px;
							}

							&::after {
								@include sm {
									@include backgroundImage('chevron_menu_red.svg');
									transform: translate(0, -50%) rotate(0);
								}
							}
						}
					}
				}

				&.has-child {
					> .item-list {
						@include sm {
							display: none;
						}
					}
				}
			}

			.has-child {
				@include sm {
					margin-bottom: 20px;
				}
			}
		}

		.level-2 {
			@include sm {
				padding-left: 20px;
			}

			a {
				font-size: 1.3rem;
				margin-bottom: 5px;

				@include sm {
					padding: 3px 0 7px;
				}
			}
		}

		.has-child {
			> span,
			> a {
				@include sm {
					position: relative;
					padding-right: 29px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-right: 10px;
				}

				&::after {
					@include sm {
						@include backgroundImage('chevron_menu.svg', 100% 100%, right center);
						content: '';
						display: inline-block;
						width: 10px;
						height: 14px;
						transform: translate(0, -50%) rotate(-90deg);
					}
				}
			}
		}

		.front {
			a {
				display: flex;

				@include min($sm-width) {
					font-size: 0;
					color: transparent;
				}

				&::before {
					@include backgroundImage('home.svg', 100% 100%, center);
					display: block;
					width: 18px;
					height: 20px;

					@include min($sm-width) {
						content: "";
					}
				}
			}
		}

		.new {
			&::after {
				content: "Nouveau !";
				color: $colorCuriousBlue;
			}
		}

		a {
			display: inline-block;
			color: $colorBlack;
			font-family: $fontRubik;
			line-height: 1.4;
			letter-spacing: letterSpacing(31.25);

			@include sm {
				display: block;
			}

			&:focus,
			&:hover {
				text-decoration: none;
			}

			&.mea-autopromo-wrapper {
				max-width: 100%;
			}
		}
	}

	.cible-enseignant .level-1 .menu-item:nth-last-child(2) {
		-ms-grid-column: 3 !important;
		-ms-grid-row: 1 !important;
		grid-column: 3;
	}

	ul {
		@extend %noStylelist;
	}

	a {
		text-decoration: none;
	}
}

#block-bordas-block-bordas-refonte-footer-menu {
	padding-top: 30px;
	padding-bottom: 35px;
	border: solid $colorAlto;
	border-width: 3px 0;

	@include sm {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.level-0 {
		position: relative;
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: minmax(min-content, 400px) 1fr;
		grid-template-columns: minmax(min-content, 400px) 1fr;
		font-size: 1.3rem;

		@include md {
			-ms-grid-columns: minmax(min-content, 370px) 1fr;
			grid-template-columns: minmax(min-content, 370px) 1fr;
		}

		@include sm {
			-ms-grid-columns: 1fr 2fr;
			grid-template-columns: 1fr 2fr;
		}

		@include xs {
			display: block;
		}

		> li {
			@include xs {
				margin-bottom: 20px;
			}

			> span {
				display: inline-block;
				font-size: 1.8rem;
				font-weight: 700;
				margin-bottom: 20px;

				@include xs {
					margin-bottom: 10px;
				}
			}
		}

		> .first {
			-ms-grid-column: 1;
			grid-column: 1;
			padding-right: 96px;

			@include md {
				padding-right: 60px;
			}

			@include sm {
				padding-right: 20px;
			}

			@include xs {
				padding-right: 0;
			}
		}

		> .last {
			-ms-grid-column: 2;
			grid-column: 2;

			> .item-list > ul {
				display: -ms-grid;
				display: grid;
				-ms-grid-columns: minmax(min-content, 341px) 1fr;
				grid-template-columns: minmax(min-content, 341px) 1fr;
				-ms-grid-rows: auto auto;
				grid-template-rows: auto auto;

				@include sm {
					-ms-grid-columns: 1fr 15px 1fr;
					grid-template-columns: 1fr 15px 1fr;
				}

				@include xs {
					display: block;
				}

				> li {
					@include xs {
						margin-bottom: 20px;
					}
				}

			}
		}

		a {
			text-decoration: none;

			&:focus,
			&:hover {
				color: $colorBetterave;
				text-decoration: underline;
			}
		}
	}

	.level-1 {
		> li {
			margin-bottom: 6px;

			&:first-child {
				-ms-grid-column: 1;
				grid-column: 1;
				-ms-grid-row: 1;
				grid-row: 1;
			}

			&:nth-child(2) {
				-ms-grid-column: 1;
				grid-column: 1;
				-ms-grid-row: 2;
				grid-row: 2;
			}

			&:nth-child(3) {
				-ms-grid-column: 2;
				grid-column: 2;
				-ms-grid-row: 1;
				grid-row: 1;

				@include sm {
					-ms-grid-column: 3;
					grid-column: 3;
				}
			}

			&:nth-child(4) {
				-ms-grid-column: 2;
				grid-column: 2;
				-ms-grid-row: 2;
				grid-row: 2;

				@include sm {
					-ms-grid-column: 3;
					grid-column: 3;
				}
			}

			> span {
				font-weight: 500;
				font-size: 1.7rem;
			}
		}
	}

	.level-2 {
		> li {
			margin-bottom: 6px;

			&:first-child {
				margin-top: 8px;
			}
		}
	}

	ul {
		@extend %noStylelist;
	}

	a {
		color: $colorBlack;
	}
}

a.mea-link-wrapper {
	display: block !important;
}